<template>
  <div class="flex justify-center min-h-screen antialiased">  
    <div class="container sm:mt-40 mt-24 my-auto max-w-md p-3 bg-urbcharcoal rounded">
      <div>
        <div class="text-center m-6">
          <h1 class="text-3xl font-semibold text-urbtext">
            Welcome to the Urbtec Portal.
          </h1>
          <p class="text-gray-500">
            Please set your password.
          </p>
        </div>

        <div class="m-6">
          <form class="mb-4">
            <div class="p-field pt-4">
              <span class="p-float-label">
                <Password
                  id="password"
                  v-model="password"
                  inputClass="w-full"
                  class="w-full"
                  :class="{
                    'border-red-600': invalidPassword
                  }"
                  toggleMask
                >
                  <template #header>
                    <h6>Pick a password</h6>
                  </template>
                  <template #footer="sp">
                    {{ sp.level }}
                    <Divider />
                    <p class="mt-2">Required:</p>
                    <ul
                      class="pl-2 ml-2 mt-0"
                      style="line-height: 1.5"
                    >
                      <li>At least one lowercase</li>
                      <li>At least one uppercase</li>
                      <li>At least one number</li>
                      <li>Minimum 8 characters</li>
                    </ul>
                  </template>
                </Password>
                <label
                  for="password"
                  :class="{
                    'text-red-600': invalidPassword
                  }"
                >Password</label>
              </span>
            </div>
            <div class="p-field pt-4">
              <span class="p-float-label">
                <Password
                  id="password_confirmation"
                  v-model="password_confirmation"
                  inputClass="w-full"
                  class="w-full"
                  :class="{
                    'border-red-600': invalidPasswordConfirmation
                  }"
                  :feedback="false"
                  toggleMask
                />
                <label
                  for="password_confirmation"
                  :class="{
                    'text-red-600': invalidPasswordConfirmation
                  }"
                >Password Confirmation</label>
              </span>
            </div>
            <div class="mb-6">
              <Button
                class="w-full px-3 py-4 duration-100 ease-in-out justify-center text-xl"
                :loading="loading"
                label="Submit"
                :class="{'shake' : buttonShake}"
                @click="onSubmit"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VerificationService from '../services/VerificationProvider';
import Password from 'primevue/password';
import Divider from 'primevue/divider';

import { mapActions } from 'vuex';

export default {
	components: {
		Password,
		Divider
	},
	data(){
		return {
			password: null,
			password_confirmation: null,
			loading: false,
			buttonShake: false,
			invalidPassword: false,
			invalidPasswordConfirmation: false
		};
	},
	methods: {
		...mapActions({
			attempt: 'auth/attempt'
		}),
		onSubmit(){
			if (!this.password){
				this.invalidPassword = true;
				return;
			}
			if (!this.password_confirmation){
				this.invalidPasswordConfirmation = true;
				return;
			}

			if (this.password != this.password_confirmation){
				this.invalidPassword = true;
				this.invalidPasswordConfirmation = true;
				return;
			}
			this.loading = true;
			VerificationService.verifyUser(this.$route.params.email, this.$route.params.token, this.password, this.password_confirmation)
				.then(async (res) => {
					await this.attempt(res.data.access_token);
					this.$router.push('/list');
				})
				.catch((err) => {
					this.loading = false;
					this.invalidPassword = true;
					this.invalidPasswordConfirmation = true;
				});
		}
	}
};
</script>

<style lang="scss" scoped>
    .field-card {
        margin: 0 30px 0 30px;
        padding-left: 0;
        padding-right: 0; 
    }
    .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>