import axios from 'axios';

export default class SiteReadings {
	getAllViewableReadings(site_name) {
		let params = {
			'site_name': site_name,
			'edit' : 0
		};
		return axios.get('/site/readings/get', { params });
	}

	getReadings(params) {
		return axios.get('/site/readings/get', { params });
	}

	setReading(params) {
		return axios({
			method: 'post',
			url: '/site/readings/set',
			data: params
		});
	}

	getUnread(params) {
		return axios.get('/site/readings/un/get', { params });
	}

	pictureUpload(file, data) {
		var form = new FormData();
		form.append('image', file);
		form.append('data', data);
		return axios.post('/upload', form, {
			headers: { 'Content-Type': 'image/*' }
		});
	}

	submitManualReading(params) {
		return axios({
			method: 'post',
			url: '/site/readings/un/set',
			data: params
		});
	}

	getViewOnlyReadings(params) {
		return axios.get('/view/readings/get', { params });
	}

	getViewOnlyReadingsAsCSV(params, baseURLforImages) {
		return axios.get('/view/readings/get', { params })
			.then(async (res) => {
				let readings = res.data.readings;
				// let headers = Object.keys(readings[0]);
				let headers = [
					'Meter No.',
					'Site Name',
					'Unit',
					'Reading Date',
					'New Reading',
					'Old reading',
					'Consumption',
					'Utility Type',
					'Unit Number',
					'Estimated Reading',
					'Estimated Consumption',
					'Image Link',
					'Comment'
				];
				let fields = [
					'meter_no',
					'site_name',
					'unit',
					'reading_date',
					'reading_new',
					'reading_old',
					'consumption',
					'utility_type',
					'unit_no',
					'est_reading',
					'est_consumption',
					'file_name',
					'description'
				];
				
				await Promise.all(readings.map((row) => {
					row['file_name'] = baseURLforImages + 'image/' + params.site_name + '/' + row.reading_date + '/' + row.file_name;
				}));
				
				const replacer = (key, value) => {
					if (value === null) {
						return '';
					} else {
						return value;
					}
				};
				let csv = [
					headers.join(','), // header row first
					...readings.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
				].join('\r\n');

				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


				if (window.navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(blob, 'SITE NAME' + '.csv');
				}
				else {
					let link = document.createElement('a');
					link.style.display = 'none';
					document.body.appendChild(link);
					if (link.download !== undefined) {
						link.setAttribute('href', URL.createObjectURL(blob));
						link.setAttribute('download', 'Readings for '+ params.site_name + ' ' + new Date().toLocaleDateString() +' .csv');
						link.click();
					}
					else {
						csv = 'data:text/csv;charset=utf-8,' + csv;
						window.open(encodeURI(csv));
					}
					document.body.removeChild(link);
				}
			});
	}

	getReadingsAsCSV(params, baseURLforImages) {
		return axios.get('/site/readings/get', { params })
			.then(async (res) => {
				let readings = res.data.readings;
				// let headers = Object.keys(readings[0]);
				let headers = [
					'Meter No.',
					'Site Name',
					'Unit',
					'Reading Date',
					'New Reading',
					'Old reading',
					'Consumption',
					'Utility Type',
					'Unit Number',
					'Estimated Reading',
					'Estimated Consumption',
					'Image Link',
					'Comment'
				];
				let fields = [
					'meter_no',
					'site_name',
					'unit',
					'reading_date',
					'reading_new',
					'reading_old',
					'consumption',
					'utility_type',
					'unit_no',
					'est_reading',
					'est_consumption',
					'file_name',
					'description'
				];
				
				await Promise.all(readings.map((row) => {
					row['file_name'] = baseURLforImages + 'image/' + params.site_name + '/' + row.reading_date + '/' + row.file_name;
				}));
				
				const replacer = (key, value) => {
					if (value === null) {
						return '';
					} else {
						return value;
					}
				};
				let csv = [
					headers.join(','), // header row first
					...readings.map(row => fields.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','))
				].join('\r\n');

				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });


				if (window.navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(blob, 'SITE NAME' + '.csv');
				}
				else {
					let link = document.createElement('a');
					link.style.display = 'none';
					document.body.appendChild(link);
					if (link.download !== undefined) {
						link.setAttribute('href', URL.createObjectURL(blob));
						link.setAttribute('download', 'Readings for '+ params.site_name + ' ' + new Date().toLocaleDateString() +' .csv');
						link.click();
					}
					else {
						csv = 'data:text/csv;charset=utf-8,' + csv;
						window.open(encodeURI(csv));
					}
					document.body.removeChild(link);
				}
			});
	}

	uploadReplacementPicture(file, site_name, file_name, reading_date, reading_id, new_file_name) {
		let formData = new FormData();
		formData.append('meter_image', file);
		formData.append('site_name', site_name);
		formData.append('reading_date', reading_date);
		formData.append('file_name', file_name);
		formData.append('reading_id', reading_id);
		formData.append('new_file_name', new_file_name);
		return axios({
			method: 'post',
			url: '/site/readings/picture/replace',
			data: formData,
			headers: {
				'Content-Type': 'multipart/form-data'
			}
		});
	}
}