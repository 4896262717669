import { createApp } from 'vue';
import App from './App.vue';
import router from '@/domains/Base/router';
import store from '@/domains/Base/store';
import PrimeVue from 'primevue/config';
import 'chart.js';

import './assets/tailwind.css';

// Importing axios and setting baseURL
import axios from 'axios';
axios.defaults.baseURL = process.env.VUE_APP_API_URL;

require('@/domains/Base/store/subscriber');

// Declare constant vue app object
const vue = createApp(App);

// Default primevue and component theming
import 'primevue/resources/themes/bootstrap4-dark-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

// Custom sass compiled into css
import './assets/css/index.css';

// Importing components
import ConfirmationService from 'primevue/confirmationservice';
import ConfirmDialog from 'primevue/confirmdialog';
import ToastService from 'primevue/toastservice';
import Toast from 'primevue/toast';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Tooltip from 'primevue/tooltip';
import Chart from 'primevue/chart';

// Registering components
vue.component('Toast',Toast);
vue.component('ConfirmDialog',ConfirmDialog);
vue.component('InputText',InputText);
vue.component('Button',Button);
vue.component('Chart',Chart);

// Registering service providers
vue.use(ConfirmationService);
vue.use(ToastService);
vue.use(PrimeVue);

// Registering directives
vue.directive('tooltip', Tooltip);

store.dispatch('auth/attempt', localStorage.getItem('token')).then(function() {
	vue.use(store);
	vue.use(router);
	vue.mount('#app');
});