<template>
  <template v-if="loading">
    <i
      class="pi pi-spin pi-spinner"
      style="font-size: 2rem"
    />
  </template>
  <template v-else>
    <template v-if="cycles.length > 0">
      <div class="p-field p-grid justify-center">
        <label
          for="show_all_switch"
          class="p-col-fixed cursor-pointer"
          style="width: 100px"
        >Show all</label>
        <div class="p-col-fixed">
          <span class="p-input-icon-right">
            <InputSwitch
              id="show_all_switch"
              v-model="showAllCycles"
            />
          </span>
        </div>
      </div>
      <template
        v-for="(cycle, index) in cycles"
        :key="index"
      >
        <template v-if="index === 0 || showAllCycles">
          <div class="flex justify-center items-center flex-wrap">
            <!-- For readings between&nbsp;<span class="font-bold">{{ cycle.from_date }}</span>&nbsp;and&nbsp;<span class="font-bold">{{ cycle.to_date }}</span> -->
            <InputText
              v-model="cycle.cycle_code"
              class="w-32 ml-2 text-center"
              disabled
            />
            <template v-if="cycle.export_types.length == 1">
              <Button
                v-tooltip="'Download billing as CSV.'"
                icon="pi pi-download"
                label="CSV"
                class="ml-1"
                :loading="cycle.loading"
                @click="downloadBillingCSV(cycle, cycle.export_types[0])"
              /> 
              <Button
                v-tooltip="'Download billing as XLSX.'"
                icon="pi pi-download"
                label="XLSX"
                class="ml-1"
                :loading="cycle.loading"
                @click="downloadBillingXLSX(cycle, cycle.export_types[0])"
              />
              <Button
                icon="pi pi-eye"
                label="View"
                class="ml-1"
                @click="viewBilling(cycle, cycle.export_types[0])"
              />
            </template>
            <template v-else>
              <SplitButton
                v-tooltip="'Download billing as CSV.'"
                icon="pi pi-download"
                label="CSV"
                class="ml-1"
                :loading="cycle.loading"
                :model="cycle.export_types_csv"
                @click="downloadBillingCSV(cycle, cycle.export_types[0])"
              /> 
              <SplitButton
                v-tooltip="'Download billing as XLSX.'"
                icon="pi pi-download"
                label="XLSX"
                class="ml-1"
                :loading="cycle.loading"
                :model="cycle.export_types_xlsx"
                @click="downloadBillingXLSX(cycle, cycle.export_types[0])"
              />
              <SplitButton
                icon="pi pi-eye"
                label="View"
                class="ml-1"
                :loading="cycle.loading"
                :model="cycle.export_types_view"
                @click="viewBilling(cycle, cycle.export_types[0])"
              />
            </template>
          </div>
        </template>
        <Dialog
          v-model:visible="cycle.showBilling"
          :maximizable="true"
        >
          <template #header>
            {{ site.site_name }} - {{ cycle.cycle_code }}
          </template>
          <template v-if="cycle.loading">
            <i
              class="pi pi-spin pi-spinner"
              style="font-size: 2rem"
            />
          </template>
          <template v-else>
            <DataTable
              :value="cycle.billing"
              responsiveLayout="scroll"
              :paginator="true"
              :rows="20"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10, 20, 50]"
              stripedRows
            >
              <template
                v-for="(col, colNo) in cycle.billingColumns"
                :key="colNo"
              >
                <Column
                  :field="col.field"
                  :header="col.header"
                />
              </template>
            </DataTable>
          </template>
        </Dialog>
      </template>
    </template>
    <template v-else>
      <div class="font-bold text-2xl">
        No cycles found for {{ site.site_name }}
      </div>
    </template>
  </template>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';

import BillingService from '../../services/BillingProvider';
import UtilitiesService from '../../services/UtilitiesProvider';
import ShortDateService from '../../services/ShortDateProvider';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import SplitButton from 'primevue/splitbutton';

export default {
	components: {
		InputSwitch,
		Dialog,
		DataTable,
		Column,
		SplitButton
	},
	props: {
		site: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			cycles: [],
			showAllCycles: false,
			loading: true,
			showBilling: false,
			billingViewLoading: true,
		};
	},
	created() {
		this.utilitiesService = new UtilitiesService();
		this.billingService = new BillingService();
	},
	mounted() {
		this.loadCycles(this.site);
	},
	methods: {
		loadCycles(site) {
			this.loadingCycles = true;
			this.utilitiesService
				.getCycles(site.site_name)
				.then((res) => {
					this.cycles = res.data.cycles;
					this.cycles.forEach((cycle) => {
						cycle.loading = false;
						cycle.showBilling = false;
						cycle.billing = [];
						cycle.billingColumns = [];
						cycle.export_types = cycle.billing_type.split(',');
						console.log(cycle.export_types);
						cycle.export_types_csv = [];
						cycle.export_types_xlsx = [];
						cycle.export_types_view = [];
						cycle.export_types.forEach((export_type) => {
							cycle.export_types_csv.push({
								label: export_type,
								command: () => {
									this.downloadBillingCSV(cycle, export_type);
								}
							});
							cycle.export_types_xlsx.push({
								label: export_type,
								command: () => {
									this.downloadBillingXLSX(cycle, export_type);
								}
							});
							cycle.export_types_view.push({
								label: export_type,
								command: () => {
									this.viewBilling(cycle, export_type);
								}
							});
						});
					});
					this.showCycles = true;
					this.loading = false;
				})
				.catch((err) => {
					this.cycles = [];
					this.loadingCycles = false;
					this.showCycles = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Failed to retrieve cycles.',
						detail: err.message,
					});
					this.loading = false;
				});
		},
		downloadBillingCSV(cycle, export_type) {
			const shortDateService = new ShortDateService();
			const date = shortDateService.shortDateToProperDate(cycle.cycle_code);
			this.billingService.exportBillingAsCSV(
				this.site.site_name,
				cycle.billing_year,
				cycle.billing_month,
				export_type
			);
		},
		downloadBillingXLSX(cycle, export_type) {
			const shortDateService = new ShortDateService();
			const date = shortDateService.shortDateToProperDate(cycle.cycle_code);
			this.billingService.exportBillingAsXLSX(
				this.site.site_name,
				cycle.billing_year,
				cycle.billing_month,
				export_type
			);
		},
		viewBilling(cycle, export_type) {
			cycle.showBilling = true;
			cycle.loading = true;
			this.utilitiesService
				.viewBilling(
					this.site.site_name,
					cycle.billing_year,
					cycle.billing_month,
					export_type
				)
				.then((res) => {
					cycle.loading = false;
					cycle.billing = res.data.billing;
					cycle.billingColumns = res.data.headers;
				});
		},
	},
};
</script>

<style></style>
