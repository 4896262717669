<template>
  <DataTable
    ref="datatable"
    v-model:filters="filters"
    :totalRecords="rowCount"
    filterDisplay="row"
    :value="readings"
    :paginator="true"
    :lazy="true"
    class="p-datatable-customers p-datatable-sm"
    :rows="rowsPerPage"
    dataKey="site_name"
    :rowHover="true"
    :loading="loading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[50, 100]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    responsiveLayout="scroll"
    :globalFilterFields="['unit', 'meter_no', 'reading_date_disp']"
    editMode="cell"
    @filter="onFilter($event)"
    @page="onPage($event)"
    @sort="onSort($event)"
    @cellEditComplete="onCellEditComplete"
    @cell-edit-init="onCellEditInit"
    @cell-edit-cancel="onCellEditCancel"
    @keydown.enter.prevent
  >
    <template #header>
      <span class="text-3xl"> {{ siteName }} (Editing) </span>
      <div class="float-right">
        <Button
          :loading="exportLoading"
          icon="pi pi-external-link"
          label="Export"
          class="mr-2 w-28"
          @click="exportCSV($event)"
        />
        <Button
          :loading="loading"
          @click="generateBilling()"
        >
          Generate Billing
        </Button>
      </div>
    </template>
    <template
      v-if="!locked"
      #empty
    >
      No readings found.
    </template>
    <template
      v-else-if="locked"
      #empty
    >
      <div class="text-center">
        <i
          class="pi pi-lock"
          style="fontsize: 20rem; color: #e5b52b"
        /> <br>
        <span class="text-4xl text-red-500"> {{ errorMessage }} </span>
      </div>
    </template>
    <template #loading>
      Loading readings for {{ siteName }} data. Please wait.
    </template>
    <Column
      sortable
      style="width: 50px"
      filterField="utility_type"
      :showFilterMatchModes="false"
    >
      <template #body="{ data }">
        <img
          v-if="data.utility_type === 'electricity'"
          src="@/assets/util-electricity.svg"
          alt="ELECTRICITY"
          style="width: 40px"
        >
        <img
          v-else-if="data.utility_type === 'cold_water'"
          src="@/assets/util-cold_water.svg"
          alt="COLD_WATER"
          style="width: 40px"
        >
        <img
          v-else-if="data.utility_type === 'water'"
          src="@/assets/util-water.svg"
          alt="WATER"
          style="width: 40px"
        >
        <img
          v-else-if="data.utility_type === 'hot_water'"
          src="@/assets/util-hot_water.svg"
          alt="HOT_WATER"
          style="width: 40px"
        >
        <img
          v-else
          src="@/assets/util-unsure.svg"
          alt="UNSURE"
          style="width: 40px"
        >
      </template>
    </Column>
    <Column
      field="unit_no"
      header="No."
      sortable
      style="width: 100px; text-align: center"
      :showFilterMenu="false"
    >
      <template #body="{ data }">
        {{ data.unit_no }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          @keydown.enter="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="unit"
      header="Unit"
      sortable
      :showFilterMenu="false"
    >
      <template #body="{ data }">
        {{ data.unit }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by unit"
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="meter_no"
      header="Meter No."
      sortable
      :showFilterMenu="false"
    >
      <template #body="{ data }">
        {{ data.meter_no }}
        <span
          v-if="data.description != 'Working Meter' && data.description != ''"
          class="text-yellow-600"
        >
          [{{ data.description }}]
        </span>
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by meter no."
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_old_date_disp"
      header="Previous Reading Date"
      sortable
      class="text-center"
      :showFilterMenu="false"
    >
      <template #body="{ data }">
        {{ data.reading_old_date_disp }}
      </template>
      <template #filter>
        <div />
      </template>
    </Column>
    <Column
      field="reading_date_disp"
      header="Reading Date"
      sortable
      class="text-center"
      :showFilterMenu="false"
    >
      <template #body="{ data }">
        {{ data.reading_date_disp }}
      </template>
      <template #filter="{ filterModel, filterCallback }">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by date"
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_new"
      header="New Reading"
      sortable
      style="width: 10%"
    >
      <template #body="slotProps">
        <div :class="getStylingClasses(slotProps)">
          <ReadingIcon :readingValidity="slotProps.data.valid" />
          {{ slotProps.data.reading_new }}
        </div>
      </template>
      <template #editor="slotProps">
        <div class="p-fluid">
          <span class="p-input-icon-left">
            <ReadingIcon
              :readingValidity="slotProps.data.valid"
              :class="getStylingClasses(slotProps)"
            />
            <InputText
              v-model="slotProps.data[slotProps.column.props.field]"
              @input="updateValidity(slotProps)"
            />
          </span>
        </div>
      </template>
    </Column>
    <Column
      field="reading_old"
      header="Old Reading"
      sortable
      style="width: 10%"
    >
      <template #body="{ data }">
        {{ data.reading_old }}
      </template>
      <template #editor="slotProps">
        <div class="p-fluid">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" />
        </div>
      </template>
    </Column>
    <Column
      field="consumption"
      header="Consumption"
      sortable
    >
      <template #body="{ data }">
        <span class="status-success">
          <template v-if="data.reading_new - data.reading_old < -50000">
            {{
              getAppropriateReadingForClockedMeter(
                data.reading_new,
                data.reading_old
              )
            }}
            <span v-if="data.old_consumption != null">
              ({{
                getAppropriateReadingForClockedMeter(
                  data.reading_new,
                  data.reading_old
                ) > data.old_consumption
                  ? '+'
                  : ''
              }}{{
                getAppropriateReadingForClockedMeter(
                  data.reading_new,
                  data.reading_old
                ) - data.old_consumption
              }})
            </span>
          </template>
          <template v-else>
            <span
              :class="{
                'red-consumption': data.reading_new - data.reading_old < 0,
              }"
            >
              {{ data.reading_new - data.reading_old }}
            </span>
            <span v-if="data.old_consumption != null">
              ({{
                data.reading_new - data.reading_old > data.old_consumption
                  ? '+'
                  : ''
              }}{{
                data.reading_new - data.reading_old - data.old_consumption
              }})
            </span>
          </template>
        </span>
      </template>
    </Column>
    <Column
      field="file_name"
      header="IMG"
    >
      <template #body="{ data }">
        <Button
          :label="pictureButtonLabel(data)"
          icon="pi pi-external-link"
          :class="disablePictureButton(data.file_name) ? 'p-button-danger' : ''"
          @click="togglePicture(data)"
        />
        <Dialog
          v-model:visible="data.pictureDialog"
          :header="data.unit + ' | ' + data.meter_no"
          :maximizable="true"
          position="left"
        >
          <div class="p-d-flex p-jc-center">
            <template v-if="disablePictureButton(data.file_name)">
              <div class="p-mr-2">
                <i
                  class="pi pi-file-excel"
                  style="fontsize: 10rem"
                />
              </div>
            </template>
            <template v-else-if="!data.uploadingNew">
              <img
                :src="
                  baseURL +
                    'image/' +
                    siteName +
                    '/' +
                    data.reading_date +
                    '/' +
                    data.file_name +
                    '#' +
                    data.suffix
                "
              >
            </template>
            <template v-else>
              <ProgressSpinner />
            </template>
          </div>
          <template #footer>
            <FileUpload
              :ref="'upload' + data.meter_no"
              name="meterImage[]"
              mode="basic"
              :customUpload="true"
              chooseLabel="Replace Picture"
              @uploader="newPictureUpload($event, data)"
            />
          </template>
        </Dialog>
      </template>
    </Column>
  </DataTable>
  <ConfirmDialog
    :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
    :style="{ width: '50vw' }"
  />
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import FileUpload from 'primevue/fileupload';
import ProgressSpinner from 'primevue/progressspinner';

import ReadingIcon from './ReadingIcon';

import SiteReadingService from '../services/SiteReadings';
import BillingService from '../services/BillingProvider';
import LegacyBillingService from '../services/LegacyBillingProvider';

import imageCompression from 'browser-image-compression';
import _ from 'lodash';

import { FilterMatchMode } from 'primevue/api';

export default {
	components: {
		DataTable,
		Column,
		Dialog,
		ReadingIcon,
		FileUpload,
		ProgressSpinner,
	},
	props: {
		siteName: {
			type: String,
			default: '',
		},
	},
	data() {
		return {
			readings: [],
			loading: true,
			exportLoading: false,
			pagingParams: {},
			rowsPerPage: 50,
			rowCount: 0,
			filters: {
				meter_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
				unit_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				reading_date_disp: { value: null, matchMode: FilterMatchMode.CONTAINS },
				utility_type: { value: null, matchMode: FilterMatchMode.IN },
			},
			locked: false,
			editingCellRows: {},
			utility_types: [
				{ name: 'Cold Water', image: '@/assets/util-cold_water.svg' },
				{ name: 'Electricity', image: '@/assets/util-electricity.svg' },
				{ name: 'Water', image: '@/assets/util-water.svg' },
				{ name: 'Unsure', image: '@/assets/util-unsure.svg' },
			],
			baseURL: process.env.VUE_APP_API_URL,
			initCellValues: {},
			errorMessage: ''
		};
	},
	created() {
		this.siteReadingService = new SiteReadingService();
		this.billingService = new BillingService();
		this.legacyBillingService = new LegacyBillingService();
	},
	mounted() {
		this.pagingParams = {
			pageIndex: 0,
			pageSize: this.$refs.datatable.rows,
			sortField: null,
			sortOrder: null,
			edit: 1,
			filters: this.filters,
			site_name: this.siteName,
			paging: true,
		};

		this.loadPagedData();
	},
	methods: {
		getAppropriateReadingForClockedMeter(reading_new, reading_old) {
			let digits = reading_old.toString().length;
			reading_new = parseInt(reading_new);
			let multiplier = Math.pow(10, digits);
			let meterLimit = Math.round(reading_old / multiplier) * multiplier;

			return meterLimit - reading_old + reading_new;
		},
		async newPictureUpload(event, data) {
			data.uploadingNew = true;

			console.log(event.files[0]);

			const options = {
				maxSizeMB: 1,
				maxWidthOrHeight: 600,
				useWebWorker: true,
			};
			try {
				const compressedFile = await imageCompression(event.files[0], options);
				// console.log(
				// 	'compressedFile instanceof Blob',
				// 	compressedFile instanceof Blob
				// ); // true
				// console.log(
				// 	`compressedFile size ${compressedFile.size / 1024 / 1024} MB`
				// ); // smaller than maxSizeMB

				var newFileName = Date.now() + '_' + event.files[0].name;

				await this.siteReadingService
					.uploadReplacementPicture(
						compressedFile,
						this.siteName,
						data.file_name,
						data.reading_date,
						data.reading_id,
						newFileName
					)
					.then((res) => {
						data.suffix += 'NEW';
						data.uploadingNew = false;
						this.$refs['upload' + data.meter_no].clear();
						this.$toast.add({
							severity: 'success',
							summary: 'Update successfull!',
							detail: res.data.message,
							life: 2000,
						});
						if (res.data.type === 'add') {
							data.file_name = newFileName;
						}
					})
					.catch((err) => {
						data.uploadingNew = false;
						this.$toast.add({
							severity: 'error',
							summary: 'Update failed!',
							detail: err.response.data.message,
							life: 2000,
						});
					});
			} catch (error) {
				console.error(error);
			}
		},
		disablePictureButton(file_name) {
			switch (file_name) {
			case 'NOFILE':
			case 'no_image_available.jpg':
				return true;
			default:
				return false;
			}
		},
		pictureButtonLabel(data) {
			return data.pictureDialog == true ? 'Hide' : 'Show';
		},
		togglePicture(data) {
			if (data.pictureDialog) {
				data.pictureDialog = false;
			} else {
				data.pictureDialog = true;
			}
		},
		loadPagedData() {
			this.loading = true;

			setTimeout(() => {
				this.siteReadingService
					.getReadings(this.pagingParams)
					.then((res) => {
						this.readings = res.data.readings;
						this.rowCount = res.data.count;
						this.loading = false;

						this.readings.forEach((element) => {
							element.pictureDialog = false;
							element.uploadingNew = false;
							element.suffix = '';
						});
					})
					.catch((err) => {
						console.error(err.message);
						this.readings = null;
						this.loading = false;
						this.locked = err.response.data.locked;
						this.errorMessage = err.response.data.message;
					});
			}, Math.random() * 1000 + 250);
		},
		onPage(event) {
			this.pagingParams.pageIndex = event.page;
			this.pagingParams.pageSize = event.rows;
			this.loadPagedData();
		},
		onSort(event) {
			this.pagingParams.sortField = event.sortField;
			this.pagingParams.sortOrder = event.sortOrder < 0 ? 'ASC' : 'DESC';
			this.loadPagedData();
		},
		onFilter() {
			this.pagingParams.filters = this.filters;
			this.loadPagedData();
		},
		onCellEditComplete(event) {
			this.readings[event.index][event.field] = parseInt(event.newValue);

			if (event.value === event.newValue) {
				return;
			} else {
				var params = {
					meter_no: event.data['meter_no'],
					unit: event.data['unit'],
					reading_date: event.data['reading_date'],
					site_name: this.siteName,
					[event.field]: event.newValue,
				};
				var newConsumption;
				if (event.field === 'reading_new') {
					newConsumption = event.newValue - event.data.reading_old;
				} else if (event.field === 'reading_old') {
					newConsumption = event.data.reading_new - event.newValue;
				} else {
					return;
				}

				if (event.data.valid == 'HIGH' || event.data.valid == 'LOW') {
					if (newConsumption < 0) {
						this.readings[event.index][event.field] = event.value;
						this.$toast.add({
							severity: 'error',
							summary: 'Not allowed!',
							detail: 'Consumption cannot be negative.',
						});
					} else {
						this.$confirm.require({
							message: 'Are you sure you want to proceed?',
							header: 'Confirmation',
							icon: 'pi pi-exclamation-triangle',
							accept: () => {
								this.commitFieldParams(params, event);
							},
							reject: () => {
								this.readings[event.index][event.field] = event.value;
								this.$toast.add({
									severity: 'error',
									summary: 'Update canceled',
									detail: 'You have rejected',
									life: 3000,
								});
								return;
							},
						});
					}
				} else {
					this.commitFieldParams(params, event);
				}
			}
		},
		commitFieldParams(params, event) {
			this.siteReadingService
				.setReading(params)
				.then((res) => {
					this.readings[event.index][event.field] = parseInt(event.newValue);
					this.$toast.add({
						severity: 'success',
						summary: 'Updated successfully!',
						detail: res.data.message,
						life: 2000,
					});
				})
				.catch((err) => {
					this.readings[event.index][event.field] = parseInt(event.value);
					console.error(err);
					if (err.response) {
						this.$toast.add({
							severity: 'error',
							summary: 'Update failed!',
							detail: err.response.data.message,
						});
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Update failed!',
							detail: 'Connection error. Please try again later.',
						});
					}
				});
		},
		onCellEditInit() {},
		onCellEditCancel() {},
		generateBilling() {
			this.$confirm.require({
				message:
					'Are you sure you want to generate the billing for ' +
					this.siteName +
					'?',
				header: 'Confirmation',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					this.loading = true;
					Promise.all([this.legacyBilling(), this.newBilling()]).then(() => {});
				},
				reject: () => {
					//callback to execute when user rejects the action
				},
			});
		},
		legacyBilling() {
			this.legacyBillingService
				.generate(this.siteName)
				.then((res) => {
					this.$toast.add({
						severity: 'success',
						summary: 'Billing successfully generated!',
						detail: res.message,
					});
					this.locked = true;
					this.readings = [];
					this.loading = false;
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error',
						summary: 'Billing generation failed!',
						detail: err.message,
					});
				});
		},
		newBilling() {
			this.billingService.generate(this.siteName);
		},
		updateValidity(props) {
			var value1 = props.data['reading_new'];
			var value2 = props.data['reading_old'];
			var estimateval = props.data['est_reading'];
			var avgconsumption = props.data['est_consumption'];

			if (estimateval != '') {
				var currentconsumption;
				if (value1 - value2 < -50000) {
					currentconsumption = this.getAppropriateReadingForClockedMeter(
						value1,
						value2
					);
				} else {
					currentconsumption = value1 - value2;
				}
				var persentagediff = (currentconsumption / avgconsumption - 1) * 100;

				if (Math.sign(persentagediff) == +1 && persentagediff > 60) {
					props.data['valid'] = 'HIGH';
					return;
				} else if (
					Math.sign(persentagediff) == +1 &&
					persentagediff > 30 &&
					persentagediff <= 60
				) {
					props.data['valid'] = 'MIDHIGH';
					return;
				} else if (persentagediff >= -30 && persentagediff <= 30) {
					props.data['valid'] = 'OKAY';
					return;
				} else if (
					Math.sign(persentagediff) == -1 &&
					persentagediff < -30 &&
					persentagediff >= -60
				) {
					props.data['valid'] = 'MIDLOW';
					return;
				} else if (persentagediff < -60) {
					props.data['valid'] = 'LOW';
					return;
				} else {
					return;
				}
			}
			return;
		},
		getStylingClasses(props) {
			var value1 = props.data['reading_new'];
			var value2 = props.data['reading_old'];
			var estimateval = props.data['est_reading'];
			var avgconsumption = props.data['est_consumption'];

			if (estimateval != '') {
				var currentconsumption;
				if (value1 - value2 < -50000) {
					currentconsumption = this.getAppropriateReadingForClockedMeter(
						value1,
						value2
					);
				} else {
					currentconsumption = value1 - value2;
				}
				var persentagediff = (currentconsumption / avgconsumption - 1) * 100;

				if (Math.sign(persentagediff) == +1 && persentagediff > 60) {
					props.data['valid'] = 'HIGH';
					return 'text-red-700 font-bold';
				} else if (
					Math.sign(persentagediff) == +1 &&
					persentagediff > 30 &&
					persentagediff <= 60
				) {
					props.data['valid'] = 'MIDHIGH';
					return 'text-yellow-400';
				} else if (persentagediff >= -30 && persentagediff <= 30) {
					props.data['valid'] = 'OKAY';
					return 'text-green-500';
				} else if (
					Math.sign(persentagediff) == -1 &&
					persentagediff < -30 &&
					persentagediff >= -60
				) {
					props.data['valid'] = 'MIDLOW';
					return 'text-yellow-400';
				} else if (persentagediff < -60) {
					props.data['valid'] = 'LOW';
					return 'text-red-700 font-bold';
				} else {
					return '';
				}
			}
			return '';

			// if (Math.sign(persentagediff) == +1 && persentagediff > 60) { document.getElementById(rightfieldname).style.backgroundColor = '#FF8484'; document.getElementById(rightfieldname).style.borderColor = 'red';}
			// else if (Math.sign(persentagediff) == +1 && persentagediff > 30 && persentagediff <= 60) { document.getElementById(rightfieldname).style.backgroundColor = '#FFC49E'; document.getElementById(rightfieldname).style.borderColor = 'orange'; }
			// else if (persentagediff >= -30 && persentagediff <= 30) { document.getElementById(rightfieldname).style.backgroundColor = '#48E252'; document.getElementById(rightfieldname).style.borderColor = 'green'; }
			// else if (Math.sign(persentagediff) == -1 && persentagediff < -30 && persentagediff >= -60) { document.getElementById(rightfieldname).style.backgroundColor = '#FFC49E'; document.getElementById(rightfieldname).style.borderColor = 'orange';}
			// else if (persentagediff < -60) { document.getElementById(rightfieldname).style.backgroundColor = '#FF8484'; document.getElementById(rightfieldname).style.borderColor = 'red';}
			// else { document.getElementById(rightfieldname).style.backgroundColor = '#FF8484'; document.getElementById(rightfieldname).style.borderColor = 'red';}
		},
		exportCSV() {
			this.exportLoading = true;
			this.siteReadingService
				.getReadingsAsCSV(
					{
						sortField: null,
						sortOrder: null,
						edit: 1,
						filters: this.filters,
						site_name: this.siteName,
					},
					this.baseURL
				)
				.then(() => {
					this.exportLoading = false;
				});
		},
	},
};
</script>

<style lang="scss" scoped>
::v-deep(.editable-cells-table td.p-cell-editing) {
	padding-top: 0;
	padding-bottom: 0;
}
.p-datatable-emptymessage {
	text-align: center;
}

.red-consumption {
	color: rgb(228, 0, 0);
	font-weight: bold;
}
</style>
