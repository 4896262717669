import axios from 'axios';

export default class DashProvider {
	siteMeters() {
		return axios.get('/dash/siteMeters');
	}
    
	siteCount() {
		return axios.get('/dash/siteCount');
	}
    
	meterCount() {
		return axios.get('/dash/meterCount');
	}
    
	latestMeterUsages() {
		return axios.get('/dash/currentMonthUsageStatistics');
	}

	lastMeterUsages() {
		return axios.get('/dash/lastMonthUsageStatistics');
	}
}