<template> 
  <div class="card">
    <DataTable
      v-model:expandedRowGroups="expandedRowGroups"
      :value="units"
      rowGroupMode="subheader"
      groupRowsBy="unit"
      sortMode="single"
      sortField="unit"
      :sortOrder="1"
      responsiveLayout="scroll"
      :expandableRowGroups="true"
      @rowgroupExpand="onRowGroupExpand"
      @rowgroupCollapse="onRowGroupCollapse"
    >
      <Column
        field="unit"
        header="unit"
      />
      <Column
        field="meter_no"
        header="meter_no"
      />
      <Column
        field="reading_date"
        header="reading_date"
      />
      <Column
        field="reading_old"
        header="reading_old"
      />
      <Column
        field="reading_new"
        header="reading_new"
      />
      <Column
        field="consumption"
        header="consumption"
      />
      <template #groupheader="slotProps">
        <span class="text-2xl font-bold">{{ slotProps.data.site }}: </span>
        <span class="text-2xl">{{ slotProps.data.unit }}</span>
        <span class="float-right"><Button
          :loading="slotProps.data.billingLoading"
          @click="generateUnitBilling(slotProps.data)"
        > GENERATE BILLING </Button></span>
      </template>
      <template #header>
        <span class="text-3xl">
          FINAL BILLING
        </span>
        <Button
          class="float-right"
          @click="generateAllUnitBillings()"
        >
          GENERATE BILLING FOR ALL UNITS
        </Button>
      </template>
      <!-- <template #groupfooter="slotProps">
        <td
          colspan="4"
          style="text-align: right"
        >
          Total Consumption
        </td>
        <td>{{ calculateTotalConsumption(slotProps.data) }}</td>
      </template> -->
    </DataTable>
  </div>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FinalReadingService from '../../services/FinalReadingProvider';

export default {
	components: {
		DataTable,
		Column
	},
	data() {
		return {
			units: null,
			expandedRowGroups: null
		};
	},
	finalReadingService: null,
	created() {
		this.finalReadingService = new FinalReadingService();
	},
	mounted() {
		this.finalReadingService.getFinalReadings()
			.then((res) => {
				this.units = res.data.final_readings;
				this.units.forEach(unit => {
					unit.billingLoading = false;
				});
			});
	},
	methods: {
		onRowGroupExpand(event) {},
		onRowGroupCollapse(event) {},
		calculateTotalConsumption(data) {
			let total = 0;
			this.units.forEach(unit => {
				if (data.unit == unit.unit){
					total+=unit.consumption;
				}
			});
			return total;
		},
		async generateUnitBilling(unit){
			unit.billingLoading = true;

			this.finalReadingService.generateUnitFinalReading(unit.site, unit.unit)
				.then((res) => {
					unit.billingLoading = false;
				})
				.catch((err) => {
					unit.billingLoading = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Final reading billing failed.',
						detail: err.message
					});
				});
		}, 
		async generateAllUnitBillings(){
			this.units.forEach((unit) => {
				unit.billingLoading = true;
			});
      
			setTimeout(() => {
				this.units.forEach((unit) => {
					unit.billingLoading = false;
				});
			}, 3000);

		}
	},
};
</script>

<style>

</style>