<template>
  <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center pt-10">
    <div class="p-mb-2 p-mr-2 mx-40">
      Total no. of Sites
      <div class="text-5xl">
        {{ siteCount }}
      </div>
    </div>
    <div class="p-mb-2 p-mr-2 mx-40">
      Total no. of Meters
      <div class="text-5xl">
        {{ meterCount }}
      </div>
    </div>
  </div>
</template>

<script>
import DashInfoService from '../../services/DashProvider';

export default {
	data() {
		return {
			siteCount: 0,
			meterCount: 0
		};
	},
	created() {
		this.dashInfoService = new DashInfoService();
	},
	mounted() {
		this.dashInfoService.siteCount().then(res => {
			this.siteCount = res.data.count;
		});

		this.dashInfoService.meterCount().then(res => {
			this.meterCount = res.data.count;
		});
	}
};
</script>

<style></style>
