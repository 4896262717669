import store from '../store';
import axios from 'axios';

store.subscribe((mutation) => {
	switch (mutation.type) {
	case 'auth/SET_TOKEN':
		if (mutation.payload){
			axios.defaults.headers.common['Authorization'] = 'Bearer ' + mutation.payload;
			localStorage.setItem('token', mutation.payload);
			axios.interceptors.request.use(config => {
			// Do something before request is sent
				if (!config.url.includes('/auth')) {
					store.dispatch('auth/refresh');
				}
				return config;
			},error => {
			// Do something with request error
				return Promise.reject(error);
			});
		} else {
			axios.defaults.headers.common['Authorization']  = null;
			localStorage.removeItem('token');
		}
		break;
	}
});