import { createRouter, createWebHistory } from 'vue-router';
// Import base components for basic routes
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import PasswordReset from '../views/PasswordReset.vue';
import VerifyNewUser from '../views/VerifyNewUser.vue';
import PasswordResetRequest from '../views/PasswordResetRequest.vue';

// Import routes for different route groups
import adminRoutes from '@/domains/Admin/router/adminRoutes';
import operatorRoutes from '@/domains/Operator/router/operatorRoutes';
import inspectorRoutes from '@/domains/Inspector/router/inspectorRoutes';
import store from '../store';

// Import service providers for verification and stuff
import PasswordService from '../services/PasswordProvider';
import VerificationService from '../services/VerificationProvider';

const basicRoutes = [
	{
		path: '/',
		name: 'home',
		component: Home,
		meta: { requiresAuth: false, title: '🚧 WIP 🚧 | URBTEC', },
		beforeEnter: (to, from, next) => {
			if (store.getters['auth/authenticated']){
				next('/list');
			} else {
				next('/login');
			}
		}

	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { requiresAuth: false, title: 'LOGIN | URBTEC'},
		beforeEnter: (to, from, next) => {
			if (store.getters['auth/authenticated']) {
				next('/list');
			} else {
				next();
			}
		}
	},
	{
		path: '/password/reset/:token/:email',
		name: 'passwordreset',
		component: PasswordReset,
		meta: { requiresAuth: false, title: 'RESET YOUR PASSWORD | URBTEC'},
		beforeEnter: (to, from, next) => {
			if (store.getters['auth/authenticated']) {
				next('/list');
			} else {
				PasswordService.checkToken(to.params.token, to.params.email)
					.then(() => {
						next();
					})
					.catch(() => {
						next('/login');
					});
			}
		}
	},
	{
		path: '/user/verify/:token/:email',
		name: 'verifyuser',
		component: VerifyNewUser,
		meta: { requiresAuth: false, title: 'RESET YOUR PASSWORD | URBTEC'},
		beforeEnter: (to, from, next) => {
			if (store.getters['auth/authenticated']) {
				next('/list');
			} else {
				VerificationService.checkToken(to.params.token, to.params.email)
					.then(() => {
						next();
					})
					.catch(() => {
						next('/login');
					});
			}
		}
	},
	{
		path: '/password/reset',
		name: 'passwordresetrequest',
		component: PasswordResetRequest,
		meta: { requiresAuth: false, title: 'RESET YOUR PASSWORD | URBTEC'},
		beforeEnter: (to, from, next) => {
			if (store.getters['auth/authenticated']) {
				next('/list');
			} else {
				next();
			}
		}
	}
];

const routes = basicRoutes.concat(adminRoutes).concat(operatorRoutes).concat(inspectorRoutes);

const router = createRouter({
	history: createWebHistory(),
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	if(requiresAuth) {
		if (store.getters['auth/authenticated']){
			next();
		} else {
			store.dispatch('auth/logout');
			next('/login');
		}
	} else {
		next();
	}
}); 

router.afterEach(async (to, from) => {
	const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

	if (requiresAuth) {
		await store.dispatch('auth/refresh');
	} 
});

export default router;
