<template>
  <div
    class="container mx-auto sm:w-3/5 sm:px-8 p-d-flex p-flex-column p-flex-md-row"
  >
    <div
      class="bg-urbcharcoal rounded grid content-center justify-items-center p-mb-2 p-mr-2"
    >
      <div class="m-4 p-4">
        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="clientname"
              v-model="form.clientname"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="clientname"
            >Client Name</label>
          </span>
        </div>
        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbname"
              v-model="form.dbname"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="databasename"
            >Database Name</label>
          </span>
        </div>

        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbhost"
              v-model="form.dbhost"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="dbhost"
            >Database Host</label>
          </span>
        </div>

        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbuser"
              v-model="form.dbuser"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="dbuser"
            >Database User</label>
          </span>
        </div>

        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbpass"
              v-model="form.dbpass"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="dbpass"
            >Database Password</label>
          </span>
        </div>

        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbport"
              v-model="form.dbport"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="dbport"
            >Database Port</label>
          </span>
        </div>

        <div class="flex p-4">
          <span class="p-float-label">
            <InputText
              id="dbdriver"
              v-model="form.dbdriver"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              class=""
              for="dbdriver"
            >Database Driver</label>
          </span>
        </div>
      </div>

      <div class="p-4">
        <Button
          label="Add Existing Database"
          @click="onSubmit"
        />
      </div>
      <div class="p-4">
        <Button
          label="Create New Database"
          disabled="true"
        />
      </div>
    </div>
    <div
      class="bg-urbcharcoal rounded grid content-center justify-items-center p-mb-2 p-mr-2"
    >
      <template
        v-for="db in databases"
        :key="db.id"
      >
        <div class="p-4">
          <Button
            :label="'Aggregate Sites for ' + db.client_name"
            @click="aggregateSiteList(db.id)"
          /><br>
        </div>
      </template>
    </div>
    <div
      class="bg-urbcharcoal rounded grid content-center justify-items-center p-mb-2 p-mr-2"
    >
      <div class="">
        <div class="flex p-4">
          <span class="p-float-label">
            <AutoComplete
              id="Site Name"
              v-model="sites"
              :suggestions="filteredSites"
              field="site_name"
              @complete="searchSites($event.query)"
              @item-select="loadButton($event)"
              @item-unselect="loadButton()"
              @clear="hideButton()"
            />
            <label
              class=""
              for="Site Name"
            >Site Name</label>
          </span>
        </div>
        <div
          v-if="showSiteCycleButton"
          class="p-4"
        >
          <Button
            :label="'Generate cycles for ' + siteForCycleGen"
            :loading="loadingSiteCycles"
            @click="generateForSite()"
          /><br>
        </div>
      </div>
      <div class="border-t-2 border-b-2 ">
        <template
          v-for="db in databases"
          :key="db.id"
        >
          <div class="p-4">
            <Button
              :label="'Generate cycles for ' + db.client_name"
              :loading="db.loadingCycles"
              @click="generateForDB(db)"
            /><br>
          </div>
        </template>
      </div>
      <!-- <ProgressSpinner /> -->
      <div class="p-4">
        <Button
          :label="'Generate cycles for all databases'"
          :loading="allCyclesLoading"
          @click="generateForAll()"
        /><br>
      </div>
    </div>
  </div>
</template>

<script>
import DatabaseService from '../services/DatabaseProvider';
import CycleService from '../services/CycleProvider';
import SiteInfoService from '../services/SiteInfoProvider';
import AutoComplete from 'primevue/autocomplete';
import axios from 'axios';

export default {
	name: 'NewDatebaseComponent',
	components: {
		AutoComplete
	},
	data() {
		return {
			form: {
				clientname: '',
				dbname: '',
				dbhost: '',
				dbuser: '',
				dbpass: '',
				dbport: '3306',
				dbdriver: 'mysql',
				testconnection: 'true'
			},
			databases: [],
			sites: [],
			siteForCycleGen: null,
			showSiteCycleButton: false,
			allCyclesLoading: false,
			filteredSites: [],
			loadingSiteCycles: false
		};
	},
	created() {
		this.dbService = new DatabaseService();
		this.cycleService = new CycleService();
		this.siteInfoService = new SiteInfoService();
	},
	mounted() {
		this.loadDatabases();
	},
	methods: {
		searchSites(query) {
			this.siteInfoService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		loadButton(event) {
			this.showSiteCycleButton = true;
			this.siteForCycleGen = event.value.site_name;
		},
		hideButton() {
			this.showSiteCycleButton = false;
		},
		generateForAll() {
			this.allCyclesLoading = true;
			this.cycleService.populateFromAll().then(res => {
				this.allCyclesLoading = false;
				this.$toast.add({
					severity: 'success',
					summary: 'Cycles successfully generated.',
					detail: res.data
				});
			});
		},
		generateForDB(db) {
			db.loadingCycles = true;
			this.cycleService
				.populateFromDB(db.id)
				.then(res => {
					db.loadingCycles = false;
					this.$toast.add({
						severity: 'success',
						summary: res.data.message
					});
				})
				.catch(err => {
					db.loadingCycles = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Unable to generate cycles.',
						detail: err.response.data.message
					});
				});
		},
		generateForSite() {
			this.loadingSiteCycles = true;
			this.cycleService
				.populateFromSiteName(this.siteForCycleGen)
				.then(res => {
					this.loadingSiteCycles = false;
					this.$toast.add({
						severity: 'success',
						summary:
							'Cycles successfully generated for ' + this.siteForCycleGen,
						detail: res.data.message
					});
				})
				.catch(err => {
					this.loadingSiteCycles = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong generating the cycles',
						detail: err.response.message
					});
				});
		},
		formFlagged() {
			/**Checks the form details.
			 * Return false if the form is correct.
			 * **/
			var flagged = false;

			if (this.form.dbname === '') {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database name is required.'
				});
				flagged = true;
			}

			if (this.form.dbhost === '') {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database host is required.'
				});
				flagged = true;
			}

			if (this.form.dbuser === '') {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database user is required.'
				});
				flagged = true;
			}

			// if (this.form.dbpass === '') {
			// 	this.$toast.add({
			// 		severity: 'error',
			// 		summary: 'Information missing.',
			// 		detail: 'Database password is required.'
			// 	});
			// 	flagged = true;
			// }

			if (this.form.dbport === '') {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database port number is required.'
				});
				flagged = true;
			} else if (isNaN(this.form.dbport)) {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database port must be a number.'
				});
				flagged = true;
			}

			if (this.form.dbdriver === '') {
				this.$toast.add({
					severity: 'error',
					summary: 'Information missing.',
					detail: 'Database driver is required.'
				});
				flagged = true;
			}

			if (flagged) {
				return true;
			} else {
				return false;
			}
		},
		onSubmit() {
			var flagged = this.formFlagged();
			if (!flagged) {
				axios
					.post('/admin/DB/new', this.form)
					.then(res => {
						this.$toast.add({
							severity: 'success',
							summary: 'Database successfully added.',
							detail: res.data.message
						});
						this.loadDatabases();
					})
					.catch(err => {
						// console.error(err);
						this.$toast.add({
							severity: 'error',
							summary: 'Adding the database was unsuccessful.',
							detail: err.message
						});
					});
			} else {
				this.$toast.add({
					severity: 'error',
					summary: 'Adding the database was unsuccessful.',
					detail: 'Key information missing.'
				});
			}
		},
		loadDatabases() {
			this.dbService.getDatabases().then(res => {
				res.data.forEach(element => {
					element.loadingCycles = false;
				});

				this.databases = res.data;
			});
		},
		aggregateSiteList(dbid) {
			// console.log(dbid);
			this.dbService.aggregateSiteList(dbid).then(res => {
				this.$toast.add({
					severity: 'success',
					summary: 'Aggregation successful',
					detail: res.data.message,
					life: 1000
				});
			});
		}
	}
};
</script>
