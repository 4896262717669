import axios from 'axios'; 

export default class DatabasePovider {
	createNewDatabase(form) {
		return axios({
			method: 'post',
			url: '/admin/DB/new',
			data: form
		})
			.then(()=> this.$toast.add({severity:'error',summary: 'Database successfully created.', 
				detail: `Name: ${form.data.dbname}  Host: ${form.data.dbhost} User: ${form.data.dbuser}`}))
			.catch((err) => {
				this.$toast.add({severity:'error', summary: 'Database successfully created.', detail: err.message});
			});
	}

	getDatabases(){
		return axios({
			method: 'get',
			url: '/admin/DB/list'
		});
	}

	searchDatabases(query) {
		return axios({
			method: 'get',
			url: '/admin/DB/search',
			params: {
				query: query
			}
		});
	}

	aggregateSiteList(dbid){
		var form = {
			'dbid': dbid
		};
		return axios({
			method: 'post',
			url: '/admin/sites/aggregate',
			data: form
		});
	}
} 