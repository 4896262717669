import axios from 'axios'; 

export default class SiteInfoProvider {
	search(site_name){
		return axios({
			method: 'get',
			url: '/admin/sites/search',
			params: {
				'site_name': site_name
			}
		});
	}

	getSiteMeters(site_name) {
		return axios({
			method: 'get',
			url: '/admin/sites/meters',
			params: {
				'site_name': site_name
			}
		});
	}

	siteNameExists(site_name) {
		return axios({
			method: 'get',
			url: '/admin/sites/exists',
			params: {
				'site_name': site_name
			}
		});
	}

	getLogYears(site_id, prefix, month, day) {
		var params = {
			site_id: site_id,
			prefix: prefix
		};
		
		if (month != undefined) {
			params.month = month.value;
		}

		if (day != undefined) {
			params.day = day.value;
		}

		return axios({
			method: 'get',
			url: '/admin/logs/years',
			params: params
		});
	}

	getLogMonths(site_id, prefix, year, day) {
		var params = {
			site_id: site_id,
			prefix: prefix
		};
		
		if (year != undefined) {
			params.year = year.value;
		}

		if (day != undefined) {
			params.day = day.value;
		}

		return axios({
			method: 'get',
			url: '/admin/logs/months',
			params: params
		});
	}

	
	getLogDays(site_id, prefix, year, month) {
		var params = {
			site_id: site_id,
			prefix: prefix
		};
		
		if (year != undefined) {
			params.year = year.value;
		}

		if (month != undefined) {
			params.month = month.value;
		}

		return axios({
			method: 'get',
			url: '/admin/logs/days',
			params: params
		});
	}

	getLogs(site_id, prefix, year, month, day) {
		var params = {
			site_id: site_id,
			prefix: prefix
		};
		
		if (year != undefined) {
			params.year = year.value;
		}

		if (month != undefined) {
			params.month = month.value;
		}

		if (day != undefined) {
			params.day = day.value;
		}

		return axios({
			method: 'get',
			url: '/admin/logs/get',
			params: params
		});
	}

	getCouncils(database_id) {
		return axios({
			method: 'get',
			url: '/admin/sites/councils/get',
			params: {
				database_id: database_id
			}
		});
	}
} 