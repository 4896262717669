import axios from 'axios';

export default class BillingProvider {
	generate(site_name, cycle_id){
		return axios({
			method: 'post',
			url: '/billing/generate',
			data: {
				site_name: site_name,
				cycle_id: cycle_id
			}
		});
	}
    
	rollback(site_name, cycle_id){
		return axios({
			method: 'post',
			url: '/billing/rollback',
			data: {
				site_name: site_name,
				cycle_id: cycle_id
			}
		});
	}

	final(site_name, cycle_id){
		return axios({
			method: 'post',
			url: '/billing/final',
			data: {
				site_name: site_name,
				cycle_id: cycle_id
			}
		});
	}

	info(site_name){
		return axios({
			method: 'get',
			url: '/billing/info',
			params: {
				site_name: site_name
			}
		});
	}

	exportBilling(site_name, year, month, type, file_type) {
		return axios({
			method: 'get',
			url: '/billing/export',
			params: {
				site_name: site_name,
				billing_year: year,
				billing_month: month,
				billing_type: type,
				file_type: file_type
			}
		});
	}

	viewBilling(site_name, billing_year, billing_month, billing_type){
		return axios({
			method: 'get',
			url: '/billing/manual/view',
			params: {
				site_name: site_name,
				billing_year: billing_year,
				billing_month: billing_month,
				billing_type: billing_type
			}
		});
	}

	exportBillingAsCSV(site_name, year, month, type) {
		return this.exportBilling(site_name, year, month, type, 'csv')
			.then((res) => {
				let filename = res.headers['content-disposition'];
				filename = filename.split('filename=');
				filename = filename[1];
				filename = filename.replace(new RegExp('"', 'g'), '');

				let csv = res.data;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

				if (window.navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(blob, filename);
				}
				else {
					let link = document.createElement('a');
					link.style.display = 'none';
					document.body.appendChild(link);
					if (link.download !== undefined) {
						link.setAttribute('href', URL.createObjectURL(blob));
						link.setAttribute('download', filename);
						link.click();
					}
					else {
						csv = 'data:text/csv;charset=utf-8,' + csv;
						window.open(encodeURI(csv));
					}
					document.body.removeChild(link);
				}
			});
	}

	exportBillingAsXLSX(site_name, year, month, type) {
		return axios({
			method: 'get',
			url: '/billing/export',
			params: {
				site_name: site_name,
				billing_year: year,
				billing_month: month,
				billing_type: type,
				file_type: 'xlsx'
			},
			responseType: 'blob'
		}).then((res) => {
			let filename = res.headers['content-disposition'];
			filename = filename.split('filename=');
			filename = filename[1];
			filename = filename.replace(new RegExp('"', 'g'), '');

			let contentType = res.headers['content-type'];

			let xlsx = res.data;
			const blob = new Blob([xlsx], { type: contentType });

			if (window.navigator.msSaveOrOpenBlob) {
				navigator.msSaveOrOpenBlob(blob, filename);
			}
			else {
				let link = document.createElement('a');
				link.style.display = 'none';
				document.body.appendChild(link);
				if (link.download !== undefined) {
					link.setAttribute('href', URL.createObjectURL(blob));
					link.setAttribute('download', filename);
					link.click();
				}
				else {
					xlsx = 'data:' + contentType + ';' + xlsx;
					window.open(encodeURI(xlsx));
				}
				document.body.removeChild(link);
			}
		});
	}

	getCycles(site_id) {
		return axios({
			method: 'get',
			url: '/billing/manual/cycles',
			params: {
				site_id: site_id
			}
		});
	}

}