<template>
  <div class="p-field p-grid justify-center">
    <div class="flex justify-center flex-col">
      <span class="text-2xl">
        New Cycle for All Sites
      </span>
      <div class="p-fluid p-field p-grid p-col justify-center mt-5">
        <div class="ml-2">
          <label for="date_range">Date range</label>
          <n-config-provider
            :theme-overrides="{ common: { primaryColor: '#e5b52b' } }"
            :theme="darkTheme"
          >
            <Calender
              id="date_range"
              v-model:value="cycle.date_range"
              type="daterange"
              :theme="darkTheme"
              size="large"
              :disabled="loading"
              clearable
            />
          </n-config-provider>
        </div>
        <div class="ml-2">
          <label for="cycle_code">Billing Cycle</label>
          <div class="flex flex-row">
            <Dropdown
              v-model="cycle.cycle_code_month"
              :class="{ 'border-red-500': errors.cycle_code}"
              :options="cycle_months"
              :disabled="loading"
              placeholder="MONTH"
              @change="cycle.saved = false"
            />
            <Dropdown
              v-model="cycle.cycle_code_year"
              :class="{ 'border-red-500': errors.cycle_code}"
              :options="cycle_years"
              :disabled="loading"
              placeholder="YEAR"
              @change="cycle.saved = false"
            />
          </div>
        </div>
        <div class="ml-2">
          <label for="save_buttons"><pre>&nbsp;</pre></label>
          <div
            id="save_button"
            class="w-36 flex flex-row"
          >
            <Button
              v-show="!cycle.saved"
              label="Submit."
              :loading="loading"
              :disabled="untouched && cycle_code_month != null && cycle_code_year != null"
              @click="submitNewCycle(cycle, index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="displayErrors"
    :modal="true"
    :breakpoints="{'960px': '75vw'}" 
    :style="{width: '50vw'}"
  >
    <template #header>
      <h3>Header</h3>
    </template>

    <template
      v-for="(site, index) in cycleErors"
      :key="index"
    >
      <div class="text-3xl flex justify-center">
        {{ index }}
      </div>
      <template
        v-for="(info, infoIndex) in site"
        :key="infoIndex"
      >
        <div class="flex justify-center">
          <span class="font-bold">
            CYCLE ID: &nbsp;
          </span>
          {{ info.cycle_id }} &nbsp;
          <span class="font-bold">
            DATE RANGE: &nbsp;
          </span>
          {{ info.from_date }}
          &#32;to &#32;
          {{ info.to_date }}
        </div>
      </template>
    </template>
  </Dialog>
</template>

<script>
import SetupService from '../../services/SetupProvider';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import { NDatePicker, NConfigProvider } from 'naive-ui';
import { darkTheme } from 'naive-ui';

export default {
	components:{
		Dropdown,
		Calender: NDatePicker,
		NConfigProvider,
		Dialog
	},
	data(){
		return {
			cycle: {
				date_range: [
					new Date().getTime(), new Date().getTime()+2160000000
				],
				cycle_code_month: null,
				cycle_code_year: null
			},
			cycle_months: [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC'
			],
			cycle_years: [],
			loading: false,
			errors: {
				cycle_code: false,
				date_range: false
			},
			untouched: true,
			cycleErors: {},
			displayErrors: false
		};
	},
	created(){
		this.setupService = new SetupService();
		this.darkTheme = darkTheme;
		for (let index = 2018; index < 2030; index++) {
			this.cycle_years.push(index);
		}
	},
	methods: {
		dateToDateString(date){
			date = new Date(date);
			return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
		},
		submitNewCycle(){
			this.loading = true;
			this.setupService.newCycleForAllSites(
				this.dateToDateString(this.cycle.date_range[0]),
				this.dateToDateString(this.cycle.date_range[1]),
				this.cycle.cycle_code_month + this.cycle.cycle_code_year
			)
				.then((res) => {
					this.cycle = {
						date_range: [
							new Date().getTime(), new Date().getTime()+2160000000
						],
						cycle_code_month: null,
						cycle_code_year: null
					};
					this.loading = false;
					this.$toast.add({
						severity: 'success',
						summary: 'Cycles successfully created!',
						detail: res.data.message
					});
				})
				.catch((err) => {
					console.log(err);
					if (err.response.data.errorType == 2){
						this.cycleErors = err.response.data.errors;
						this.displayErrors = true;
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Could not create cycle!',
							detail: err.response.data.message
						});
						this.errors.cycle_code = true;
						this.errors.date_range = true;
					}
					this.loading = false;
          
				});
		}
	}
};
</script>

<style>

</style>