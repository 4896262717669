export default class DateFormatProvider {
	getHumanDate(date) {
		date = new Date(date);
		var months = [
			'January',
			'February',
			'March',
			'April',
			'May',
			'June',
			'July',
			'August',
			'September',
			'October',
			'November',
			'December'
		];

		var weekdays = [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		];

		var suffix;

		var dayOfTheMonth = date.getDate();

		if (dayOfTheMonth <= 3 && dayOfTheMonth >= 21) {
			switch (dayOfTheMonth % 10) {
			case 1:
				suffix = 'st';
				break;
			case 2:
				suffix = 'nd';
				break;
			case 3:
				suffix = 'rd';
				break;
			default:
				suffix = 'th';
			}
		} else {
			suffix = 'th';
		}

		var dateString =
            weekdays[date.getDay()] +
            ', ' +
            dayOfTheMonth +
            suffix +
            ' ' +
            months[date.getMonth()] +
            ' ' +
            date.getFullYear();

		return dateString;
	}
} 