<template>
  <div class="">
    <span class="p-input-icon-left max-w-min mb-3 mx-0 px-0">
      <i class="pi pi-search text-3xl -mt-4" />
      <InputText
        v-model="searchTerm"
        type="text"
        placeholder="Sites."
        class="text-5xl border-t-0 border-r-0 border-l-0 rounded-md focus:border-urborange border-4 text-center"
      />
    </span>
    <div
      class="divide-solid divide-y border-t divide-urbslate border-b bg-urbcharcoal rounded-xl"
    >
      <div
        v-for="site in siteList"
        v-show="searchList(site.site_name)"
        :key="site.site_name"
        class="p-8 mb-5"
        role="list-item"
      >
        <div class="flex float-left site-name">
          <template v-if="loading">
            <Skeleton
              :width="site.skelesize"
              class="p-mb-2"
              height="1.4rem"
            />
          </template>
          <template v-else>
            <span
              class="text-xl text-urbtext"
            >{{ site.site_name }}
              <i
                v-if="site.reading_type === 'UTIL'"
                class="pi pi-cloud"
                style="font-size: 1.5rem;"
              /></span>
          </template>
        </div>
        <div class="flex float-right site-buttons">
          <div class="md:flex">
            <template v-if="loading">
              <template
                v-for="(skele, index) in site.skelebuttons"
                :key="index"
              >
                <Skeleton
                  width="7rem"
                  height="2.25rem"
                  class="mx-1"
                />
              </template>
              <Skeleton
                width="2rem"
                height="2.25rem"
                class="mx-1"
              />
            </template>
            <template v-else-if="isRole('OPERATOR')">
              <template v-if="site.reading_type === 'MOBIXT'">
                <SplitButton
                  v-if="!site.billing_status"
                  :model="site.editItems"
                  icon="pi pi-pencil"
                  label="Edit"
                  class="ml-1 h-10"
                  :loading="site.billing_in_progress"
                  @click="toEditPage(site.site_name)"
                />
                <!-- <SplitButton
                v-if="!site.billing_status"
                :model="site.editItems"
                icon="pi pi-pencil"
                label="Unread"
                class="ml-1 h-10"
                @click="toUnreadPage(site.site_name)"
              /> -->
                <SplitButton
                  v-if="site.billing_status"
                  :model="site.billingItems"
                  icon="pi pi-money-bill"
                  label="Billing"
                  class="ml-1 h-10"
                  @click="toBillingPage(site)"
                />
                <Button
                  icon="pi pi-eye"
                  label="View"
                  class="ml-1 h-10"
                  @click="toViewPage(site.site_name)"
                />
                <!-- <Button
                icon="pi pi-cog"
                class="ml-1 h-10"
              /> -->
              </template>
              <template v-else-if="site.reading_type === 'UTIL'">
                <SplitButton
                  :model="site.billingItems"
                  icon="pi pi-money-bill"
                  label="Billing"
                  class="ml-1 h-10"
                  @click="toBillingPage(site.site_name)"
                />
              </template>
            </template>
            <template v-else-if="isRole('OBSERVER')">
              <Button
                icon="pi pi-eye"
                label="View"
                class="ml-1 h-10"
                @click="toViewOnlyPage(site.site_name)"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skeleton from 'primevue/skeleton';
import InputText from 'primevue/inputtext';
import axios from 'axios';
import BillingService from '../services/BillingProvider';
import LegacyBillingService from '../services/LegacyBillingProvider';
import ShortDateService from '../services/ShortDateProvider';

import { mapGetters } from 'vuex';

import SplitButton from 'primevue/splitbutton';

export default {
	name: 'ListComponent',
	components: {
		InputText,
		Skeleton,
		SplitButton
	},
	data() {
		return {
			siteList: [],
			search: '',
			loading: true,
			searchTerm: ''
		};
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			user: 'auth/user'
		})
	},
	created() {
		this.billingService = new BillingService();
		this.legacyBillingService = new LegacyBillingService();
	},
	mounted() {
		for (var i = 0; i < window.innerHeight / 80; i++) {
			var skelesize = Math.floor(Math.random() * 11) + 10 + 'rem';
			var skelebuttoncount = Math.floor(Math.random() * 1) + 2;
			var skelebuttons = [];
			while (skelebuttoncount > 0) {
				skelebuttons.push(skelebuttoncount);
				skelebuttoncount--;
			}
			var singleRow = {
				skelesize: skelesize,
				skelebuttons: skelebuttons,
				site_name: 'TEMP'
			};

			this.siteList.push(singleRow);
		}
		let endpoint;
		if (this.isRole('OPERATOR')) {
			endpoint = 'site/list';
		} else if (this.isRole('OBSERVER')) {
			endpoint = 'view/site/list';
		}
		axios.get(endpoint).then(res => {
			// this.siteList = res.data.;
			let sites = [];
			for (const site in res.data.sites) {
				sites.push(res.data.sites[site]);
			}
			this.siteList = sites;
			this.siteList.forEach(element => {
				if (element.reading_type != 'MOBIXT'){
					const billingItems = [
						{
							label: 'Export Billing',
							command: () => {
								const shortDateService = new ShortDateService;
								console.log(element.cycle_code.substring(0, 3));
								const date = shortDateService.shortDateToProperDate(element.cycle_code);
								this.billingService.exportBillingAsCSV(
									element.site_name, 
									date.getFullYear(), 
									element.cycle_code.substring(0, 3)
								);
							}
						}
					];
					element.billingItems = billingItems;
				} else {
					element.billingItems = [];
					element.editItems = [];

					if (element.billing_type === 'new'){
						element.billingItems.push({
							label: 'Rollback Billing',
							command: () => {
								this.rollbackNewBilling(element);
							}
						});
						element.billingItems.push({
							label: 'Export Billing',
							command: () => {
								const shortDateService = new ShortDateService;
								console.log(element.cycle_code.substring(0, 3));
								const date = shortDateService.shortDateToProperDate(element.cycle_code);
								this.billingService.exportBillingAsCSV(
									element.site_name, 
									date.getFullYear(), 
									element.cycle_code.substring(0, 3)
								);
							}
						});
						element.editItems = [
							{
								label: 'Generate Billing',
								command: () => {
									this.newBilling(element);
								}
							}
						];
					} else if (element.billing_type === 'old'){
						element.billingItems.push({
							label: 'Rollback Billing',
							command: () => {
								this.rollbackLegacyBilling(element);
							}
						});
						element.billingItems.push({
							label: 'Export Electric Billing',
							command: () => {
								this.legacyBillingService.exportBillingAsCSV(
									element.site_name, 
									element.cycle_id,
									'electricity'
								);
							},
							visible: (element.U_Electricity === 1)
						});
						element.billingItems.push({
							label: 'Export Cold Water Billing',
							command: () => {
								this.legacyBillingService.exportBillingAsCSV(
									element.site_name, 
									element.cycle_id,
									'cold_water',
								);
							},
							visible: (element.U_Cold_Water === 1)
						});
						element.editItems = [
							{
								label: 'Generate Billing',
								command: () => {
									this.legacyBilling(element);
								}
							}
						];
					}
				}
				element.billing_in_progress = false;
			});
			// console.log(this.siteList);
			this.loading = false;
		});
	},
	methods: {
		searchList: function(s) {
			if (s !== '') {
				return s.toUpperCase().includes(this.searchTerm.toUpperCase());
			}
		},
		toViewPage: function(site_name) {
			this.$router.push('/site/' + site_name + '/view');
		},
		toViewOnlyPage: function(site_name) {
			this.$router.push('/site/' + site_name + '/view_only');
		},
		toBillingPage: function(site) {
			if (site.billing_type === 'old'){
				this.$router.push('/site/' + site.site_name + '/billing/old');
			} else {
				this.$router.push('/site/' + site.site_name + '/billing');
			}
		},
		toEditPage: function(site_name) {
			this.$router.push('/site/' + site_name + '/edit');
		},
		toUnreadPage: function(site_name) {
			this.$router.push('/site/' + site_name + '/unread');
		},
		exportBilling: function(site_name, billing_year, billing_month, type) {
			this.billingService
				.exportBillingAsCSV(site_name, billing_year, billing_month, type)
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Export failed!',
						detail: err.response.data.message
					});
				});
		},
		legacyBilling: function(element) {
			element.billing_in_progress = true;
			return this.legacyBillingService
				.generate(element.site_name, element.cycle_id)
				.then(res => {
					element.billing_in_progress = false;
					element.billing_status = 1;
					this.$toast.add({
						severity: 'success',
						summary: 'Legacy billing successfully generated!',
						detail: res.message
					});
				})
				.catch(err => {
					element.billing_in_progress = false;
					element.billing_status = 0;
					this.$toast.add({
						severity: 'error',
						summary: 'Legacy billing generation failed!',
						detail: err.message
					});
				});
		},
		newBilling: function(element) {
			element.billing_in_progress = true;
			return this.billingService.generate(element.site_name, element.cycle_id)
				.then(res => {
					element.billing_in_progress = false;
					element.billing_status = 1;
					this.$toast.add({
						severity: 'success',
						summary: 'Billing successfully generated!',
						detail: res.message
					});
				})
				.catch(err => {
					element.billing_in_progress = false;
					element.billing_status = 0;
					this.$toast.add({
						severity: 'error',
						summary: 'Billing generation failed!',
						detail: err.message
					});
				});
		},
		rollbackNewBilling(element){
			return this.billingService
				.rollback(element.site_name, element.cycle_id)
				.then(res => {
					element.billing_in_progress = false;
					element.billing_status = 0;
					this.$toast.add({
						severity: 'success',
						summary: 'Rollback successful for ' + element.site_name + '!',
						detail: res.message
					});
				})
				.catch(err => {
					element.billing_in_progress = false;
					element.billing_status = 1;
					this.$toast.add({
						severity: 'error',
						summary: 'Rollback failed for ' + element.site_name + '!',
						detail: err.message
					});
				});
		},
		rollbackLegacyBilling(element){
			return this.legacyBillingService
				.rollback(element.site_name)
				.then(res => {
					element.billing_in_progress = false;
					element.billing_status = 0;
					this.$toast.add({
						severity: 'success',
						summary: 'Rollback successful for ' + element.site_name + '!',
						detail: res.message
					});
				})
				.catch(err => {
					element.billing_in_progress = false;
					element.billing_status = 1;
					this.$toast.add({
						severity: 'error',
						summary: 'Rollback failed for ' + element.site_name + '!',
						detail: err.message
					});
				});
		},
		isRole($role) {
			if (this.user && this.user.roles) {
				if (this.authenticated) {
					return this.user.roles.indexOf($role) > -1 ? true : false;
				}
			}
			return false;
		}
	}
};
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
	.p-toolbar[role='list-item'] {
		color: pink !important;
		text-align: center !important;
	}
}

@media (min-width: 767px) {
	.p-toolbar[role='list-item'] {
		padding-left: 20%;
		padding-right: 20%;
		margin-left: 2rem !important;
		margin-right: 2rem !important;
	}
	.site-name {
		margin-left: 24%;
	}
	.site-buttons {
		margin-right: 24%;
	}
}

.p-inputtext {
	width: 50vw;
}

.p-button-label {
	display: none !important;
}
</style>
