<template>
  <template v-if="!submitLoading">
    <div class="p-d-flex p-flex-column">
      <div class="p-mb-2">
        <label for="dbselect">Select a database to continue</label>
      </div>
      <div
        id="dbselect"
        class="p-field p-d-flex  p-jc-center"
      >
        <template
          v-for="db in databases"
          :key="db.id"
        >
          <div class="p-mr-2">
            <Button
              v-if="db.show"
              :label="db.dbname"
              :class="db.class"
              @click="selectDatabase(db)"
            /><br>
          </div>
        </template>
        <i
          v-if="site_info_form.database_id != ''"
          class="pi pi-times"
          @click="cancel()"
        />
      </div>
      <div
        v-if="site_info_form.database_id != ''"
        class="p-field"
      >
        <div class="p-mb-2">
          <label for="site_name">Site Name</label>
        </div>
        <div class="p-mb-2 align-middle">
          <span class="p-input-icon-right">
            <InputText
              id="site_name"
              v-model="site_info_form.site_name"
              type="site_name"
              aria-describedby="site_name-help"
              :class="site_validations.name_input_classes"
              :disabled="site_validations.name_input_locked"
              @input="validateSiteName()"
            />
            <i
              v-if="site_validations.name_input_validation_load"
              class="pi pi-spin pi-spinner"
            />
          </span>
        </div>
        <div class="p-mb-2">
          <small
            v-if="
              site_info_form.site_name.length < 1 &&
                site_validations.name_input_valid != undefined
            "
            id="site_name-help"
            class="p-error"
          >Site name is required.</small>
          <small
            v-else-if="
              !site_validations.name_input_valid &&
                site_validations.name_input_valid != undefined
            "
            id="site_name-help"
            class="p-error"
          >Site name is not available. Try adding extra details to the
            name.</small>
        </div>
        <div>
          <Button
            v-if="!cancelButton"
            :disabled="
              !site_validations.name_input_valid ||
                site_validations.name_input_locked
            "
            @click="showForm()"
          >
            PROCEED
          </Button>
          <Button
            v-else
            @click="cancel()"
          >
            CANCEL
          </Button>
        </div>
      </div>
      <template v-if="site_info_options.show">
        <div class="p-field  p-grid  p-jc-center">
          <label
            for="water_council"
            class="p-col-fixed"
            style="width:200px"
          >Water Council</label>
          <Dropdown
            id="water_council"
            v-model="site_info_form.water_council"
            :options="site_info_options.water_councils"
            optionLabel="name"
            style="width:300px"
            panelClass="p-col"
            :disabled="
              !site_info_form.council_charges.water &&
                !site_info_form.additional_charges.hot_water.active
            "
          />
        </div>
        <div class="p-field  p-grid  p-jc-center">
          <label
            for="electricity_council"
            class="p-col-fixed"
            style="width:200px"
          >Electricity Council</label>
          <Dropdown
            id="electricity_council"
            v-model="site_info_form.electricity_council"
            :options="site_info_options.electricity_councils"
            optionLabel="name"
            style="width:300px"
            panelClass="p-col"
            :disabled="!site_info_form.council_charges.electricity"
          />
        </div>
        <div class="p-field  p-grid  p-jc-center">
          <label
            for="electricity_council"
            class="p-col-fixed"
            style="width:200px"
          >Old age home</label>
          <InputSwitch v-model="site_info_form.old_age_home" />
        </div>

        <Divider class="border-t-2 border-gray-500" />
        <div class="p-grid">
          <div class="p-col-4">
            <div class="text-xl mb-5">
              Council Charges
            </div>
            <div class="box">
              <div class="p-field  p-grid  flex justify-center">
                <label
                  for="council_charge_water"
                  class="p-col-fixed"
                  style="width:200px"
                >Water</label>
                <InputSwitch
                  v-model="site_info_form.council_charges.water"
                  class="mt-1 mr-4"
                />
                <span class="p-float-label">
                  <InputText
                    id="waterGLUsage"
                    v-model="site_info_form.council_charges.waterGLUsage"
                    mode="decimal"
                    class="w-28"
                  />
                  <label for="waterGLUsage">GL Usage</label>
                </span>
                <span class="p-float-label">
                  <InputText
                    id="waterGLFixed"
                    v-model="site_info_form.council_charges.waterGLFixed"
                    mode="decimal"
                    class="w-28 ml-1"
                  />
                  <label for="waterGLFixed">GL Fixed</label>
                </span>
              </div>
              <div class="p-field  p-grid  p-jc-center">
                <label
                  for="council_charge_electricity"
                  class="p-col-fixed"
                  style="width:200px"
                >Electricity</label>
                <InputSwitch
                  v-model="site_info_form.council_charges.electricity"
                  class="mt-1 mr-4"
                />
                <span class="p-float-label">
                  <InputText
                    id="electricityGLUsage"
                    v-model="site_info_form.council_charges.electricityGLUsage"
                    mode="decimal"
                    class="w-28"
                  />
                  <label for="electricityGLUsage">GL Usage</label>
                </span>
                <span class="p-float-label">
                  <InputText
                    id="electricityGLFixed"
                    v-model="site_info_form.council_charges.electricityGLFixed"
                    mode="decimal"
                    class="w-28 ml-1"
                  />
                  <label for="electricityGLFixed">GL Fixed</label>
                </span>
              </div>
              <div
                v-if="site_info_form.council_charges.water"
                class="p-field  p-grid  p-jc-center"
              >
                <label
                  for="council_charge_sanitation"
                  class="p-col-fixed"
                  style="width:200px"
                >Sanitation</label>
                <InputSwitch
                  v-model="site_info_form.council_charges.sanitation"
                  class="mt-1 mr-4"
                />
                <span class="p-float-label">
                  <InputText
                    id="sanitationGLUsage"
                    v-model="site_info_form.council_charges.sanitationGLUsage"
                    mode="decimal"
                    class="w-28"
                  />
                  <label for="sanitationGLUsage">GL Usage</label>
                </span>
                <span class="p-float-label">
                  <InputText
                    id="sanitationGLFixed"
                    v-model="site_info_form.council_charges.sanitationGLFixed"
                    mode="decimal"
                    class="w-28 ml-1"
                  />
                  <label for="sanitationGLFixed">GL Fixed</label>
                </span>
              </div>
            </div>
          </div>
          <div class="p-col-8">
            <table>
              <tr>
                <th
                  colspan="4"
                  class="text-xl pb-5"
                >
                  Additional Charges
                </th>
              </tr>
              <tr>
                <td>
                  <div class="p-field  p-grid  p-jc-center">
                    <label
                      for="additional_charge_hot_water"
                      class="p-col-fixed"
                      style="width:200px"
                    >Hot_water</label>
                    <InputSwitch
                      v-model="
                        site_info_form.additional_charges.hot_water.active
                      "
                    />
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <span class="p-float-label">
                      <InputNumber
                        id="hot_water_rate"
                        v-model="
                          site_info_form.additional_charges.hot_water.rate
                        "
                        mode="decimal"
                        :minFractionDigits="2"
                        prefix="R "
                      />
                      <label for="hot_water_rate">Rate</label>
                    </span>
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <RadioButton
                      id="hot_water_per_unit"
                      v-model="
                        site_info_form.additional_charges.hot_water.method
                      "
                      value="per Unit Cons."
                    />
                    <label
                      class="pl-4 pr-4"
                      for="hot_water_per_unit"
                    >per Unit Cons.</label>
                    <RadioButton
                      id="hot_water_fixed_charge"
                      v-model="
                        site_info_form.additional_charges.hot_water.method
                      "
                      value="fixed rate"
                    />
                    <label
                      class="pl-4 pr-4"
                      for="hot_water_fixed_charge"
                    >fixed rate</label>
                  </div>
                </td>
                <td class="flex flex-row">
                  <span class="p-float-label">
                    <InputText
                      id="hotWaterGLCode"
                      v-model="
                        site_info_form.additional_charges.hot_water.GLCode
                      "
                      mode="decimal"
                      class="w-28"
                    />
                    <label for="hotWaterGLCode">GL Code</label>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="p-field  p-grid  p-jc-center">
                    <label
                      for="additional_charge_gas"
                      class="p-col-fixed"
                      style="width:200px"
                    >Gas</label>
                    <InputSwitch
                      v-model="site_info_form.additional_charges.gas.active"
                    />
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <span class="p-float-label">
                      <InputNumber
                        id="gas_rate"
                        v-model="site_info_form.additional_charges.gas.rate"
                        mode="decimal"
                        :minFractionDigits="2"
                        prefix="R "
                      />
                      <label for="gas_rate">Rate</label>
                    </span>
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <RadioButton
                      id="gas_per_unit"
                      v-model="site_info_form.additional_charges.gas.method"
                      value="per Unit Cons."
                    />
                    <label
                      class="pl-4 pr-4"
                      for="gas_per_unit"
                    >per Unit Cons.</label>
                    <RadioButton
                      id="gas_fixed_charge"
                      v-model="site_info_form.additional_charges.gas.method"
                      value="fixed rate"
                    />
                    <label
                      class="pl-4 pr-4"
                      for="gas_fixed_charge"
                    >fixed rate</label>
                  </div>
                </td>
                <td class="flex flex-row">
                  <span class="p-float-label">
                    <InputText
                      id="gasGLCode"
                      v-model="site_info_form.additional_charges.gas.GLCode"
                      mode="decimal"
                      class="w-28"
                    />
                    <label for="gasGLCode">GL Code</label>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="p-field  p-grid  p-jc-center">
                    <label
                      for="additional_charge_admin"
                      class="p-col-fixed"
                      style="width:200px"
                    >Admin</label>
                    <InputSwitch
                      v-model="site_info_form.additional_charges.admin.active"
                    />
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <span class="p-float-label">
                      <InputNumber
                        id="admin_rate"
                        v-model="site_info_form.additional_charges.admin.rate"
                        mode="decimal"
                        :minFractionDigits="2"
                        prefix="R "
                      />
                      <label for="admin_rate">Rate</label>
                    </span>
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <RadioButton
                      id="admin_per_unit"
                      v-model="site_info_form.additional_charges.admin.method"
                      value="per Unit Cons."
                    />
                    <label
                      class="pl-4 pr-4"
                      for="admin_per_unit"
                    >per Unit Cons.</label>
                    <RadioButton
                      id="admin_fixed_charge"
                      v-model="site_info_form.additional_charges.admin.method"
                      value="fixed rate"
                    />
                    <label
                      class="pl-4 pr-4"
                      for="admin_fixed_charge"
                    >fixed rate</label>
                  </div>
                </td>
                <td class="flex flex-row">
                  <span class="p-float-label">
                    <InputText
                      id="adminGLCode"
                      v-model="site_info_form.additional_charges.admin.GLCode"
                      mode="decimal"
                      class="w-28"
                    />
                    <label for="adminGLCode">GL Code</label>
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="p-field  p-grid  p-jc-center">
                    <label
                      for="additional_charge_vat"
                      class="p-col-fixed"
                      style="width:200px"
                    >VAT</label>
                    <InputSwitch
                      v-model="site_info_form.additional_charges.vat.active"
                    />
                  </div>
                </td>
                <td class="pl-10">
                  <div class="p-field  p-grid  p-jc-center">
                    <span class="p-float-label">
                      <InputNumber
                        id="vat_rate"
                        v-model="site_info_options.vat"
                        mode="decimal"
                        :minFractionDigits="0"
                        suffix="%"
                        disabled
                      />
                      <label for="vat_rate">Rate</label>
                    </span>
                  </div>
                </td>
                <td />
                <td class="flex flex-row">
                  <span class="p-float-label">
                    <InputText
                      id="vatGLUsage"
                      v-model="site_info_form.additional_charges.vat.GLCode"
                      mode="decimal"
                      class="w-28"
                    />
                    <label for="vatGLUsage">GL Code</label>
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div>
          <Button @click="submit()">
            SUBMIT
          </Button>
        </div>
      </template>
    </div>
  </template>
  <template v-else>
    <ProgressSpinner />
  </template>
</template>

<script>
import { debounce } from 'lodash';
import SiteInfoService from '../services/SiteInfoProvider';
import DatabaseService from '../services/DatabaseProvider';
import SiteService from '../services/SiteProvider';

import Dropdown from 'primevue/dropdown';
import RadioButton from 'primevue/radiobutton';
import Divider from 'primevue/divider';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import ProgressSpinner from 'primevue/progressspinner';

export default {
	components: {
		Dropdown,
		Divider,
		InputSwitch,
		InputNumber,
		RadioButton,
		ProgressSpinner
	},
	data() {
		return {
			site_validations: {
				name_input_valid: undefined,
				name_input_validation_load: false,
				name_input_classes: 'text-center p-inputtext-lg',
				name_input_locked: false
			},
			submitLoading: false,
			cancelButton: false,
			databases: [],
			site_info_options: {
				water_councils: [],
				electricity_councils: [],
				additional_charges: {
					methods: ['per Unit Cons.', 'fixed rate']
				},
				vat: 0.15,
				show: false
			},
			site_info_form: {
				database_id: '',
				site_name: '',
				old_age_home: false,
				council_charges: {
					water: false,
					waterGLUsage: null,
					waterGLFixed: null,
					electricity: false,
					electricityGLUsage: null,
					electricityGLFixed: null,
					sanitation: false,
					sanitationGLUsage: null,
					sanitationGLFixed: null
				},
				additional_charges: {
					hot_water: {
						active: false,
						method: 'per Unit Cons.',
						rate: null,
						GLCode: null
					},
					gas: {
						active: false,
						method: 'per Unit Cons.',
						rate: null,
						GLCode: null
					},
					admin: {
						active: false,
						method: 'per Unit Cons.',
						rate: null,
						GLCode: null
					},
					vat: {
						active: true,
						GLCode: null
					}
				}
			}
		};
	},
	mounted() {
		this.siteInfoService = new SiteInfoService();
		this.siteService = new SiteService();
		this.databaseService = new DatabaseService();

		this.databaseService
			.getDatabases()
			.then(res => {
				res.data.forEach(element => {
					element.show = true;
					element.class = '';
				});
				this.databases = res.data;
			})
			.catch(err => {
				console.error(err);
			});
	},
	methods: {
		validateSiteName: debounce(function() {
			this.site_info_form.site_name = this.site_info_form.site_name.toUpperCase();
			this.siteInfoService
				.siteNameExists(this.site_info_form.site_name)
				.then(res => {
					if (!res.data) {
						this.site_validations.name_input_classes =
							'text-center p-inputtext-lg p-valid';
						this.site_validations.name_input_valid = true;
					} else {
						this.site_validations.name_input_classes =
							'text-center p-inputtext-lg p-invalid';
						this.site_validations.name_input_valid = false;
					}
				})
				.catch(err => {
					console.error(err);
				});
		}, 700),
		showForm() {
			this.site_validations.name_input_locked = true;
			this.site_info_options.show = true;
			this.cancelButton = true;
			this.siteInfoService
				.getCouncils(this.site_info_form.database_id)
				.then(res => {
					this.site_info_options.water_councils = res.data.councils;
					this.site_info_options.electricity_councils = res.data.councils;
				});
		},
		cancel() {
			this.site_validations.name_input_locked = false;
			this.site_info_options.show = false;
			this.cancelButton = false;
			this.site_info_form.database_id = '';
			this.site_info_form.site_name = '';
			this.databases.forEach(element => {
				element.show = true;
				element.class = '';
			});
			this.site_validations = {
				name_input_valid: undefined,
				name_input_validation_load: false,
				name_input_classes: 'text-center p-inputtext-lg',
				name_input_locked: false
			};
			this.site_info_form = {
				database_id: '',
				site_name: '',
				old_age_home: false,
				council_charges: {
					water: false,
					electricity: false,
					sanitation: false
				},
				additional_charges: {
					hot_water: {
						active: false,
						method: 'per Unit Cons.',
						rate: null
					},
					gas: {
						active: false,
						method: 'per Unit Cons.',
						rate: null
					},
					admin: {
						active: false,
						method: 'per Unit Cons.',
						rate: null
					},
					vat: {
						active: true
					}
				}
			};
		},
		selectDatabase(db) {
			this.site_info_form.database_id = db.id;
			this.databases.forEach(element => {
				if (element.id != db.id) {
					element.show = false;
				} else {
					element.class = 'p-button-success';
				}
			});
		},
		submit() {
			this.submitLoading = true;
			// console.log(this.site_info_form);
			this.siteService
				.newSite(this.site_info_form)
				.then(res => {
					this.$toast.add({
						severity: 'success',
						summary: this.site_info_form.site_name + ' successfully created.',
						detail: res.data.message
					});
					this.cancel();
					this.submitLoading = false;
				})
				.catch(err => {
					console.error(err);
					this.submitLoading = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong!',
						detail: 'An error has occurred while creating the site.'
					});
				});
		},
		validateSiteData() {
			if (this.site_info_form.scale === undefined) {
				return false;
			}
			if (this.site_info_form.level === undefined) {
				return false;
			}
			if (this.site_info_form.fixed_rates.length < 1) {
				return false;
			}
			if (this.site_info_form.utilities.length < 1) {
				return false;
			}
			return true;
		}
	}
};
</script>

<style lang="scss" scoped>
.p-valid {
	border-color: rgb(57, 221, 93);
}

.utilities-item-value {
	display: inline-flex;

	img.flag {
		width: 17px;
	}
}
</style>
