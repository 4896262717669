<template>
  <div class="px-8">
    <Accordion
      :multiple="true"
      lazy
    >
      <AccordionTab>
        <template #header>
          <i class="pi pi-sort-alt" />
          <span class="ml-2"> Form Sequence Management </span>
        </template>
        <FormOrderComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-key" />
          <span class="ml-2"> Lookup Generate </span>
        </template>
        <LookupComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-tablet" />
          <span class="ml-2"> Manage Devices </span>
        </template>
        <DeviceManagementComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-user-plus" />
          <span class="ml-2"> Create New User</span>
        </template>
        <NewUserComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-map-marker" />
          <span class="ml-2"> Register a new site</span>
        </template>
        <NewSiteComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-clock" />
          <span class="ml-2"> Insert Meters</span>
        </template>
        <MeterComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-table" />
          <span class="ml-2"> Database Options </span>
        </template>
        <NewDatebaseComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-unlock" />
          <span class="ml-2">Site Permissions</span>
        </template>
        <UserPermissions />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-cog" />
          <span class="ml-2">Site Options</span>
        </template>
        <SiteSettingsComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-cog" />
          <span class="ml-2">Client Options</span>
        </template>
        <ClientSettingsComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-money-bill" />
          <span class="ml-2">Billing Options</span>
        </template>
        <BillingOptions />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-money-bill" />
          <i class="pi pi-money-bill" />
          <i class="pi pi-money-bill" />
          <span class="ml-2">Council Bill</span>
        </template>
        <Council />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-list" />
          <span class="ml-2"> Edit user roles </span>
        </template>
        <RoleComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-file-o" />
          <span class="ml-2"> View Logs</span>
        </template>
        <LogComponent />
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import NewDatebaseComponent from '../components/NewDatabaseComponent';
import UserPermissions from '../components/UserComponent';
import NewUserComponent from '../components/NewUserComponent';
import LogComponent from '../components/LogViewingComponent.vue';
import BillingOptions from '../components/BillingOptionsComponent.vue';
import RoleComponent from '../components/UserRoleComponent.vue';
import SiteSettingsComponent from '../components/SiteSettingsComponent.vue';
import ClientSettingsComponent from '../components/ClientDefaultSettingsComponent.vue';
import NewSiteComponent from '../components/NewSiteComponent.vue';
import MeterComponent from '../components/MeterComponent.vue';
import Council from '../components/CouncilComponent.vue';
import FormOrderComponent from '@/domains/Operator/components/Form/FormOrderComponent.vue';
import LookupComponent from '../components/LookupComponent.vue';
import DeviceManagementComponent from '../components/DeviceManagementComponent.vue';

export default {
	name: 'AdminOptions',
	components: {
    Accordion,
    AccordionTab,
    NewUserComponent,
    NewDatebaseComponent,
    UserPermissions,
    BillingOptions,
    LogComponent,
    RoleComponent,
    SiteSettingsComponent,
    ClientSettingsComponent,
    NewSiteComponent,
    MeterComponent,
    Council,
    FormOrderComponent,
    LookupComponent,
    DeviceManagementComponent
}
};
</script>
