<template>
  <div class="p-field p-grid">
    <label
      class="p-col-fixed"
      style="width:100px"
      for="site_name"
    >Site Name</label>
    <div
      class="p-col-fixed"
      style="width:100px"
    >
      <AutoComplete
        id="site_name"
        v-model="site"
        :suggestions="filteredSites"
        field="site_name"
        @complete="searchSites($event.query)"
        @item-select="showSettingsPopup()"
        @keyup.enter="showSettingsPopup()"
      />
    </div>
    <Dialog
      v-model:visible="showSettingsDialog"
      header="Header"
      :style="{ width: '50vw' }"
      :maximizable="true"
      :modal="true"
    >
      <template #header>
        <div class="text-4xl">
          Settings for {{ site.site_name }}
        </div>
      </template>
      <div class="p-d-flex p-jc-center p-fluid p-formgrid p-grid">
        <div class="p-mr-2 p-field text-center">
          <label for="from_day">From Day</label>
          <div class="p-col">
            <InputNumber
              id="from_day"
              v-model="site_settings.SITE_READING_CYCLE_FROM_DAY.value"
              v-tooltip.left="'The day where a new cycle begins.'"
              inputClass="text-center"
              showButtons
              :min="0"
              :max="30"
            />
          </div>
        </div>
        <div class="p-mr-2 p-field text-center">
          <label for="to_day">To Day</label>
          <div class="p-col">
            <InputNumber
              id="to_day"
              v-model="site_settings.SITE_READING_CYCLE_TO_DAY.value"
              v-tooltip.right="'The day where a new cycle ends.'"
              inputClass="text-center"
              showButtons
              :min="0"
              :max="30"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="SAVE"
          icon="pi pi-save"
          :loading="uploadingSettings"
          @click="saveSettings()"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
import SiteInfoService from '../services/SiteInfoProvider';
import SettingService from '../services/SettingsProvider';

import AutoComplete from 'primevue/autocomplete';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';

export default {
	components: {
		AutoComplete,
		Dialog,
		InputNumber
	},
	data() {
		return {
			site: [],
			filteredSites: [],
			showSettingsDialog: false,
			site_settings: [],
			uploadingSettings: false
		};
	},
	created() {
		this.siteInfoService = new SiteInfoService();
		this.settingService = new SettingService();
	},
	methods: {
		searchSites(query) {
			this.siteInfoService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		showSettingsPopup() {
			this.settingService.getSiteSettings(this.site.id).then(res => {
				this.site_settings = res.data.settings;
				this.site_settings.SITE_READING_CYCLE_TO_DAY.value = parseInt(
					this.site_settings.SITE_READING_CYCLE_TO_DAY.value
				);
				this.site_settings.SITE_READING_CYCLE_FROM_DAY.value = parseInt(
					this.site_settings.SITE_READING_CYCLE_FROM_DAY.value
				);
				this.showSettingsDialog = true;
			});
		},
		saveSettings() {
			this.uploadingSettings = true;
			this.settingService
				.setSiteSettings(this.site.id, this.site_settings)
				.then(res => {
					this.$toast.add({
						severity: 'success',
						summary: 'Settings saved!',
						detail: res.data.message,
						life: 1000
					});
					this.uploadingSettings = false;
				});
		}
	}
};
</script>

<style></style>
