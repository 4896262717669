import axios from 'axios';

export default class VerificationProvider {
	static checkToken(token, email){
		return axios({
			method: 'post',
			url: '/user/verify/check',
			data: {
				token: token,
				email: email
			}
		});
	}

	static verifyUser(email, token, password, password_confirmation){
		return axios({
			method: 'post',
			url: '/user/verify/finalize',
			data: {
				token: token,
				email: email,
				password: password,
				password_confirmation: password_confirmation
			}
		});
	}
}