<template>
  <DataTable
    ref="dt"
    v-model:filters="filters"
    :totalRecords="rowCount"
    filterDisplay="row"
    :value="readings"
    :paginator="true"
    :lazy="true"
    class="p-datatable-customers p-datatable-sm"
    :rows="10"
    dataKey="site_name"
    :rowHover="true"
    :loading="loading"
    editMode="cell"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    responsiveLayout="scroll"
    :globalFilterFields="['unit', 'meter_no', 'reading_date_disp']"
    @filter="onFilter($event)"
    @page="onPage($event)"
    @sort="onSort($event)"
  >
    <template #header>
      <span class="text-3xl"> {{ siteName }} (Viewing) </span>
    </template>
    <template #empty>
      No readings found.
    </template>
    <template #loading>
      Loading readings for {{ siteName }} data. Please wait.
    </template>
    <Column
      field="unit"
      header="Unit"
      sortable
    >
      <template #body="{data}">
        {{ data.unit }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by unit"
          @keydown.enter="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="meter_no"
      header="Meter No."
      sortable
    >
      <template #body="{data}">
        {{ data.meter_no }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by meter no."
          @keydown.enter="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_date_disp"
      header="Reading Date"
      sortable
    >
      <template #body="{data}">
        {{ data.reading_date_disp }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by reading date"
          @keydown.enter="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_new"
      header="New Reading"
      sortable
      style="width:10%"
    >
      <template #body="{data}">
        <template v-if="data.valid == 'UNREAD'">
          <span class="p-buttonset">
            <Button @click="fillBlank(data)">
              New
            </Button>
            <Button @click="fillEstimate(data)">
              Estimate
            </Button>
          </span>
        </template>
        <template v-else-if="data.reading_new == null">
          UNSET
        </template>
        <template v-else>
          {{ data.reading_new }}
        </template>
      </template>
      <template #editor="slotProps">
        <div class="p-fluid">
          <InputText v-model="slotProps.data[slotProps.column.props.field]" />
        </div>
      </template>
    </Column>
    <Column
      field="reading_old"
      header="Old Reading"
      sortable
    >
      <template #body="{data}">
        {{ data.reading_old }}
      </template>
    </Column>
    <Column
      field="consumption"
      header="Consumption"
      sortable
    >
      <template #body="{data}">
        {{ data.reading_new ? data.reading_new - data.reading_old : null }}
      </template>
    </Column>
    <Column
      field="file_name"
      header="IMG"
    >
      <template #body="slotProps">
        <FileUpload
          name="data.file_name"
          mode="basic"
          :auto="true"
          accept="image/*"
          :customUpload="true"
          :disabled="uploadEnabled(slotProps)"
          @uploader="uploadPicture($event, slotProps)"
        />
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import FileUpload from 'primevue/fileupload';

import SiteReadingService from '../services/SiteReadings';

import { FilterMatchMode } from 'primevue/api';

export default {
	components: {
		DataTable,
		Column,
		FileUpload
	},
	props: {
		siteName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			readings: [],
			loading: true,
			pagingParams: {},
			rowCount: 0,
			filters: {
				meter_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
				reading_date_disp: { value: null, matchMode: FilterMatchMode.CONTAINS }
			},
			pictureFile: []
		};
	},
	created() {
		this.siteReadingService = new SiteReadingService();
	},
	mounted() {
		this.pagingParams = {
			pageIndex: 0,
			pageSize: this.$refs.dt.rows,
			sortField: null,
			sortOrder: null,
			edit: 0,
			filters: this.filters,
			site_name: this.siteName,
			paging: true
		};

		// console.log(this.pagingParams);
		this.loadPagedData();
	},
	methods: {
		loadPagedData() {
			this.loading = true;

			setTimeout(() => {
				this.siteReadingService.getUnread(this.pagingParams).then(res => {
					this.readings = res.data.readings;
					this.rowCount = res.data.count;
					this.loading = false;

					this.readings.forEach(element => {
						element.pictureDialog = false;
					});
				});
			}, Math.random() * 1000 + 250);
		},
		onPage(event) {
			this.pagingParams.pageIndex = event.page;
			this.pagingParams.pageSize = event.rows;
			this.loadPagedData();
		},
		onSort(event) {
			this.pagingParams.sortField = event.sortField;
			this.pagingParams.sortOrder = event.sortOrder < 0 ? 'ASC' : 'DESC';
			this.loadPagedData();
		},
		onFilter() {
			this.pagingParams.filters = this.filters;
			this.loadPagedData();
		},
		fillEstimate(data) {
			data.reading_new = data.est_reading;
			data.valid = 'ESTIMATED-PENDING';
			return;
		},
		fillBlank(data) {
			data.valid = 'BLANK-PENDING';
			return;
		},
		uploadEnabled(props) {
			if (
				props.data.valid.indexOf('PENDING') > 1 &&
				props.data.reading_new != null
			) {
				return false;
			}
			return true;
		},
		uploadPicture(event, slotProps) {
			// console.log(slotProps.data);
			this.siteReadingService
				.pictureUpload(event.files[0], JSON.stringify(slotProps.data))
				.then(() => {
					this.siteReadingService
						.submitManualReading(slotProps.data)
						.then(() => {
							this.uploadComplete(slotProps);
						});
				});
		},
		uploadComplete(slotProps) {
			if (slotProps.data.valid.indexOf('ESTIMATED') > 1) {
				slotProps.data.valid = 'ESTIMATED';
			} else if (slotProps.data.valid.indexOf('BLANK') > 1) {
				slotProps.data.valid = 'BLANK';
			} else {
				slotProps.data.valid = 'BLANK';
			}
			try {
				this.readings.forEach((reading, index) => {
					if (reading.id === slotProps.data.id) {
						this.readings[index] = null;
						throw 'HIT';
					}
				});
			} catch (e) {
				if (e !== 'HIT') throw e;
			}
			this.$toast.add({
				severity: 'success',
				summary: 'Submit successful',
				detail: 'Manual reading successfully added.',
				life: 3000
			});
		}
	}
};
</script>

<style lang="scss"></style>
