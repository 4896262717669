import axios from 'axios';

export default class FormProvider {
	static getForms(site_name) {
		return axios({
			method: 'get',
			url: '/form/scheduling/get',
			params: {
				site_name: site_name
			}
		});
	}

	static putForms(site_name, forms) {
		return axios({
			method: 'put',
			url: '/form/scheduling/put',
			data: {
				site_name: site_name,
				forms: forms
			}
		});
	}

	static checkForms(forms, formLimit){
		return new Promise((resolve, reject) => {
			for (let formIndex = 0; formIndex < forms.length; formIndex++) {
				const form = forms[formIndex];
				if (form.length > formLimit){
					reject({ index: formIndex, message: 'Form has too many meters.' });
				}
			}
			resolve();
		});
	}
}