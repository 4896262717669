import axios from 'axios';
import router from '@/domains/Base/router';

export default ({
	namespaced: true,
	state: {
		token: null,
		user: null,
		exp: null,
		ttl: null
	},
	getters: {
		authenticated(state) {
			return (state.token && state.user && state.exp > Math.floor(Date.now() / 1000));
		},

		user(state) {
			return state.user;
		},

		exp(state) {
			return state.exp;
		},

		ttl(state) {
			return state.ttl;
		}
	},
	mutations: {
		SET_TOKEN(state, token) {
			state.token = token;
		},
		SET_USER(state, user) {
			state.user = user;
		},
		SET_TOKEN_EXP(state, exp) {
			state.exp = exp;
		},
		SET_TOKEN_TTL(state, ttl) {
			state.ttl = ttl;
		}
	},
	actions: {
		async login({ dispatch }, credentials) {
			let response = await axios.post('/auth/login', credentials);

			return dispatch('attempt', response.data.access_token);
		},
		async refresh({ commit, state, dispatch }) {
			if (!state.token) {
				return;
			}
			let time = Math.floor(Date.now() / 1000);
			if (time >= state.exp - Math.ceil(state.ttl / 2)) {
				try {
					let response = await axios.post('/auth/refresh');

					commit('SET_TOKEN', response.data.access_token);
					commit('SET_TOKEN_EXP', response.data.exp);
					commit('SET_TOKEN_TTL', response.data.ttl);
				} catch (e) {
					console.log();
					if (e.response.status === 401) {
						router.push('/login');
					}
				}
			}
		},
		async attempt({ commit, state }, token) {
			if (token) {
				commit('SET_TOKEN', token);
			}
			
			if (!state.token) {
				return;
			}

			try {
				let response = await axios.post('/auth/me');

				commit('SET_USER', response.data);
				commit('SET_TOKEN_EXP', response.data.exp);
				commit('SET_TOKEN_TTL', response.data.ttl);
			} catch (e) {
				commit('SET_TOKEN', null);
				commit('SET_USER', null);
				commit('SET_TOKEN_EXP', null);
			}
		},
		async logout({ commit }) {
			return axios.post('/auth/logout').then(() => {
				commit('SET_TOKEN', null);
				commit('SET_USER', null);
				commit('SET_TOKEN_EXP', null);
				commit('SET_TOKEN_TTL', null);
			}).catch((err) => {
				commit('SET_TOKEN', null);
				commit('SET_USER', null);
				commit('SET_TOKEN_EXP', null);
				commit('SET_TOKEN_TTL', null);
			});
		}
	},
});