<template>
  <div class="p-field p-grid">
    <label
      for="site_name"
      class="p-col-fixed"
      style="width:100px"
    >Site Name</label>
    <div class="p-col-fixed">
      <span class="p-input-icon-right">
        <AutoComplete
          id="Site Name"
          v-model="sites"
          :suggestions="filteredSites"
          field="site_name"
          @complete="searchSites($event.query)"
          @item-select="loadMeters($event)"
          @item-unselect="loadMeters()"
          @clear="hideMeters()"
        />
      </span>
    </div>
  </div>
  <div
    v-if="site_name != ''"
    class="p-formgroup-inline"
  >
    <div class="p-field">
      <FileUpload
        ref="csvSelector"
        name="demo[]"
        mode="basic"
        :auto="false"
        :customUpload="true"
        chooseLabel="Browse"
        @uploader="clearFile($event)"
        @select="onFileSelect"
      />
    </div>
    <div class="p-field">
      <Button
        type="button"
        label="VALIDATE"
        class="urb-button-info"
        :loading="validate_loading"
        :disabled="!file_selected"
        @click="validateCSV()"
      />
    </div>
    <div class="p-field">
      <Button
        type="button"
        label="VALIDATE & IMPORT"
        class="urb-button-info"
        :loading="import_loading"
        :disabled="!file_selected"
        @click="importCSV()"
      />
    </div>
  </div>
  <div>
    <Fieldset
      :toggleable="true"
      :collapsed="true"
      legend="Header"
    >
      <template #legend>
        Guide
      </template>
      <DataTable
        ref="sampleTable"
        :value="sampleRows"
        responsiveLayout="scroll"
        class="mb-8"
        showGridlines
        exportFilename="SAMPLE METER IMPORT GRID"
      >
        <template #footer>
          <div style="text-align: center">
            <Button
              icon="pi pi-external-link"
              label="Export Sample Sheet"
              @click="exportCSV($event)"
            />
          </div>
        </template>
        <Column
          field="meter_no"
          header="meter_no*"
        />
        <Column
          field="utility_type"
          header="utility_type*"
        />
        <Column
          field="meter_type"
          header="meter_type*"
        />
        <Column
          field="contact_no"
          header="contact_no"
        />
        <Column
          field="segment"
          header="segment"
        />
        <Column
          field="unit"
          header="unit*"
        />
        <Column
          field="unit_code"
          header="unit_code*"
        />
        <Column
          field="tenant"
          header="tenant"
        />
        <Column
          field="tenant_code"
          header="tenant_code"
        />
        <Column
          field="reading_new"
          header="reading_new*"
        />
        <Column
          field="reading_date"
          header="reading_date*"
        />
        <Column
          field="reading_old"
          header="reading_old*"
        />
        <Column
          field="reading_old_date"
          header="reading_old_date*"
        />
        <Column
          field="consumption"
          header="consumption*"
        />
      </DataTable>
      <div class="p-d-flex p-flex-column p-text-left">
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            meter_no<span class="text-red-400">*</span>
          </span>
          is a unique identifier for each meter.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            utility_type<span class="text-red-400">*</span>
          </span>
          is the utility type of the meter (electricity, cold_water, gas,
          hot_water).
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            meter_type<span class="text-red-400">*</span>
          </span>
          is the type of meter (bulk, domestic).
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            contact_no
          </span>
          is an optional field for the contact number assocaited with the meter.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            segment
          </span>
          is an optional field for specifying the segment, if not specified,
          will be set equal to unit.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            unit<span class="text-red-400">*</span>
          </span>
          is the unit number associated with the meter; one unit can have
          multiple meters.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            unit_code
          </span>
          is the unique code used to identify the unit, this varies between
          organizations.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            tenant
          </span>
          is an optional field to specify the name of the tenant.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            tenant_code
          </span>
          is a code that can be used to identify different tenants.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            reading_new<span class="text-red-400">*</span>
          </span>
          is the latest reading available.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            reading_date<span class="text-red-400">*</span>
          </span>
          is the date when the latest reading was captured.
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            reading_old<span class="text-red-400">*</span>
          </span>
          is the previous reading (usually a month difference from the new
          reading).
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            reading_old_date<span class="text-red-400">*</span>
          </span>
          is the previous reading date (usually a month difference from the new
          reading).
        </div>
        <div class="p-mb-2">
          <span style="font-weight: bold;">
            consumption<span class="text-red-400">*</span>
          </span>
          is the calculated consumption (can not be negative).
        </div>
        <div class="p-mb-2 ml-4">
          <span style="font-weight: bold; ">
            <span class="text-red-400">*</span>
          </span>
          REQUIRED FIELDS
        </div>
      </div>
    </Fieldset>
  </div>
  <Dialog
    v-model:visible="displayErrorDialog"
    header="Faults detected!"
    :modal="true"
  >
    <div class="p-grid">
      <div class="p-col-1 font-bold">
        X
      </div>
      <div class="p-col-5 font-bold">
        Row Numbers
      </div>
      <div class="p-col-6 font-bold">
        Faults
      </div>
      <template
        v-for="(error, index) in csvErrors"
        :key="index"
      >
        <div class="p-col-1 font-bold">
          <input
            :id="'check' + index"
            type="checkbox"
            name="self-check"
          >
        </div>
        <div class="p-col-5">
          <span v-if="error.row_number">
            {{ error.row_number }}
          </span>
          <span
            v-for="(row_number, row_index) in error.row_numbers"
            v-else-if="error.row_numbers"
            :key="row_index"
          >
            {{ row_number }}
            <span v-if="row_index != error.row_numbers.length - 1">
              ,
            </span>
          </span>
        </div>
        <div class="p-col-6">
          <span
            v-for="(fault, fault_index) in error.faults"
            :key="fault_index"
          >
            {{ fault }} &nbsp;
          </span>
        </div>
      </template>
    </div>
  </Dialog>
</template>

<script>
import SiteInfoService from '../services/SiteInfoProvider';
import SiteService from '../services/SiteProvider';

import AutoComplete from 'primevue/autocomplete';
import FileUpload from 'primevue/fileupload';
import Fieldset from 'primevue/fieldset';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';

export default {
	components: {
		AutoComplete,
		FileUpload,
		Fieldset,
		DataTable,
		Column,
		Dialog
	},
	data() {
		return {
			sites: [],
			displayErrorDialog: false,
			csvErrors: [],
			filteredSites: [],
			validate_loading: false,
			import_loading: false,
			site_name: '',
			file_selected: false,
			sampleRows: [
				{
					meter_no: 'ABCD-1234',
					utility_type: 'electricity',
					meter_type: 'domestic',
					contact_no: '079 123 4567',
					segment: 'K-15842',
					unit: 'K-15842',
					unit_code: '12',
					tenant: 'Jeff',
					tenant_code: '1',
					reading_new: 1250,
					reading_date: '12-08-2021',
					reading_old: 1170,
					reading_old_date: '11-07-2021',
					consumption: 80
				},
				{
					meter_no: 'WXYZ-4321',
					utility_type: 'cold_water',
					meter_type: 'domestic',
					contact_no: '079 765 4321',
					segment: 'P-89516',
					unit: 'P-89516',
					unit_code: '55',
					tenant: 'Johanna',
					tenant_code: '33',
					reading_new: 3580,
					reading_date: '10-08-2021',
					reading_old: 3572,
					reading_old_date: '10-07-2021',
					consumption: 8
				}
			],
			csvFile: null
		};
	},
	created() {
		this.siteInfoService = new SiteInfoService();
		this.siteService = new SiteService();
	},
	methods: {
		validateCSV() {
			this.import_loading = true;
			this.siteService
				.validateMetersFromCSV(this.site_name, this.csvFile)
				.then(res => {
					this.$toast.add({
						severity: 'success',
						summary: res.data.message
					});
					this.import_loading = false;
				})
				.catch(err => {
					this.csvErrors = err.response.data.rejected_lines;
					this.displayErrorDialog = true;
					this.import_loading = false;
				});
		},
		importCSV() {
			this.import_loading = true;
			this.siteService
				.importMetersFromCSV(this.site_name, this.csvFile)
				.then(res => {
					this.$toast.add({
						severity: 'success',
						summary: res.data.message,
						detail: res.data.info.count + ' meters inserted.'
					});
					this.import_loading = false;
					this.site_name = '';
					this.sites = [];
				})
				.catch(err => {
					this.csvErrors = err.response.data.rejected_lines;
					this.displayErrorDialog = true;
					this.import_loading = false;
				});
		},
		clearFile() {
			// console.log(event);
			this.$refs.csvSelector.clear();
			this.file_selected = false;
		},
		onFileSelect(event) {
			this.csvFile = event.files[0];
			this.file_selected = true;
		},
		searchSites(query) {
			this.siteInfoService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		exportCSV() {
			this.$refs.sampleTable.exportCSV();
		},
		loadMeters(event) {
			this.showMeters = true;
			this.site_name = event.value.site_name;

			this.siteInfoService.getSiteMeters(this.site_name).then(res => {
				res.data.meters.forEach(element => {
					switch (element.utility_type) {
					case 'electricity':
						element.utility_type = {
							name: 'Electricity',
							value: 'electricity'
						};
						break;
					case 'cold_water':
						element.utility_type = {
							name: 'Cold Water',
							value: 'cold_water'
						};
						break;
					case 'hot_water':
						element.utility_type = { name: 'Hot Water', value: 'hot_water' };
						break;
					case 'gas':
						element.utility_type = { name: 'Gas', value: 'gas' };
						break;

					default:
						break;
					}
				});
				this.meters = res.data.meters;
				this.meterCount = res.data.count;
			});
		},
		hideMeters() {
			this.showMeters = false;
			this.meters = [];
			this.site_name = '';
		}
	}
};
</script>

<style lang="scss" scoped></style>
