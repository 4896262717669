<template>
  User:
  <AutoComplete
    v-model="users"
    :suggestions="filteredUsers"
    field="name"
    @complete="searchUsers($event)"
    @item-select="loadSiteList()"
    @item-unselect="hideSiteList()"
    @clear="hideSiteList()"
  />

  <template v-if="showSites">
    Site:
    <InputText
      v-model="searchTerm"
      type="text"
      placeholder="Search for sites."
      class="text-lg"
    />
    <div class="p-d-flex p-flex-column p-flex-md-row p-jc-center p-flex-wrap">
      <template
        v-for="database in databases"
        :key="database.id"
      >
        <div class="p-mb-2 p-mr-2 p-4 m-4 border border-indigo-600">
          <span class="m-2">
            {{ database.client_name }} <br>
            ({{ database.dbname }})
          </span>
          <br>
          <Button
            icon="pi pi-check"
            class="p-button-success ml-2 p-mr-2"
            label="Grant Access"
            @click="grantAllAccess(database.id)"
          />
          <Button
            icon="pi pi-times"
            class="p-button-danger ml-2 p-mr-2"
            label="Revoke Access"
            @click="revokeAllAccess(database.id)"
          />
        </div>
      </template>
    </div>

    <Toolbar
      v-for="(site, index) in sites"
      v-show="searchList(site.site_name)"
      :key="site.site_name"
      class="m-1 hover:bg-urbnavy"
    >
      <template #start>
        <span class="text-xl">{{ site.site_name }}</span>
      </template>
      <template #end>
        <Button
          v-if="site.access == 0"
          icon="pi pi-check"
          class="p-button-success ml-2 p-mr-2"
          label="Grant Access"
          @click="grantAccess(site.id, index)"
        />
        <Button
          v-else
          icon="pi pi-times"
          class="p-button-danger ml-2 p-mr-2"
          label="Revoke Access"
          @click="revokeAccess(site.id, index)"
        />
      </template>
    </Toolbar>
  </template>
</template>

<script>
import UserInfoService from '../services/UserInfoProvider';

import Toolbar from 'primevue/toolbar';
import AutoComplete from 'primevue/autocomplete';

export default {
	name: 'UserComponent',
	components: {
		AutoComplete,
		Toolbar
	},
	data() {
		return {
			users: [],
			filteredUsers: [],
			showSites: false,
			sites: [],
			searchTerm: '',
			databases: []
		};
	},
	userService: null,
	created() {
		this.userService = new UserInfoService();
	},
	mounted() {},
	methods: {
		searchUsers(event) {
			this.userService.searchUsers(event.query).then(res => {
				this.filteredUsers = res.data;
			});
		},
		arraySort(arr) {
			function compare(key) {
				return function(i, j) {
					if (i[key] > j[key]) {
						return 1;
					} else if (i[key] < j[key]) {
						return -1;
					}
					return 0;
				};
			}
			return arr.sort(compare('site_name'));
		},
		loadSiteList() {
			this.showSites = true;
			this.userService.getDatabases().then(res => {
				this.databases = res.data;
				// console.log(res.data[0].dbname);
			});
			this.userService.getUserSites(this.users.id).then(res => {
				this.sites = this.arraySort(res.data.list);
			});
		},
		grantAccess(site_id, index) {
			this.userService
				.grantSiteAccess(this.users.id, site_id)
				.then(res => {
					this.$toast.add({
						severity: 'info',
						summary: 'Permission granted!',
						detail: res.data.message,
						life: 1000
					});
					this.sites[index].access = 1;
				})
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Permission update failed!',
						detail: err.response.data,
						life: 10000
					});
				});
		},
		revokeAccess(site_id, index) {
			this.userService
				.revokeSiteAccess(this.users.id, site_id)
				.then(res => {
					this.$toast.add({
						severity: 'info',
						summary: 'Permission revoked!',
						detail: res.data.message,
						life: 1000
					});
					this.sites[index].access = 0;
				})
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Permission update failed!',
						detail: err.response.data,
						life: 10000
					});
				});
		},
		grantAllAccess(db_id) {
			this.userService
				.grantDBSiteAccess(this.users.id, db_id)
				.then(res => {
					this.$toast.add({
						severity: 'info',
						summary: 'Permission granted!',
						detail: res.data.message,
						life: 1000
					});
				})
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Permission update failed!',
						detail: err.response.data,
						life: 10000
					});
				});
			this.sites.forEach(site => {
				if (site.db_id === db_id) {
					site.access = 1;
				}
			});
		},
		revokeAllAccess(db_id) {
			this.userService
				.revokeDBSiteAccess(this.users.id, db_id)
				.then(res => {
					this.$toast.add({
						severity: 'info',
						summary: 'Permission revoked!',
						detail: res.data.message,
						life: 1000
					});
				})
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Permission update failed!',
						detail: err.response.data,
						life: 10000
					});
				});

			this.sites.forEach(site => {
				if (site.db_id === db_id) {
					site.access = 0;
				}
			});
		},
		hideSiteList() {
			this.showSites = false;
		},
		searchList: function(s) {
			if (s !== '') {
				return s.toUpperCase().includes(this.searchTerm.toUpperCase());
			}
		}
	}
};
</script>
