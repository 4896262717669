<template>
  <div class="relative min-h-screen">
    <div
      id="nav"
      class="m:container mx-auto"
    >
      <navbar />
    </div>
    <div class="pb-10">
      <router-view class="pb-10" />
    </div>
    <!-- <footerc /> -->
    <Toast
      position="bottom-left"
      :baseZIndex="999"
    />
  </div>
</template>

<script>
import Nav from '@/domains/Base/components/Nav';
// import Foot from '@/domains/Base/components/Footer.vue';

export default {
	components: {
		navbar: Nav
		// 'footerc': Foot
	}
};
</script>

<style lang="scss">
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}
</style>
