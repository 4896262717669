<template>
  <template v-if="loading">
    <i
      class="pi pi-spin pi-spinner"
      style="font-size: 2rem"
    />
  </template>
  <template v-else>
    <template v-if="cycles.length > 0">
      <div class="p-field p-grid justify-center">
        <label
          for="show_all_switch"
          class="p-col-fixed cursor-pointer"
          style="width:100px"
        >Show all</label>
        <div class="p-col-fixed">
          <span class="p-input-icon-right">
            <InputSwitch
              id="show_all_switch"
              v-model="showAllCycles"
            />
          </span>
        </div>
      </div>
      <template
        v-for="(cycle, index) in cycles"
        :key="cycle.id"
      >
        <template 
          v-if="index === 0 || showAllCycles"
        >
          <div class="flex justify-center items-center flex-wrap">
            For readings between&nbsp;<span class="font-bold">{{ cycle.from_date }}</span>&nbsp;and&nbsp;<span class="font-bold">{{ cycle.to_date }}</span>
            <InputText
              v-model="cycle.cycle_code"
              class="w-32 ml-2 text-center"
              disabled
            /> 
            <Button
              label="Generate Billings"
              class="ml-1"
              :disabled="cycle.billing_status"
              :loading="cycle.loading"
              @click="generateBilling(cycle)"
            /> 
            <Button
              v-tooltip="'Rollback billing.'"
              icon="pi pi-backward"
              class="ml-1"
              :disabled="!cycle.billing_status"
              :loading="cycle.loading"
              @click="rollbackBilling(cycle)"
            /> 
            <template v-if="cycle.export_types.length == 1">
              <Button
                v-tooltip="'Download billing as CSV.'"
                icon="pi pi-download"
                label="CSV"
                class="ml-1"
                :disabled="!cycle.billing_status"
                :loading="cycle.loading"
                @click="downloadBillingCSV(cycle, cycle.export_types[0])"
              /> 
              <Button
                v-tooltip="'Download billing as XLSX.'"
                icon="pi pi-download"
                label="XLSX"
                class="ml-1"
                :disabled="!cycle.billing_status"
                :loading="cycle.loading"
                @click="downloadBillingXLSX(cycle, cycle.export_types[0])"
              />
              <Button
                icon="pi pi-eye"
                label="View"
                class="ml-1"
                @click="viewBilling(cycle, cycle.export_types[0])"
              />
            </template>
            <template v-else>
              <SplitButton
                v-tooltip="'Download billing as CSV.'"
                icon="pi pi-download"
                label="CSV"
                class="ml-1"
                :disabled="!cycle.billing_status"
                :loading="cycle.loading"
                :model="cycle.export_types_csv"
                @click="downloadBillingCSV(cycle, cycle.export_types[0])"
              /> 
              <SplitButton
                v-tooltip="'Download billing as XLSX.'"
                icon="pi pi-download"
                label="XLSX"
                class="ml-1"
                :disabled="!cycle.billing_status"
                :loading="cycle.loading"
                :model="cycle.export_types_xlsx"
                @click="downloadBillingXLSX(cycle, cycle.export_types[0])"
              />
              <SplitButton
                icon="pi pi-eye"
                label="View"
                class="ml-1"
                :disabled="!cycle.billing_status"
                :loading="cycle.loading"
                :model="cycle.export_types_view"
                @click="viewBilling(cycle, cycle.export_types[0])"
              />
            </template>
          </div>
        </template>
        <Dialog
          v-model:visible="cycle.showBilling"
          :maximizable="true"
        >
          <template #header>
            {{ site.site_name }} - {{ cycle.cycle_code }}
          </template>
          <template v-if="cycle.loading">
            <i
              class="pi pi-spin pi-spinner"
              style="font-size: 2rem"
            />
          </template>
          <template v-else>
            <DataTable
              :value="cycle.billing"
              responsiveLayout="scroll"
              :paginator="true"
              :rows="20"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              :rowsPerPageOptions="[10, 20, 50]"
              stripedRows
            >
              <template
                v-for="(col, colNo) in cycle.billingColumns"
                :key="colNo"
              >
                <Column
                  :field="col.field"
                  :header="col.header"
                />
              </template>
            </DataTable>
          </template>
        </Dialog>
      </template>
    </template>
    <template v-else>
      <div class="font-bold text-2xl">
        No cycles found for {{ site.site_name }}
      </div>
    </template>
  </template>
</template>

<script>
import InputSwitch from 'primevue/inputswitch';
import SplitButton from 'primevue/splitbutton';

import BillingService from '../../services/BillingProvider';
import SetupService from '../../services/SetupProvider';
import ShortDateService from '../../services/ShortDateProvider';
import Dialog from 'primevue/dialog';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
	components: {
		InputSwitch,
		SplitButton,
		Dialog,
		DataTable,
		Column
	},
	props: {
		site: {
			type: Object, 
			default: null
		}
	},
	data(){
		return {
			cycles:[],
			showAllCycles: false,
			loading: true,
			showBilling: false,
			billingViewLoading: true,
		};
	},
	created(){
		this.setupService = new SetupService();
		this.billingService = new BillingService();
	},
	mounted(){
		this.loadCycles(this.site);
	},
	methods: {
		loadCycles(site){
			this.loadingCycles = true;
			this.billingService.getCycles(site.id)
				.then((res) => {
					this.cycles = res.data.cycles.reverse();
					this.cycles.forEach((cycle) => {
						cycle.loading = false;
						cycle.export_types = cycle.export_types.split(',');
						cycle.export_types_csv = [];
						cycle.export_types_xlsx = [];
						cycle.export_types_view = [];
						cycle.export_types .forEach((export_type) => {
							cycle.export_types_csv.push({
								label: export_type,
								command: () => {
									this.downloadBillingCSV(cycle, export_type);
								}
							});
							cycle.export_types_xlsx.push({
								label: export_type,
								command: () => {
									this.downloadBillingXLSX(cycle, export_type);
								}
							});
							cycle.export_types_view.push({
								label: export_type,
								command: () => {
									this.viewBilling(cycle, export_type);
								}
							});
						});
					});
					this.showCycles = true;
					this.loading = false;
				})
				.catch((err) => {
					this.cycles = [];
					this.loadingCycles = false;
					this.showCycles = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Failed to retrieve cycles.',
						detail: err.message
					});
					this.loading = false;
				});
		},
		hideCycles(){
			this.showCycles = false;
			this.cycles = [];
		},
		generateBilling(cycle){
			cycle.loading = true;
			this.billingService.generate(this.site.site_name, cycle.id)
				.then((res) => {
					cycle.billing_status = true;
					this.$toast.add({
						severity: 'success',
						summary: res.data.message,
						life: 1000
					});
					cycle.loading = false;
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong!',
						detail: err.data.message,
						life: 1000
					});
					cycle.loading = false;
				});
		},
		rollbackBilling(cycle){
			cycle.loading = true;
			this.billingService.rollback(this.site.site_name, cycle.id)
				.then((res) => {
					cycle.billing_status = false;
					this.$toast.add({
						severity: 'success',
						summary: res.data.message,
						life: 1000
					});
					cycle.loading = false;
				})
				.catch((err) => {
					console.error(err);
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong!',
						life: 1000
					});
					cycle.loading = false;
				});
		},
		downloadBillingCSV(cycle, export_type){
			cycle.loading = true;
			const shortDateService = new ShortDateService;
			console.log(cycle.cycle_code.substring(0, 3));
			const date = shortDateService.shortDateToProperDate(cycle.cycle_code);
			this.billingService.exportBillingAsCSV(
				this.site.site_name, 
				date.getFullYear(), 
				cycle.cycle_code.substring(0, 3),
				export_type
			).then((res) => {
				cycle.loading = false;
			}).catch((err) => {
				cycle.loading = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Something went wrong!',
					life: 1000
				});
			});
		},
		downloadBillingXLSX(cycle, export_type){
			cycle.loading = true;
			const shortDateService = new ShortDateService;
			console.log(cycle.cycle_code.substring(0, 3));
			const date = shortDateService.shortDateToProperDate(cycle.cycle_code);
			this.billingService.exportBillingAsXLSX(
				this.site.site_name, 
				date.getFullYear(), 
				cycle.cycle_code.substring(0, 3),
				export_type
			).then((res) => {
				cycle.loading = false;
			}).catch((err) => {
				cycle.loading = false;
				this.$toast.add({
					severity: 'error',
					summary: 'Something went wrong!',
					life: 1000
				});
			});
		},
		viewBilling(cycle, export_type) {
			cycle.showBilling = true;
			cycle.loading = true;
			const shortDateService = new ShortDateService;
			const date = shortDateService.shortDateToProperDate(cycle.cycle_code);
			this.billingService
				.viewBilling(
					this.site.site_name,
					date.getFullYear(), 
					cycle.cycle_code.substring(0, 3),
					export_type
				)
				.then((res) => {
					cycle.loading = false;
					cycle.billing = res.data.billing;
					cycle.billingColumns = res.data.headers;
				}).catch((err) => {
					cycle.loading = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Something went wrong!',
						life: 1000
					});
				});
		},
	}
};
</script>

<style>

</style>