<template>
  <Menubar :model="menuitems">
    <template #start>
      <div>
        <img
          src="@/assets/logo.svg"
          alt=""
          class="w-10"
        >
      </div>
    </template>
    <template #end>
      <template v-if="authenticated">
        <div
          type="button"
          class="p-grid p-ai-center vertical-container"
          @click="toggleUserMenu"
        >
          <div class="p-col user-widget italic">
            {{ user.name }}
            <i
              class="pi pi-angle-down"
              style="fontSize: 1rem"
            />
          </div>
          <div
            class="p-col-fixed"
            style="width:100px"
          >
            <Avatar
              icon="pi pi-user"
              class="p-mr-2"
              size="large"
              style="background-color:#e5b52b; color: white"
            />
          </div>
        </div>
        <Menu
          ref="menu"
          :model="useritems"
          :popup="true"
        />
      </template>
      <template v-else>
        <router-link to="/login">
          Login
        </router-link>
      </template>
    </template>
  </Menubar>
</template>

<script>
import Menubar from 'primevue/menubar';
import Menu from 'primevue/menu';
import Avatar from 'primevue/avatar';
import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		Menubar,
		Menu,
		Avatar
	},
	data() {
		return {
			admin: false,
			menuitems: [
				// {
				// 	label: 'Home',
				// 	to: '/'
				// },
				{
					label: 'Site List',
					to: '/list',
					visible: () => this.isRole('OPERATOR') || this.isRole('OBSERVER')
				},
				// {
				// 	label: 'Dashboard',
				// 	to: '/dashboard',
				// 	visible: () => this.isRole('OPERATOR')
				// },
				{
					label: 'Site Management',
					to: '/site/management',
					visible: () => this.isRole('OPERATOR')
				},
				{
					label: 'Billing',
					to: '/billing',
					visible: () => this.isRole('OPERATOR')
				},
				{
					label: 'Admin Panel',
					to: '/admin/options',
					visible: () => this.isRole('ADMIN')
				}
			],
			useritems: [
				{
					label: 'Sign Out',
					icon: 'pi pi-fw pi-power-off',
					command: this.logout
				}
			]
		};
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated',
			user: 'auth/user'
		})
	},
	methods: {
		...mapActions({
			logoutAction: 'auth/logout'
		}),
		logout() {
			this.logoutAction().then(() => {
				this.$router.push('/login');
			});
		},
		toggleUserMenu(event) {
			this.$refs.menu.toggle(event);
		},

		isRole($role) {
			if (this.user && this.user.roles) {
				if (this.authenticated) {
					return this.user.roles.indexOf($role) > -1 ? true : false;
				}
			}
			return false;
		}
	}
};
</script>

<style lang="scss">
.user-widget {
	cursor: pointer;
}
</style>
