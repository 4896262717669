import axios from 'axios'; 

export default class CouncilProvider {
	submitCouncilBill(rows, site_name, invoice_date, invoice_no, account_no) {
		return axios({
			method: 'post',
			url: '/admin/council/billing/insert',
			data: {
				rows: rows,
				site_name: site_name,
				invoice_date: invoice_date,
				invoice_no: invoice_no,
				account_no: account_no
			}
		});
	}
} 