<template>
  <div class="card bg-white">
    <div class="card">
      <div class="text-3xl">
        Counters
      </div>
      <Counters />
    </div>
    <div class="card">
      <div class="text-3xl">
        Monthly Usages
      </div>
      <MonthlyUsage />
    </div>
    <div class="card">
      <div class="text-3xl">
        Total Meter Count
      </div>
      <MeterCount />
    </div>
  </div>
</template>

<script>
import MeterCount from '../components/Dashboard/MeterCountPerSite.vue';
import Counters from '../components/Dashboard/Counters.vue';
import MonthlyUsage from '../components/Dashboard/MonthlyUsage.vue';

export default {
	components: {
		MeterCount,
		Counters,
		MonthlyUsage
	}
};
</script>

<style></style>
