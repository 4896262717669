import { createStore } from 'vuex';
import auth from './auth';

import InspectorStore from '@/domains/Inspector/store/inspectorStore';
import OperatorStore from '@/domains/Operator/store/operatorStore';
import AdminStore from '@/domains/Admin/store/adminStore';

export default createStore({
	state: {
	},
	mutations: {
	},
	actions: {
	},
	modules: {
		// Base modules
		auth,

		// Domain modules
		InspectorStore,
		OperatorStore,
		AdminStore,
	}
});
