<template>
  <div>
    <DataTable
      ref="bt"
      :value="billing_lines"
      :exportFilename="siteName"
      rowGroupMode="rowspan"
      groupRowsBy="unit"
      sortMode="single"
      sortField="unit"
      :sortOrder="1"
    >
      <template #header>
        <div class="text-center text-4xl">
          {{ siteName }}
        </div>
        <div style="text-align: left">
          <Button
            icon="pi pi-external-link"
            label="Export"
            @click="exportCSV($event)"
          />
        </div>
      </template>
      <Column
        field="unit"
        header="Unit"
      />
      <Column
        field="from_date"
        header="From Date"
      />
      <Column
        field="to_date"
        header="To Date"
      />
      <Column
        field="no_days"
        header="Days"
      />
      <Column
        field="consumption"
        header="Consumption"
      />
      <Column
        field="description"
        header="Description"
      />
      <Column
        field="amt"
        header="Amount"
      >
        <template #body="{data}">
          R {{ data.amt }}
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import BillingService from '../../services/BillingProvider';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
	components: {
		DataTable,
		Column
	},
	props: {
		siteName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			billing_lines: []
		};
	},
	created() {
		this.billingService = new BillingService();
	},
	mounted() {
		this.billingService.info(this.siteName).then(res => {
			this.billing_lines = res.data;
		});
	},
	methods: {
		exportCSV() {
			this.$refs.bt.exportCSV();
		}
	}
};
</script>

<style></style>
