import axios from 'axios'; 

export default class CycleProvider {
	populateFromAll() {
		return axios({
			method: 'post',
			url: '/admin/sites/cycles/populate',
		});
	}

	populateFromDB(dbid){
		var form = {
			'database_id': dbid
		};
		return axios({
			method: 'post',
			url: '/admin/sites/cycles/populate/db',
			data: form
		});
	}

	populateFromSiteName(site_name){
		var form = {
			'site_name': site_name
		};
		return axios({
			method: 'post',
			url: '/admin/sites/cycles/populate/site',
			data: form
		});
	}
} 