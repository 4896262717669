import axios from 'axios';

export default class DeviceProvider {
	static getAllDevices() {
		return axios({
			method: 'get',
			url: '/admin/device/get/all'
		});
	}
    
	static getDeviceSites(device_id){
		return axios({
			method: 'get',
			url: '/admin/device/sites/get',
			params: {
				'device_id': device_id
			}
		});
	}

	static attachSite(device_id, site_id){
		return axios({
			method: 'post',
			url: '/admin/device/sites/attach',
			params: {
				'device_id': device_id,
				'site_id': site_id
			}
		});
	}

	static detachSite(device_id, site_id){
		return axios({
			method: 'delete',
			url: '/admin/device/sites/remove',
			params: {
				'device_id': device_id,
				'site_id': site_id
			}
		});
	}

	static generateLookups(device_id){
		return axios({
			method: 'post',
			url: '/form/lookup/generate',
			data: {
				'device_id': device_id
			}
		});
	}

	static modifySetting(device_id, state){
		return axios({
			method: 'post',
			url: '/admin/device/setting',
			data: {
				'device_id': device_id,
				'state': state
			}
		});
	}

	static saveChanges(device){
		return axios({
			method: 'post',
			url: '/admin/device/save',
			data: {
				'device': device
			}
		});
	}
}