import axios from 'axios';

export default class FinalReadingProvider {
	searchSites(site_hint){
		const form = {
			site_name: site_hint,
		};
		return axios({
			method: 'get',
			url: '/site/search',
			params: form
		});
	}
    
	searchUnits(site_name, unit_hint){
		const form = {
			site_name: site_name,
			unit_no: unit_hint
		};
		return axios({
			method: 'get',
			url: '/site/unit/search',
			params: form
		});
	}

	getMeters(site_name, unit){
		const form = {
			site_name: site_name,
			unit_no: unit
		};
		return axios({
			method: 'get',
			url: '/site/unit/meters/get',
			params: form
		});
	}

	singleFinalReading(site_name, unit){
		const form = {
			site_name: site_name,
			unit_no: unit.units.unit,
			previous_reading_date: unit.units.reading_date,
			meters: unit.meters
		};

		return axios({
			method: 'post',
			url: '/site/unit/readings/final',
			data: form
		});
	}

	getFinalReadings(){
		return axios({
			method: 'get',
			url: '/site/readings/final/get'
		});
	}

	generateUnitFinalReading(site_name, unit_no){
		return axios({
			method: 'post',
			url: '/billing/generateFR',
			data: {
				site_name: site_name,
				unit: unit_no
			}
		});
	}
}