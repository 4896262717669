import axios from 'axios';

export default class PasswordProvider {
	static checkToken(token, email){
		return axios({
			method: 'post',
			url: '/password/reset/verify',
			data: {
				token: token,
				email: email
			}
		});
	}

	static requestPasswordReset(email){
		return axios({
			method: 'post',
			url: '/password/reset/request',
			data: {
				email: email
			}
		});
	}

	static resetPassword(email, token, password, password_confirmation){
		return axios({
			method: 'post',
			url: '/password/reset/finalize',
			data: {
				email: email,
				token: token,
				password: password,
				password_confirmation: password_confirmation
			}
		});
	}
}