import axios from 'axios';

export default class SettingsProvider {
	getSiteSettings(site_id) {
		return axios({
			method: 'get',
			url: '/admin/sites/settings/get',
			params: {
				'site_id': site_id
			}
		});
	}
    
	getSiteSetting(site_id, setting_type) {
		return axios({
			method: 'get',
			url: '/admin/sites/settings/get/' + setting_type,
			params: {
				'site_id': site_id
			}
		});
	}
    
	setSiteSetting(site_id, setting_type, value) {
		return axios({
			method: 'post',
			url: '/admin/sites/settings/set/' + setting_type,
			data: {
				'site_id': site_id,
				'setting_value': value
			}
		});
	}

	setSiteSettings(site_id, settings_batch) {
		return axios({
			method: 'post',
			url: '/admin/sites/settings/set',
			data: {
				'site_id': site_id,
				'settings_batch': settings_batch
			}
		});
	}

	setClientDefaultSettings(client_id, settings_batch) {
		return axios({
			method: 'post',
			url: '/admin/client/settings/set',
			data: {
				'database_id': client_id,
				'settings_batch': settings_batch
			}
		});
	}

	getClientDefaultSettings(client_id) {
		return axios({
			method: 'get',
			url: '/admin/client/settings/get',
			params: {
				'database_id': client_id
			}
		});
	}
    

}