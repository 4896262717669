import axios from 'axios'; 

export default class SetupProvider {
	newSite(form){
		return axios({
			method: 'post',
			url: '/site/new',
			data: form
		});
	}

	
	search(site_name){
		return axios({
			method: 'get',
			url: '/site/search',
			params: {
				'site_name': site_name
			}
		});
	}
    
	getCouncils(database_id) {
		return axios({
			method: 'get',
			url: '/site/info/councils',
			params: {
				database_id: database_id
			}
		});
	}

	siteNameExists(site_name) {
		return axios({
			method: 'get',
			url: '/site/exists',
			params: {
				'site_name': site_name
			}
		});
	}
    
	validateMetersFromCSV(site_name, file) {
		var form = new FormData();
		form.append('data_file', file);
		form.append('site_name', site_name);
		return axios({
			method: 'post',
			url: '/site/meters/csv/validate',
			data: form
		});
	}

	importMetersFromCSV(site_name, file) {
		var form = new FormData();
		form.append('data_file', file);
		form.append('site_name', site_name);
		return axios({
			method: 'post',
			url: '/site/meters/csv/import',
			data: form
		});
	}

	getCycles(site_id){
		return axios({
			method: 'get',
			url: '/site/cycles/get',
			params: {
				site_id: site_id
			}
		});
	}

	modifyCycle(cycle_id, site_id, from_date, to_date, cycle_code){
		return axios({
			method: 'post',
			url: '/site/cycles/modify',
			data: {
				cycle_id: cycle_id,
				site_id: site_id,
				from_date: from_date,
				to_date: to_date,
				cycle_code: cycle_code,
			}
		});
	}

	newCycle(site_id, from_date, to_date, cycle_code){
		return axios({
			method: 'post',
			url: '/site/cycles/new',
			data: {
				site_id: site_id,
				from_date: from_date,
				to_date: to_date,
				cycle_code: cycle_code,
			}
		});
	}

	newCycleForAllSites(from_date, to_date, cycle_code){
		return axios({
			method: 'post',
			url: '/cycles/all/new',
			data: {
				from_date: from_date,
				to_date: to_date,
				cycle_code: cycle_code,
			}
		});
	}

	deleteCycle(site_id, cycle_id){
		return axios({
			method: 'delete',
			url: '/site/cycles/delete',
			params: {
				site_id: site_id,
				cycle_id: cycle_id
			}
		});
	}
} 