<template>
  <div class="text-5xl text-urbtext m-5">
    Billing for {{ siteName }}
  </div>
  <div>
    <DataTable
      ref="electricity_bt"
      :value="electricity_billing_lines"
      :exportFilename="
        'Electricity billing for ' + siteName + ' ' + electricity_billing_date
      "
      rowGroupMode="rowspan"
      groupRowsBy="unit"
      sortMode="single"
      sortField="unit"
      :sortOrder="1"
      :loading="electricity_loading"
    >
      <template #header>
        <div class="text-center">
          <span class=" text-4xl">
            Electricity Billing
          </span>

          <div class="float-left">
            <Button
              v-if="electricity_available"
              icon="pi pi-external-link"
              label="Export"
              @click="exportElectrictyCSV($event)"
            />
          </div>
        </div>
      </template>
      <template #empty>
        Billing lines unavailable.
      </template>
      <Column
        field="unit_no"
        header="Unit No."
      />
      <Column
        field="Section"
        header="Section"
      />
      <Column
        field="Opening_Reading"
        header="Opening Reading"
      />
      <Column
        field="Closing_Reading"
        header="Closing Reading"
      />
      <Column
        field="Consumption"
        header="Consumption"
      />
      <Column
        field="Amount"
        header="Amount"
      />
    </DataTable>
  </div>
  <div class="mt-10">
    <DataTable
      ref="cold_water_bt"
      :value="cold_water_billing_lines"
      :exportFilename="
        'Cold water billing for ' + siteName + ' ' + cold_water_billing_date
      "
      rowGroupMode="rowspan"
      groupRowsBy="unit"
      sortMode="single"
      sortField="unit"
      :sortOrder="1"
      :loading="cold_water_loading"
    >
      <template #header>
        <div class="text-center">
          <span class=" text-4xl">
            Cold Water Billing
          </span>

          <div class="float-left">
            <Button
              v-if="cold_water_available"
              icon="pi pi-external-link"
              label="Export"
              @click="exportColdWaterCSV($event)"
            />
          </div>
        </div>
      </template>
      <template #empty>
        Billing lines unavailable.
      </template>
      <Column
        field="unit_no"
        header="Unit No."
      />
      <Column
        field="Section"
        header="Section"
      />
      <Column
        field="Opening_Reading"
        header="Opening Reading"
      />
      <Column
        field="Closing_Reading"
        header="Closing Reading"
      />
      <Column
        field="Consumption"
        header="Consumption"
      />
      <Column
        field="Amount"
        header="Amount"
      />
      <Column
        field="Sew_amt"
        header="Sew_amt"
      />
    </DataTable>
  </div>
</template>

<script>
import BillingService from '../../services/LegacyBillingProvider';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';

export default {
	components: {
		DataTable,
		Column
	},
	props: {
		siteName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			electricity_billing_lines: [],
			electricity_loading: true,
			electricity_billing_date: '',
			electricity_available: false,
			cold_water_billing_lines: [],
			cold_water_loading: true,
			cold_water_billing_date: '',
			cold_water_available: false
		};
	},
	created() {
		this.billingService = new BillingService();
	},
	mounted() {
		this.billingService.electricity_info(this.siteName).then(res => {
			if (res.data.length > 0) {
				this.electricity_billing_lines = res.data;
				this.electricity_billing_date = res.data[0].billing_date;
				this.electricity_loading = false;
				this.electricity_available = true;
			} else {
				this.electricity_billing_lines = res.data;
				this.electricity_loading = false;
			}
		});
		this.billingService.cold_water_info(this.siteName).then(res => {
			if (res.data.length > 0) {
				this.cold_water_billing_lines = res.data;
				this.cold_water_billing_date = res.data[0].billing_date;
				this.cold_water_loading = false;
				this.cold_water_available = true;
			} else {
				this.cold_water_billing_lines = res.data;
				this.cold_water_loading = false;
			}
		});
	},
	methods: {
		exportElectrictyCSV() {
			this.$refs.electricity_bt.exportCSV();
		},
		exportColdWaterCSV() {
			this.$refs.cold_water_bt.exportCSV();
		}
	}
};
</script>

<style></style>
