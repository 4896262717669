<template>
  <div class="flex flex-col items-start">
    <div class="p-field p-grid">
      <label
        for="site_name"
        class="p-col-fixed"
        style="width:100px"
      >Site Name</label>
      <div class="p-col-fixed">
        <span class="p-input-icon-right">
          <AutoComplete
            id="Site Name"
            v-model="sites"
            :suggestions="filteredSites"
            :forceSelection="true"
            field="site_name"
            @complete="searchSites($event.query)"
            @item-select="startFinalReading()"
            @item-unselect="hideFinalReadings()"
            @clear="hideFinalReadings()"
          />
        </span>
      </div>
    </div>
    <template v-if="showFinalReadings">
      <template
        v-for="(unit, unitIndex) in units"
        :key="unitIndex"
      >
        <div class="flex flex-col mt-10">
          <div>
            <label :for="'unit_no_' + unitIndex">Unit Number</label>
            <div>
              <span class="p-input-icon-right">
                <AutoComplete
                  :id="'unit_no_' + unitIndex"
                  v-model="unit.units"
                  type="text"
                  placeholder="Unit Number"
                  field="unit"
                  :suggestions="unit.filteredUnits"
                  :forceSelection="true"
                  :disabled="unit.unitSelected"
                  @complete="searchUnits($event.query, unitIndex)"
                  @item-select="getUnitMeters(unit)"
                  @item-unselect="removeMeters(unit)"
                  @clear="removeMeters(unit)"
                />
                <template v-if="unit.metersLoading">
                  <i
                    
                    class="pi pi-spin pi-spinner"
                  />
                </template>
                <template v-else-if="unit.unitSelected">
                  <i
                    class="pi pi-times text-red-500 cursor-pointer"
                    @click="removeFinalReading(unitIndex)"
                  />
                </template>
              </span>
              <template v-if="unit.unitSelected">
                <Button
                  :loading="unit.loading"
                  class="ml-2"
                  @click="submitSingleFinalReading(unit, unitIndex)"
                >
                  Submit
                </Button>
              </template>
            </div>
          </div>
        </div>
        <template
          v-for="(meter, meterIndex) in unit.meters"
          :key="meterIndex"
        >
          <div class="ml-8 flex flex-row">
            <div class="mr-2">
              <label :for="'meter_no_' + unitIndex + '_' + meterIndex">Meter Number</label>
              <div>
                <InputText
                  id="meter_no" 
                  v-model="meter.meter_no"
                  type="text"
                  placeholder="Meter Number"
                  disabled 
                />
              </div>
            </div>
            <div class="mr-2">
              <label :for="'old_reading_' + unitIndex + '_' + meterIndex">Old Reading</label>
              <div>
                <InputNumber
                  :id="'old_reading_' + unitIndex + '_' + meterIndex" 
                  v-model="meter.reading_new"
                  placeholder="Final Reading"
                  disabled
                  :min="0"
                />
              </div>
            </div>
            <div class="mr-2">
              <label :for="'reading_date' + unitIndex + '_' + meterIndex">Old Reading Date</label>
              <div>
                <InputText
                  :id="'reading_date' + unitIndex + '_' + meterIndex"
                  v-model="meter.reading_date"
                  type="date"
                  placeholder="Prev. Reading Date"
                  :disabled="true"
                />
              </div>
            </div>
            <div class="mr-2">
              <label :for="'final_reading_' + unitIndex + '_' + meterIndex">Final Reading</label>
              <div>
                <InputNumber
                  :id="'final_reading_' + unitIndex + '_' + meterIndex" 
                  v-model="meter.final_reading"
                  placeholder="Final Reading"
                  :min="0"
                />
              </div>
            </div>
            <div class="mr-2">
              <label :for="'final_reading_date' + unitIndex + '_' + meterIndex">Final Reading Date</label>
              <div>
                <InputText
                  :id="'final_reading_date' + unitIndex + '_' + meterIndex"
                  v-model="meter.final_reading_date"
                  type="date"
                  placeholder="Final Reading Date"
                />
              </div>
            </div>
          </div>
        </template>
      </template>
      <div class="p-5">
        <Button
          @click="newFinalReading"
        >
          ADD FINAL READING
        </Button>
        <Button
          class="ml-2"
          :disabled="units.length === 0"
          @click="submitAllFinalReadings"
        >
          SUBMIT ALL
        </Button>
      </div>
    </template>
  </div>
</template>
<script>
import InputNumber from 'primevue/inputnumber';

import FinalReadingService from '../../services/FinalReadingProvider';
import AutoComplete from 'primevue/autocomplete';

export default {
	components: {
		InputNumber,
		AutoComplete
	},
	data(){
		return {
			units: [],
			sites: [],
			filteredSites: [],
			showFinalReadings: false
		};
	},
	created(){
		this.finalReadingService = new FinalReadingService();
	},
	methods: {
		searchSites(query) {
			this.finalReadingService.searchSites(query)
				.then(res => {
					this.filteredSites = res.data;
				});
		},
		searchUnits(query, unitIndex) {
			this.finalReadingService.searchUnits(this.sites.site_name, query)
				.then(res => {
					this.units[unitIndex].filteredUnits = res.data.units;
				});
		},
		newFinalReading(){
			this.units.push({
				units: [],
				filteredUnits: [],
				completed: false,
				unitSelected: false,
				metersLoading: false,
				loading: false,
				meters: []
			});
		},
		removeFinalReading(index){
			this.units.splice(index,1);
		},
		hideFinalReadings(){
			this.units = [];
			this.showFinalReadings = false;
		},
		startFinalReading(){
			this.showFinalReadings = true;
		},
		removeMeters(unit){
			unit.meters = [];
		},
		getUnitMeters(unit){
			unit.metersLoading = true;
			const date = new Date();
			this.finalReadingService.getMeters(this.sites.site_name, unit.units.unit)
				.then(res => {
					unit.unitSelected = true;
					unit.metersLoading = false;
					unit.meters = res.data.meters;
					unit.meters.forEach(element => {
						element.final_reading_date = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2,'0') + '-' + date.getDate().toString().padStart(2,'0');
						element.reading_new = parseInt(element.reading_new);
						element.final_reading = element.reading_new;
					});
				});
		},
		async submitSingleFinalReading(unit, unitIndex, batch){
			unit.loading = true;
			await this.finalReadingService.singleFinalReading(this.sites.site_name, unit)
				.then(res => {
					unit.completed = true;
					unit.loading = false;
					this.$toast.add({
						severity: 'success',
						summary: 'Final reading completed.',
						detail: res.data.message
					});
					if (!batch){
						this.units.splice(unitIndex, 1);
					}
				})
				.catch(err => {
					unit.loading = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Final reading failed.',
						detail: err.response.data.message
					});
				});
		},
		cleanup(){
			for (let index = this.units.length-1; index >= 0; index--) {
				console.log(this.units[index]);
				if (this.units[index].completed){
					console.log(index);
					this.units.splice(index, 1);
				}
				console.log(this.units[index]);
			}
		},
		async submitAllFinalReadings(){
			await Promise.all(this.units.map(async (unit, unitIndex) => {
				await this.submitSingleFinalReading(unit, unitIndex, true);
			})).then(() => {
				this.cleanup();
			});
		}
	}
};
</script>

<style>

</style>