<template>
  <Chart
    ref="siteMeters"
    type="bar"
    :data="siteMeterData"
    :options="siteMeterOptions"
  />
</template>

<script>
import DashInfoService from '../../services/DashProvider';

export default {
	data() {
		return {
			siteMeterData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: '#42A5F5',
						label: 'Sites and their meters'
					}
				]
			},
			siteMeterOptions: {
				responsive: true
			}
		};
	},
	created() {
		this.dashInfoService = new DashInfoService();
	},
	mounted() {
		this.dashInfoService.siteMeters().then(res => {
			// console.log(this.siteMeterData.datasets[0]);
			this.siteMeterData.datasets[0].data = res.data.meters;
			this.siteMeterData.labels = res.data.sites;
			this.$refs.siteMeters.refresh();
		});
	}
};
</script>

<style></style>
