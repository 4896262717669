import AdminOptions from '../../Admin/views/AdminOptions.vue';

export default [
	{
		path: '/admin/options',
		name: 'adminOptions',
		component: AdminOptions, 
		meta: { requiresAuth: true }
	}
];
