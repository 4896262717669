<template>
  <Card>
    <template #header>
      <div class="text-4xl pt-8">
        Site Billing
      </div>
    </template>
    <template #content>
      <div class="p-field p-grid justify-center">
        <label
          for="site_name"
          class="p-col-fixed"
          style="width:100px"
        >Site Name</label>
        <div class="p-col-fixed">
          <span class="p-input-icon-right">
            <AutoComplete
              id="Site Name"
              v-model="site"
              :suggestions="filteredSites"
              field="site_name"
              @complete="searchSites($event.query)"
              @item-select="loadCycles($event)"
              @item-unselect="loadCycles()"
              @clear="hideCycles()"
            />
          </span>
        </div>
      </div>
      <template v-if="showCycles">
        <template v-if="site.reading_type == 'MOBIXT'">
          <ManualList :site="site" />
        </template>
        <template v-else-if="site.reading_type == 'UTIL'">
          <UtilList :site="site" />
        </template>
        <template v-else>
          Reading type not yet implemented
        </template>
      </template>
    </template>
  </Card>
</template>

<script>
import Card from 'primevue/card';
import AutoComplete from 'primevue/autocomplete';

import ManualList from './ManualList.vue';
import UtilList from './UtilityList.vue';

import BillingService from '../../services/BillingProvider';
import SetupService from '../../services/SetupProvider';

export default {
	components: {
		Card,
		AutoComplete,
		ManualList,
		UtilList
	},
	data(){
		return {
			site: null,
			filteredSites: [],
			cycles:[],
			showAllCycles: false,
			showCycles: false
		};
	},
	created(){
		this.setupService = new SetupService();
		this.billingService = new BillingService();
	},
	methods: {
		searchSites(query) {
			this.setupService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		loadCycles(event){
			this.showCycles = true;
		},
		hideCycles(event){
			this.showCycles = false;
		}
	}
};
</script>

<style>

</style>