import List from '../views/List';
import ReadingEdit from '../views/ReadingEdit';
import ReadingView from '../views/ReadingView';
import ReadingViewOnly from '../views/ReadingViewOnly';
import Unread from '../views/Unread';
import Dash from '../views/Dash';
import BillingView from '../views/BillingView';
import LegacyBillingView from '../views/LegacyBillingView';
import ManagementPage from '../views/Management.vue';
import BillingPageView from '../views/BillingPageView.vue';

export default [
	{
		path: '/list',
		name: 'list',
		component: List,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/edit',
		name: 'editreadings',
		component: ReadingEdit,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/view',
		name: 'viewreadings',
		component: ReadingView,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/view_only',
		name: 'viewonlyreadings',
		component: ReadingViewOnly,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/billing',
		name: 'viewbilling',
		component: BillingView,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/billing/old',
		name: 'viewoldbilling',
		component: LegacyBillingView,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/:site_name/unread',
		name: 'viewunread',
		component: Unread,
		meta: { requiresAuth: true }
	},
	{
		path: '/site/management',
		name: 'management',
		component: ManagementPage,
		meta: { requiresAuth: true }
	},
	{
		path: '/dashboard',
		name: 'dashboard',
		component: Dash,
		meta: { requiresAuth: true }
	},
	{
		path: '/billing',
		name: 'billing',
		component: BillingPageView,
		meta: { requiresAuth: true }
	},
];

