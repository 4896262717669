<template>
  <div class="p-field p-grid justify-center">
    <label
      for="site_name"
      class="p-col-fixed"
      style="width: 100px"
    >Site Name</label>
    <div class="p-col-fixed">
      <span class="p-input-icon-right">
        <AutoComplete
          id="Site Name"
          v-model="site"
          :suggestions="filteredSites"
          field="site_name"
          @complete="searchSites($event.query)"
          @item-select="loadCycles($event)"
          @item-unselect="loadCycles()"
          @clear="hideCycles()"
        />
      </span>
    </div>
  </div>
  <template v-if="showCycles">
    <template v-if="loadingCycles">
      <div class="lds-dual-ring" />
    </template>
    <template v-else>
      <div class="p-3">
        <Button
          label="CREATE NEW CYCLE"
          @click="newCycle"
        />
      </div>
      <transition-group
        name="list"
        tag="p"
      >
        <template v-if="cycles.length > 0">
          <template
            v-for="(cycle, index) in cycles"
            :key="cycle.id"
          >
            <BlockUI
              :blocked="cycle.blocked"
              :baseZIndex="50"
            >
              <div class="flex justify-center flex-col border-b-2">
                <span class="text-2xl">
                  Cycle {{ cycles.length - index }}
                  <span class="italic text-gray-400 text-lg ml-3">
                    [ID{{ cycle.id }}]
                  </span>
                  <i
                    v-tooltip="cycle.saved ? 'Saved.' : 'Unsaved.'"
                    class="pi ml-3"
                    :class="{
                      'pi-exclamation-circle text-yellow-300': !cycle.saved,
                      'pi-check-circle text-green-600': cycle.saved,
                    }"
                    style="font-size: 1rem"
                  />
                  <ConfirmPopup />
                  <i
                    v-tooltip="'Delete cycle.'"
                    class="pi pi-trash text-red-500 ml-3 cursor-pointer"
                    style="font-size: 1rem"
                    @click="deleteCycle($event, cycle, index)"
                  />
                </span>
                <div class="p-fluid p-field p-grid p-col justify-center">
                  <div class="ml-2">
                    <label for="date_range">Date range</label>
                    <n-config-provider
                      :theme-overrides="{ common: { primaryColor: '#e5b52b' } }"
                      :theme="darkTheme"
                    >
                      <Calender
                        id="date_range"
                        v-model:value="cycle.date_range"
                        type="daterange"
                        :theme="darkTheme"
                        size="large"
                        clearable
                        @change="cycle.saved = false"
                      />
                    </n-config-provider>
                  </div>
                  <div class="ml-2">
                    <label for="cycle_code">Billing Cycle</label>
                    <div class="flex flex-row">
                      <Dropdown
                        v-model="cycle.cycle_code_month"
                        :options="cycle_months"
                        placeholder="MONTH"
                        @change="cycle.saved = false"
                      />
                      <Dropdown
                        v-model="cycle.cycle_code_year"
                        :options="cycle_years"
                        placeholder="YEAR"
                        @change="cycle.saved = false"
                      />
                    </div>
                  </div>
                  <div class="ml-2">
                    <label for="save_buttons"><pre>&nbsp;</pre></label>
                    <div
                      id="save_button"
                      class="w-36 flex flex-row"
                    >
                      <template v-if="cycle.new">
                        <Button
                          v-show="!cycle.saved"
                          label="Submit."
                          @click="submitNewCycle(cycle, index)"
                        />
                      </template>
                      <template v-else>
                        <Button
                          v-show="!cycle.saved"
                          label="Save."
                          @click="saveCycle(cycle, index)"
                        />
                        <Button
                          v-show="!cycle.saved"
                          label="Cancel."
                          class="ml-1"
                          @click="cancelCycleChanges(cycle, index)"
                        />
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </BlockUI>
          </template>
        </template>
        <template v-else>
          <span class="text-2xl"> No cycles available, create a cycle? </span>
        </template>
      </transition-group>
    </template>
  </template>
</template>

<script>
import SetupService from '../../services/SetupProvider';
import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import ConfirmPopup from 'primevue/confirmpopup';
import BlockUI from 'primevue/blockui';
import { NDatePicker, NConfigProvider } from 'naive-ui';
import { darkTheme } from 'naive-ui';
import _ from 'lodash';

export default {
	components: {
		AutoComplete,
		Dropdown,
		Calender: NDatePicker,
		NConfigProvider,
		ConfirmPopup,
		BlockUI,
	},
	data() {
		return {
			site: null,
			filteredSites: [],
			showCycles: false,
			cycles: [],
			ogCycles: [],
			loadingCycles: true,
			cycle_months: [
				'JAN',
				'FEB',
				'MAR',
				'APR',
				'MAY',
				'JUN',
				'JUL',
				'AUG',
				'SEP',
				'OCT',
				'NOV',
				'DEC',
			],
			cycle_years: [],
		};
	},
	created() {
		this.setupService = new SetupService();
		this.darkTheme = darkTheme;
		for (let index = 2018; index < 2030; index++) {
			this.cycle_years.push(index);
		}
	},
	methods: {
		searchSites(query) {
			console.log(query);
			this.setupService.search(query).then((res) => {
				this.filteredSites = res.data;
			});
		},
		loadCycles(event) {
			this.loadingCycles = true;
			this.setupService
				.getCycles(event.value.id)
				.then((res) => {
					this.cycles = res.data.cycles.reverse();
					this.cycles.forEach((cycle) => {
						cycle.saved = true;
						cycle.new = false;
						cycle.blocked = cycle.billing_status ? true : false;
						cycle.date_range = [
							new Date(cycle.from_date).getTime(),
							new Date(cycle.to_date).getTime(),
						];
					});
					this.ogCycles = _.cloneDeep(this.cycles);
					this.loadingCycles = false;
				})
				.catch((err) => {
					this.cycles = [];
					this.loadingCycles = false;
					this.site = null;
					this.showCycles = false;
					this.$toast.add({
						severity: 'error',
						summary: 'Failed to retrieve cycles.',
						detail: err.message,
					});
				});
			this.showCycles = true;
		},
		hideCycles() {
			this.showCycles = false;
			this.cycles = [];
			this.site = null;
		},
		dateToDateString(date) {
			date = new Date(date);
			return (
				date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
			);
		},
		deleteCycle(event, cycle, index) {
			this.$confirm.require({
				target: event.currentTarget,
				message: 'Are you sure?',
				icon: 'pi pi-exclamation-triangle',
				accept: () => {
					cycle.blocked = true;
					this.setupService
						.deleteCycle(cycle.site_id, cycle.id)
						.then((res) => {
							this.cycles.splice(index, 1);
							this.ogCycles.splice(index, 1);
							this.$toast.add({
								severity: 'success',
								detail: 'Cycle successfully deleted!',
								summary: res.data.message,
							});
						})
						.catch((err) => {
							console.error(err);
						});
				},
				reject: () => {},
			});
		},
		saveCycle(cycle, index) {
			console.log(cycle);
			this.setupService
				.modifyCycle(
					cycle.id,
					cycle.site_id,
					this.dateToDateString(cycle.date_range[0]),
					this.dateToDateString(cycle.date_range[1]),
					cycle.cycle_code_month + cycle.cycle_code_year
				)
				.then((res) => {
					this.$toast.add({
						severity: 'success',
						detail: 'Cycle successfully updated!',
						summary: res.data.message,
					});
					cycle.saved = true;
					this.ogCycles[index] = _.cloneDeep(cycle);
				})
				.catch((err) => {
					this.$toast.add({
						severity: 'error',
						detail: 'Something went wrong!',
						summary: err.response.message,
					});
				});
		},
		cancelCycleChanges(cycle, index) {
			if (!cycle.new) {
				cycle.saved = true;
				this.cycles[index] = _.cloneDeep(this.ogCycles[index]);
			} else {
				this.cycles.splice(index, 1);
			}
		},
		submitNewCycle(cycle, index) {
			console.log('Submit new cycle.');
			this.setupService
				.newCycle(
					cycle.site_id,
					this.dateToDateString(cycle.date_range[0]),
					this.dateToDateString(cycle.date_range[1]),
					cycle.cycle_code_month + cycle.cycle_code_year
				)
				.then((res) => {
					cycle.new = false;
					cycle.saved = true;
					cycle.id = res.data.cycle_id;
					this.ogCycles[index] = _.cloneDeep(cycle);
					this.$toast.add({
						severity: 'success',
						detail: 'Cycle successfully created!',
						summary: res.data.message,
					});
				})
				.catch((err) => {
					// let detail = err.response.data.message;

					if (err.response.data.errors) {
						let errors = err.response.data.errors;
						errors.forEach((error) => {
							this.$toast.add({
								severity: 'error',
								summary: err.response.data.message,
								detail: error,
							});
						});
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Something went wrong!',
							detail: err.response.data.message,
						});
					}
				});
		},
		newCycle() {
			if (this.cycles.length > 0) {
				let top = this.cycles.length - 1;
				this.cycles.unshift({
					cycle_code: this.cycles[top].cycle_code,
					cycle_code_month: this.cycles[top].cycle_code_month,
					cycle_code_year: this.cycles[top].cycle_code_year,
					date_range: [
						this.cycles[0].date_range[0] + 2592000000,
						this.cycles[0].date_range[1] + 2592000000,
					],
					from_date: this.cycles[top].from_date,
					id: null,
					new: true,
					saved: false,
					site_id: this.cycles[top].site_id,
					to_date: this.cycles[top].to_date,
				});
			} else {
				this.cycles.push({
					cycle_code: null,
					cycle_code_month: null,
					cycle_code_year: null,
					date_range: [new Date().getTime(), new Date().getTime()],
					from_date: null,
					id: null,
					new: true,
					saved: false,
					site_id: this.site.id,
					to_date: null,
				});
			}
		},
		checkCycleData(cycle) {
			cycle.saved = false;
			console.log(cycle);
		},
	},
};
</script>

<style>
.lds-dual-ring {
	display: inline-block;
	width: 80px;
	height: 80px;
}
.lds-dual-ring:after {
	content: ' ';
	display: block;
	width: 64px;
	height: 64px;
	margin: 8px;
	border-radius: 50%;
	border: 6px solid #fff;
	border-color: #fff transparent #fff transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.list-item {
	display: inline-block;
	margin-right: 10px;
}

.list-enter-active,
.list-leave-active {
	transition: all 1s;
}

.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateX(50px);
}
</style>
