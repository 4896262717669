<template>
  <div class="p-field p-grid">
    <label
      class="p-col-fixed"
      style="width:100px"
      for="site_name"
    >Site Name</label>
    <div
      class="p-col-fixed"
      style="width:100px"
    >
      <AutoComplete
        id="site_name"
        v-model="site"
        :suggestions="filteredSites"
        field="site_name"
        @complete="searchSites($event.query)"
        @item-select="loadAvailableLogs($event)"
        @item-unselect="hideAll()"
        @clear="hideAll()"
      />
    </div>
  </div>

  <div
    v-if="showLogTypes"
    class="p-field p-grid"
  >
    <label
      class="p-col-fixed"
      style="width:100px"
      for="log_type"
    >Log Type</label>
    <div
      class="p-col-fixed"
      style="width:100px"
    >
      <DropDown
        id="log_type"
        v-model="selectedLogType"
        :options="logTypes"
        optionLabel="name"
        placeholder="Select a log type"
        @change="showDateSelect($event)"
      />
    </div>
  </div>
  <div
    v-if="showLogDates"
    class="p-field p-grid"
  >
    <label
      class="p-col-fixed"
      style="width:100px"
      for="log_dates"
    >Log Dates</label>
    <div
      id="log_dates"
      class="p-d-flex"
      style="width:100px"
    >
      <DropDown
        v-model="selectedLogYear"
        :options="logYears"
        optionLabel="value"
        placeholder="Year"
        @click="loadLogYears()"
        @change="loadLogs($event)"
      />
      <DropDown
        v-model="selectedLogMonth"
        :options="logMonths"
        optionLabel="value"
        placeholder="Month"
        @click="loadLogMonths()"
        @change="loadLogs($event)"
      />
      <DropDown
        v-model="selectedLogDay"
        :options="logDays"
        optionLabel="value"
        placeholder="Day"
        @click="loadLogDays()"
        @change="loadLogs($event)"
      />
    </div>
  </div>
  <div
    v-if="showLogs"
    class="p-field"
  >
    <template
      v-for="(log, index) in logs"
      :key="index"
    >
      <Button
        :label="dateFormatService.getHumanDate(log.name)"
        @click="openDialog(log)"
      />
      <Dialog
        v-model:visible="log.view"
        :header="log.name"
        :maximizable="true"
        position="top"
      >
        <pre>
                {{ JSON.stringify(log.content, null, '\t') }}
            </pre>
      </Dialog>
      <br>
    </template>
  </div>
</template>

<script>
import SiteInfoService from '../services/SiteInfoProvider';
import DateFormatService from '../services/DateFormatProvider';

import AutoComplete from 'primevue/autocomplete';
import DropDown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';

export default {
	components: {
		AutoComplete,
		DropDown,
		Dialog
	},
	data() {
		return {
			site: [],
			filteredSites: [],
			showLogTypes: false,
			showLogDates: false,
			showLogs: false,
			logTypes: [],
			selectedLogType: null,
			selectedLogMonth: undefined,
			selectedLogYear: undefined,
			selectedLogDay: undefined,
			logDate: null,
			logDays: [],
			logMonths: [],
			logYears: [],
			logs: []
		};
	},
	created() {
		this.siteInfoService = new SiteInfoService();
		this.dateFormatService = new DateFormatService();
	},
	methods: {
		searchSites(query) {
			this.siteInfoService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		loadAvailableLogs(event) {
			this.logTypes = [
				{ name: 'Form submission logs', prefix: 'file_submission' },
				{ name: 'Update logs', prefix: 'reading_update' }
			];
			this.showLogTypes = true;
		},
		hideAll() {
			this.showLogTypes = false;
			this.showLogDates = false;
			this.showLogSpecifics = false;
			this.showLog = false;
		},
		showDateSelect() {
			this.showLogDates = true;
		},
		loadLogYears() {
			this.siteInfoService
				.getLogYears(
					this.site.id,
					this.selectedLogType.prefix,
					this.selectedLogMonth,
					this.selectedLogDay
				)
				.then(res => {
					this.logYears = res.data;
				});
		},
		loadLogMonths() {
			this.siteInfoService
				.getLogMonths(
					this.site.id,
					this.selectedLogType.prefix,
					this.selectedLogYear,
					this.selectedLogDay
				)
				.then(res => {
					this.logMonths = res.data;
				});
		},
		loadLogDays() {
			this.siteInfoService
				.getLogDays(
					this.site.id,
					this.selectedLogType.prefix,
					this.selectedLogYear,
					this.selectedLogDay
				)
				.then(res => {
					this.logDays = res.data;
				});
		},
		loadLogs() {
			this.showLogs = true;
			this.siteInfoService
				.getLogs(
					this.site.id,
					this.selectedLogType.prefix,
					this.selectedLogYear,
					this.selectedLogMonth,
					this.selectedLogDay
				)
				.then(res => {
					this.logs = res.data;
				});
		},
		openDialog(log) {
			log.view = true;
		}
	}
};
</script>

<style></style>
