<template>
  <div class="px-8">
    <Accordion
      :activeIndex="0"
      lazy
    >
      <AccordionTab>
        <template #header>
          <i class="pi pi-map-marker" />
          <span class="ml-2"> Register a new site</span>
        </template>
        <NewSiteComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-calendar-times" />
          <span class="ml-2"> Cycles</span>
        </template>
        <TabView :lazy="true">
          <TabPanel>
            <template #header>
              <i class="pi pi-globe mr-2" />
              <span>Global Cycles</span>
            </template>
            <GlobalCycleComponent />
          </TabPanel>
          <TabPanel>
            <template #header>
              <i class="pi pi-flag  mr-2" />
              <span>Site Cycles</span>
            </template>
            <SiteCycleComponent />
          </TabPanel>
        </TabView>
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-clock" />
          <span class="ml-2"> Insert Meters</span>
        </template>
        <MeterComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-clock" />
          <span class="ml-2"> Final Readings</span>
        </template>
        <FinalReadingComponent />
      </AccordionTab>

      <AccordionTab>
        <template #header>
          <i class="pi pi-sort-alt" />
          <span class="ml-2"> Form Sequence Management </span>
        </template>
        <FormOrderComponent />
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script>
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel'; 

import NewSiteComponent from '../components/Setup/NewSiteComponent.vue';
import MeterComponent from '../components/Setup/MeterComponent.vue';
import SiteCycleComponent from '../components/Setup/SiteCycleComponent.vue';
import GlobalCycleComponent from '../components/Setup/GlobalCycleComponent.vue';
import FinalReadingComponent from '../components/Setup/FinalReadingComponent.vue';
import FormOrderComponent from '../components/Form/FormOrderComponent.vue';


export default {
	name: 'OperatorOptions',
	components: {
		Accordion,
		AccordionTab,
		NewSiteComponent,
		MeterComponent,
		SiteCycleComponent,
		GlobalCycleComponent,
		TabView,
		TabPanel,
		FinalReadingComponent,
		FormOrderComponent
	}
};
</script>
