<template>
  <div class="m:container lg:mx-8">
    <ReadingViewGridVue :siteName="siteName" />
  </div>
</template>

<script>
import ReadingViewGridVue from '../components/ReadingViewGrid.vue';

export default {
	components: {
		ReadingViewGridVue
	},
	data(){
		return {
			siteName: this.$route.params.site_name
		};
	},
	mounted(){
		document.title = '👁️ ' + this.siteName + ' | URBTEC';
	}
};
</script>