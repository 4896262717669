<template>
  <div class="card px-8">
    <Generate />
  </div>
</template>

<script>
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Card from 'primevue/card';

import Generate from '../components/Billing/Generate.vue';
import Export from '../components/Billing/Export.vue';
import FinalReadingBilling from '../components/Billing/FinalReadingBilling.vue';

export default {
	components: {
		TabView,
		TabPanel,
		Card,
		Generate,
		Export,
		FinalReadingBilling
	},
};
</script>

<style>

</style>