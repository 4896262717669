<template>
  <Chart
    ref="currentMeterUsages"
    type="bar"
    :data="currentMeterUsageData"
    :options="meterUsageOptions"
  />
  <Chart
    ref="lastMeterUsages"
    type="bar"
    :data="lastMeterUsageData"
    :options="meterUsageOptions"
  />
</template>

<script>
import DashInfoService from '../../services/DashProvider';

export default {
	data() {
		return {
			currentMeterUsageData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: '#42A5F5',
						label: 'Sites and their meters'
					}
				]
			},
			lastMeterUsageData: {
				labels: [],
				datasets: [
					{
						data: [],
						backgroundColor: '#42A5F5',
						label: 'Sites and their meters'
					}
				]
			},
			meterUsageOptions: {
				responsive: true
			}
		};
	},
	created() {
		this.dashInfoService = new DashInfoService();
	},
	mounted() {
		this.dashInfoService.latestMeterUsages().then(res => {
			// console.log(this.currentMeterUsageData.datasets[0]);
			this.currentMeterUsageData.datasets[0].data = res.data.totalUsages;
			this.currentMeterUsageData.labels = res.data.sites;
			this.$refs.currentMeterUsages.refresh();
		});

		this.dashInfoService.lastMeterUsages().then(res => {
			// console.log(this.lastMeterUsageData.datasets[0]);
			this.lastMeterUsageData.datasets[0].data = res.data.totalUsages;
			this.lastMeterUsageData.labels = res.data.sites;
			this.$refs.lastMeterUsages.refresh();
		});
	}
};
</script>

<style></style>
