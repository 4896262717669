<template>
  <div class="flex  flex-col justify-center items-center">
    <label
      for="site_name"
      class="p-col-fixed"
      style="width:100px"
    >Site Name</label>
    <div class="p-col-fixed">
      <span class="p-input-icon-right">
        <AutoComplete
          id="Site Name"
          v-model="site"
          :suggestions="filteredSites"
          field="site_name"
          @complete="searchSites($event.query)"
          @item-select="loadForms($event)"
          @item-unselect="hideForms()"
          @clear="hideForms()"
        />
      </span>
    </div>
  </div>
  <template v-if="formsLoading">
    LOADING ANIMATION
  </template>
  <template v-else-if="showForms">
    <BlockUI :blocked="submitLoading">
      <PickList
        v-model="operationLists"
        dataKey="id"
        @move-to-target="handleMove()"
        @move-to-source="handleMove()"
        @move-all-to-target="handleMove()"
        @move-all-to-source="handleMove()"
        @reorder="handleMove()"
      >
        <template #sourceheader>
          <div class="flex justify-center items-center">
            <div class="text-2xl mx-3">
              A
            </div>
            <div>
              <DropDown
                v-model="listsSelected[0]"
                :options="getFormOptions(1)"
                optionLabel="name"
                optionValue="index"
                :filter="true"
                @change="changeList($event, 0)"
              />
            </div>
            <div
              class="m-2 cursor-pointer"
              @click="newList(0)"
            >
              <i
                class="pi pi-plus"
                style="font-size: 2rem;"
              />
            </div>
            <div
              class="m-2 cursor-pointer"
              @click="deleteList(0)"
            >
              <i
                class="pi pi-trash text-red-700"
                style="font-size: 2rem;"
              />
            </div>
            <div v-if="operationLists[0]">
              <template v-if="operationLists[0].length === formLimit">
                <div class="text-yellow-400">
                  {{ operationLists[0].length }} Full
                </div>
              </template>
              <template v-else-if="operationLists[0].length > formLimit">
                <div class="text-red-400">
                  {{ operationLists[0].length }} Overloaded
                </div>
              </template>
              <template v-else>
                <div class="text-green-600">
                  {{ operationLists[0].length }}
                </div>
              </template>
            </div>
          </div>
          <div
            class="flex justify-between pr-5"
          >
            <div>
              Pos
            </div>
            <div>
              Meter no.
            </div>
            <div>
              Unit
            </div>
            <div>
              DOT
            </div>
          </div>
        </template>
        <template #targetheader>
          <div class="flex justify-center items-center">
            <div class="text-2xl mx-3">
              B
            </div>
            <div>
              <DropDown
                v-model="listsSelected[1]"
                :options="getFormOptions(0)"
                optionLabel="name"
                optionValue="index"
                :filter="true"
                @change="changeList($event, 1)"
              />
            </div>
            <div
              class="m-2 cursor-pointer"
              @click="newList(1)"
            >
              <i
                class="pi pi-plus"
                style="font-size: 2rem;"
              />
            </div>
            <div
              class="m-2 cursor-pointer"
              @click="deleteList(1)"
            >
              <i
                class="pi pi-trash text-red-700"
                style="font-size: 2rem;"
              />
            </div>
            <div v-if="operationLists[1]">
              <template v-if="operationLists[1].length === formLimit">
                <div class="text-yellow-400">
                  {{ operationLists[1].length }} Full
                </div>
              </template>
              <template v-else-if="operationLists[1].length > formLimit">
                <div class="text-red-400">
                  {{ operationLists[1].length }} Overloaded
                </div>
              </template>
              <template v-else>
                <div>
                  {{ operationLists[1].length }}
                </div>
              </template>
            </div>
          </div>
          <div
            class="flex justify-between pr-5"
          >
            <div>
              Pos
            </div>
            <div>
              Meter no.
            </div>
            <div>
              Unit
            </div>
            <div>
              DOT
            </div>
          </div>
        </template>
        <template
          #item="slotProps"
        >
          <div
            class="flex justify-between"
            :class="{ 'text-red-600 font-bold': slotProps.index > 24 }"
          >
            <div>
              {{ slotProps.index + 1 }}
            </div>
            <div>
              {{ slotProps.item.meter_no }}
            </div>
            <div>
              {{ slotProps.item.unit }}
            </div>
            <div
              class="dot"
              :style="{ 'background-color': slotProps.item.color}"
            />
          </div>
        </template>
      </PickList>
    </BlockUI>
    <Button
      class="m-6 text-3xl"
      :disabled="!touched"
      :loading="submitLoading"
      @click="saveChanges()"
    >
      SAVE CHANGES
    </Button>
  </template>
</template>

<script>
import PickList from 'primevue/picklist';
import DropDown from 'primevue/dropdown';
import FormService from '@/domains/Operator/services/FormProvider';
import AutoComplete from 'primevue/autocomplete';
import HelpService from '../../services/HelpProvider';
import SetupService from '../../services/SetupProvider';
import BlockUI from 'primevue/blockui';


export default {
	components: {
		PickList,
		DropDown,
		AutoComplete,
		BlockUI
	},
	data(){
		return {
			formLimit: 25,
			operationLists: [],
			operationListsHistory: [],
			listsSelected: [ 0, 1 ],
			listFrom: [ 0, 1],
			listOptions: [],
			lists: [],
			touched: false,
			site: null,
			showForms: false,
			submitLoading: false,
			formsLoading: false,
			filteredSites: [],
		};
	},
	created() {
		this.setupService = new SetupService();
	},
	methods: {
		searchSites(query) {
			this.setupService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		loadForms(){
			this.formsLoading = true;
			FormService.getForms(this.site.site_name)
				.then((res) => {
					let count = 0;
					for (let form in res.data.forms){
						this.listOptions.push({
							name: 'Form ' + (count+1) + ' [' + form + ']',
							index: count
						});
						this.lists[count] = res.data.forms[form];
						count++;
						for (let meter in res.data.forms[form]){
							res.data.forms[form][meter].color = HelpService.randomColor();
						}
					}
					this.operationLists = [
						this.lists[0],
						this.lists[1],
					];
					this.operationListsHistory = [
						this.lists[0],
						this.lists[1]
					];
					this.formsLoading = false;
					this.showForms = true;
				})
				.catch((err) => {
					console.error(err);
				});
		},
		hideForms(){
			this.operationLists = [];
			this.operationListsHistory = [];
			this.listsSelected = [ 0, 1 ];
			this.listFrom = [ 0, 1];
			this.listOptions = [];
			this.lists = [];
			this.touched = false;
			this.showForms = false;
			this.submitLoading = false;
			this.formsLoading = false;
		},
		changeList(event, sourceOrTarget){
			if (this.lists[this.listFrom[sourceOrTarget]] !== undefined){
				// Updates value in list array
				this.lists[this.listFrom[sourceOrTarget]] = this.operationLists[sourceOrTarget];
			}
			// Set operational list to new value
			this.operationLists[sourceOrTarget] = this.lists[this.listsSelected[sourceOrTarget]];
			// Set value for history
			this.operationListsHistory[sourceOrTarget] = this.lists[this.listsSelected[sourceOrTarget]];
			// Update from value for next change
			this.listFrom[sourceOrTarget] = event.value;
		},
		getFormOptions(targetIndex){
			let availableList = [];
			this.listOptions.forEach((option) => {
				if (this.listsSelected[targetIndex] != option.index){
					availableList.push(option);
				}
			});
			return availableList;
		},
		newList(sourceOrTarget){
			// Create new form
			const index = this.lists.length;
			this.lists[index] = [];
			this.listOptions.push({ name: 'Form ' + (index+1), index: index});
			// Set created form as active where created
			this.listsSelected[sourceOrTarget] = index;
			this.changeList({ value: index }, sourceOrTarget);
		},
		deleteList(sourceOrTarget){
			const listToDelete = this.listsSelected[sourceOrTarget];
			if (this.lists[listToDelete].length === 0){
				this.listsSelected[sourceOrTarget] = null;
				this.listOptions.splice(listToDelete,1);
				this.lists.splice(listToDelete,1);
				// const availableLists = this.getFormOptions((sourceOrTarget === 1) ? 0 : 1);
				// if (availableLists.length > 0){
				// 	this.listsSelected
				// }
			} else {
				this.$toast.add({
					severity: 'error', 
					summary: 'Form not empty!', 
					detail: 'Can\'t delete forms with units.', 
					life: 1500 
				});
			}
		},
		handleMove(){
			this.touched = true;
			this.lists[this.listFrom[0]] = this.operationLists[0];
			this.lists[this.listFrom[1]] = this.operationLists[1];
		},
		saveChanges(){
			this.submitLoading = true;
			this.lists[this.listFrom[0]] = this.operationLists[0];
			this.lists[this.listFrom[1]] = this.operationLists[1];
			FormService.checkForms(this.lists, this.formLimit)
				.then((res) => {
					FormService.putForms(this.site.site_name, this.lists)
						.then((res) => {	
							this.$toast.add({
								severity: 'success', 
								summary: 'Forms successfully updated.', 
								detail: res.data.message
							});
							this.touched = false;
						})
						.catch((err) => {
							this.$toast.add({
								severity: 'error', 
								summary: 'An error has occured!', 
								detail: err.message
							});
						});
				})
				.catch((err) => {
					this.changeList({ value: err.index }, 0);
					this.$toast.add({
						severity: 'error', 
						summary: 'Problem on form ' + (err.index+1), 
						detail: err.message
					});
				})
				.finally(() => {
					this.submitLoading = false;
				});
		}
	}

};
</script>

<style>
.dot {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
}
</style>