<template>
  <div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-4 p-md-3">
        <label for="site_name">Site Name</label>
        <AutoComplete
          id="site_name"
          v-model="sites"
          :suggestions="filteredSites"
          field="site_name"
          @complete="searchSites($event.query)"
        />
      </div>
      <div class="p-field p-col-4 p-md-3">
        <label for="invoiceNo">Invoice No.</label>
        <InputMask
          id="invoiceNo"
          v-model="invoice_no"
          type="text"
          mask="999 999 999 999"
        />
      </div>
      <div class="p-field p-col-4 p-md-3">
        <label for="accountNo">Account No</label>
        <InputMask
          id="accountNo"
          v-model="account_no"
          type="text"
          mask="9999 999 999"
        />
      </div>
      <div class="p-field p-col-4 p-md-3">
        <label for="invoiceDate">Invoice Date</label>
        <InputMask
          id="invoiceDate"
          v-model="invoice_date"
          type="text"
          mask="9999/99/99"
          placeholder="yyyy/mm/dd"
        />
      </div>
      <template
        v-for="(row, index) in rows"
        :key="index"
      >
        <div class="p-grid">
          <div class="p-field p-col p-md-2">
            <label for="utility">Utility</label>
            <Dropdown
              v-model="row.utility"
              :inputId="'utility' + index"
              :options="utilities"
              placeholder="Select Utility"
            />
          </div>
          <div
            class="p-field p-col p-md-1"
            style=" width: 135px"
          >
            <label :for="'date_from_' + index">Curr Date</label>
            <InputMask
              :id="'date_from_' + index"
              v-model="row.reading_date_curr"
              type="text"
              mask="9999/99/99"
              placeholder="yyyy/mm/dd"
            />
          </div>
          <div
            class="p-field p-col"
            style=" width: 135px"
          >
            <label :for="'date_from_' + index">Prev Date</label>
            <InputMask
              :id="'date_from_' + index"
              v-model="row.reading_date_last"
              type="text"
              mask="9999/99/99"
              placeholder="yyyy/mm/dd"
            />
          </div>
          <div
            class="p-field p-col"
            style=" width: 180px"
          >
            <label :for="'currReading' + index">Current Reading</label>
            <InputNumber
              :id="'currReading' + index"
              v-model="row.current_reading"
              :suffix="getUnits(row.utility)"
            />
          </div>
          <div
            class="p-field p-col"
            style=" width: 180px"
          >
            <label :for="'prevReading' + index">Previous Reading</label>
            <InputNumber
              :id="'prevReading' + index"
              v-model="row.previous_reading"
              :suffix="getUnits(row.utility)"
            />
          </div>
          <div
            class="p-field p-col"
            style=" width: 160px"
          >
            <label for="Consumption">Consumption</label>
            <InputNumber
              :id="'Consumption' + index"
              v-model="row.consumption"
              :suffix="getUnits(row.utility)"
            />
          </div>
          <div
            class="p-field p-col"
            style=" width: 160px"
          >
            <label for="Amount">Amount</label>
            <InputNumber
              :id="'Amount' + index"
              v-model="row.amount"
              mode="decimal"
              :minFractionDigits="2"
              prefix="R "
            />
          </div>
        </div>
      </template>
    </div>
    <Button
      icon="pi pi-plus"
      label="Add row"
      class="float-left"
      @click="newRow()"
    />
    <Button
      :disabled="rows.length === 1"
      icon="pi pi-minus"
      label="Remove row"
      class="float-left ml-4"
      @click="delRow()"
    />
    <br>

    <Button
      label="Submit"
      class="p-mt-1"
      @click="submitCouncilBill()"
    />
  </div>
</template>

<script>
import SiteInfoService from '../services/SiteInfoProvider';
import CouncilService from '../services/CouncilProvider';

import AutoComplete from 'primevue/autocomplete';
import Dropdown from 'primevue/dropdown';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';

export default {
	components: {
		Dropdown,
		AutoComplete,
		InputNumber,
		InputMask
	},
	data() {
		return {
			sites: [],
			filteredSites: [],
			utilities: ['electricity', 'water', 'sanitation'],
			invoice_date: null,
			invoice_no: null,
			account_no: null,
			rows: [
				{
					utility: null,
					reading_date_last: null,
					reading_date_curr: null,
					consumption: null,
					amount: null,
					current_reading: null,
					previous_reading: null
				}
			]
		};
	},
	created() {
		this.siteInfoService = new SiteInfoService();
		this.councilService = new CouncilService();
	},
	methods: {
		searchSites(query) {
			this.siteInfoService.search(query).then(res => {
				this.filteredSites = res.data;
			});
		},
		newRow() {
			this.rows.push({
				utility: null,
				reading_date_last: null,
				reading_date_curr: null,
				consumption: null,
				amount: null,
				current_reading: null,
				previous_reading: null
			});
		},
		delRow() {
			this.rows.pop();
		},
		getUnits(utility) {
			switch (utility) {
			case 'electricity':
				return ' KWH';
			case 'water':
			case 'sanitation':
				return ' Kl';
			default:
				return '';
			}
		},
		submitCouncilBill() {
			this.councilService
				.submitCouncilBill(
					this.rows,
					this.sites.site_name,
					this.invoice_date,
					this.invoice_no,
					this.account_no
				)
				.then(res => {
					this.$toast.add({
						severity: 'success',
						summary: 'Billing lines successfully inserted',
						detail: res.data.message
					});
				})
				.catch(err => {
					this.$toast.add({
						severity: 'error',
						summary: 'Billing lines successfully inserted',
						detail: err.response.message
					});
				});
		}
	}
};
</script>

<style></style>
