export default class ShortDateProvider {
	static shortMonthArray(month){
		const shortMonthArray = [
			'JAN',
			'FEB',
			'MAR',
			'APR',
			'MAY',
			'JUN',
			'JUL',
			'AUG',
			'SEP',
			'OCT',
			'NOV',
			'DEC'
		];

		if (month){
			return shortMonthArray[month];
		} else {
			return shortMonthArray;
		}
	}

	static regularMonthArray(month){
		const regularMonthArray = [
			'JANUARY',
			'FEBRUARY',
			'MARCH',
			'APRIL',
			'MAY',
			'JUNE',
			'JULY',
			'AUGUST',
			'SEPTEMBER',
			'OCTOBER',
			'NOVEMBER',
			'DECEMBER'
		];

		if (month){
			return regularMonthArray[month];
		} else {
			return regularMonthArray;
		}
	}

	shortDateToProperDate(shortDate){
		const shortMonthIndex = ShortDateProvider.shortMonthArray().indexOf(shortDate.substring(0, 3));

		if (shortMonthIndex >= 0){
			return new Date(shortDate.substring(3, 7) + '-' + (shortMonthIndex + 1) + '-' + '01');
		} else {
			return false;
		}
	}   
}