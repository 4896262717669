<template>
  <div class="m:container lg:mx-8">
    <ListComponent />
  </div>
</template>

<script>
import ListComponent from '../components/ListComponent.vue';

export default {
	name: 'List',
	components: {
		ListComponent
	},
	mounted() {
		document.title = 'Site List' + ' | URBTEC';
	}
};
</script>

<style></style>
