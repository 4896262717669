<template>
  <div class="home items-center align-middle">
    <img
      src="@/assets/logo.svg"
      class="block ml-auto mr-auto w-1/4"
    >
    <h1>URBTEC PORTAL</h1>
  </div>
</template>

<script>
export default {
	name: 'Home',
	components: {},
	// mounted() {
	// 	this.$toast.add({
	// 		severity: 'info',
	// 		summary: 'Work in progress!',
	// 		detail: 'The site it not currently available as it is being built.'
	// 	});
	// },
	methods: {}
};
</script>
