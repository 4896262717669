<template>
  <div class="p-d-flex p-jc-center">
    <div class="p-mr-2">
      <AutoComplete
        v-model="user"
        :suggestions="filteredUsers"
        field="name"
        @complete="searchUsers($event)"
        @item-select="loadRoles()"
        @item-unselect="hideRoleList()"
        @clear="hideRoleList()"
      />
    </div>
    <div class="border-l-2" />
    <div class="p-ml-2">
      <template v-if="showRoles">
        <div
          v-for="role in roles"
          :key="role.id"
          class="p-field-checkbox"
        >
          <Checkbox
            :id="role.name + role.id"
            v-model="userRoles"
            name="roles"
            :value="role.id"
            @change="updateRole(role)"
          />
          <label :for="role.name + role.id">
            <span class="font-bold">{{ role.name }}: </span>
            {{ role.description }}
          </label>
          <br>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import AutoComplete from 'primevue/autocomplete';
import Checkbox from 'primevue/checkbox';
import UserInfoService from '../services/UserInfoProvider';

export default {
	components: {
		AutoComplete,
		Checkbox
	},
	data() {
		return {
			user: [],
			filteredUsers: [],
			showRoles: false,
			roles: [],
			userRoles: []
		};
	},
	created() {
		this.userService = new UserInfoService();

		this.userService.getAllRoles().then(res => {
			this.roles = res.data;
		});
	},
	methods: {
		searchUsers(event) {
			this.userService.searchUsers(event.query).then(res => {
				this.filteredUsers = res.data;
			});
		},
		hideRoleList() {
			this.showRoles = false;
		},
		loadRoles() {
			// console.log(this.user);
			this.userService.getUserRoles(this.user.id).then(res => {
				this.userRoles = res.data;
			});

			this.showRoles = true;
		},
		updateRole(role) {
			this.userService.toggleUserRole(this.user.id, role.id).then(res => {
				this.$toast.add({
					severity: 'success',
					summary: 'Role update successful!',
					detail: res.data.message,
					life: 1500
				});
			});
		}
	}
};
</script>

<style></style>
