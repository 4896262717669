import axios from 'axios';

export default class UtilitiesProvider {
	getCycles(site_name){
		return axios({
			method: 'get',
			url: '/billing/util/cycles',
			params: {
				site_name: site_name
			}
		});
	}

	viewBilling(site_name, billing_year, billing_month, billing_type){
		return axios({
			method: 'get',
			url: '/billing/util/view',
			params: {
				site_name: site_name,
				billing_year: billing_year,
				billing_month: billing_month,
				billing_type: billing_type
			}
		});
	}
}