<template>
  <div class="m:container lg:mx-8">
    <ReadingGrid :siteName="siteName" />
  </div>
</template>

<script>
import ReadingGrid from '../components/ReadingEditGrid';

export default {
	components: {
		ReadingGrid
	},
	data(){
		return {
			siteName: this.$route.params.site_name
		};
	},
	mounted(){
		document.title = '✏️ ' + this.siteName + ' | URBTEC';
	}
};
</script>

<style>

</style>