<template>
  <div class="container mx-auto sm:w-3/5 sm:px-8">
    <div class="bg-urbcharcoal rounded grid content-center justify-items-center">
      <div class="m-4 p-4">
        <div class="p-4">
          <span class="p-float-label w-full">
            <Dropdown
              id="client"
              v-model="form.client"
              type="text"
              class="w-full text-left"
              :options="clients"
              optionValue="id"
              optionLabel="client_name"
              :loading="clientsLoading"
            />
            <label
              for="client"
            >Client</label>
          </span>
        </div>

        <div class="p-4">
          <span class="p-float-label">
            <InputText
              id="name"
              v-model="form.name"
              class="w-full"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              for="name"
            >Name</label>
          </span>
        </div>
				
        <div class="p-4">
          <span class="p-float-label">
            <InputText
              id="email"
              v-model="form.email"
              class="w-full"
              type="text"
              @keyup.enter="onSubmit"
            />
            <label
              for="email"
            >Email</label>
          </span>
        </div>

        <div
          v-for="role in roles"
          :key="role.id"
          class="pt-1 text-left"
        >
          <Checkbox
            :id="role.name + role.id"
            v-model="form.roles"
            name="roles"
            :value="role.id"
          />
          <label
            :for="role.name + role.id"
            class="pl-2"
          >
            <span class="font-bold">{{ role.name }}: </span>
            {{ role.description }}
          </label>
          <br>
        </div>
				
        <div class="p-4">
          <Button
            class=""
            label="Create New User"
            :loading="loading"
            @click="onSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';

import axios from 'axios';

import DatabaseService from '../services/DatabaseProvider';
import UserInfoService from '../services/UserInfoProvider';

export default {
	name: 'NewUserComponent',
	components: {
		InputText,
		Button,
		Dropdown,
		Checkbox
	},
	data() {
		return {
			form: {
				email: null,
				name: null,
				client: null,
				roles: null
			},
			loading: false,
			clients: [],
			clientsLoading: true,
			roles: [],
		};
	},
	databaseService: null,
	created(){
		this.databaseService = new DatabaseService();
		this.userService = new UserInfoService();

		this.databaseService.getDatabases()
			.then((res) => {
				this.clientsLoading = false;
				this.clients = res.data;
			})
			.catch((err) => {
				this.clientsLoading = false;
				console.error(err);
			});

		this.userService.getAllRoles().then(res => {
			this.roles = res.data;
		});
	},
	methods: {
		onSubmit() {
			this.loading = true;
			let formFlag = true;

			if(this.form.client === '') {
				this.$toast.add({severity:'error',summary: 'Information missing.', detail: 'Client is required.'});
				formFlag = false;
			}

			if(this.form.name === '') {
				this.$toast.add({severity:'error',summary: 'Information missing.', detail: 'Name is required.'});
				formFlag = false;
			}

			if(this.form.email === '') {
				this.$toast.add({severity:'error',summary: 'Information missing.', detail: 'Email is required.'});
				formFlag = false;
			}

			if (formFlag){
				axios.post('admin/user/register', this.form)
					.then((res) => {
						let message = 'Name: ' + res.data.user.name+' \n Email: ' + res.data.user.email;
						this.$toast.add({severity:'success',summary: 'User successfully added ', detail: message });
						this.form.email= null;
						this.form.name= null;
						this.form.client= null;
						this.loading = false;
					})
					.catch((err) => {
						this.loading = false;
						if (err.data.errors.email){
							this.$toast.add({severity:'error',summary: 'Error with details!', detail: err.response.data.errors });
						}
					});
			}
		}
	}
};
</script>