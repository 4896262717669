import axios from 'axios'; 

export default class SiteProvider {
	newSite(form){
		return axios({
			method: 'post',
			url: '/admin/sites/new',
			data: form
		});
	}

	validateMetersFromCSV(site_name, file) {
		var form = new FormData();
		form.append('data_file', file);
		form.append('site_name', site_name);
		return axios({
			method: 'post',
			url: '/admin/sites/meters/csv/validate',
			data: form
		});
	}
	importMetersFromCSV(site_name, file) {
		var form = new FormData();
		form.append('data_file', file);
		form.append('site_name', site_name);
		return axios({
			method: 'post',
			url: '/admin/sites/meters/csv/import',
			data: form
		});
	}
} 