<template>
  <div>
    <BillingGrid :siteName="site_name" />
  </div>
</template>

<script>
import BillingGrid from '../components/Billing/OldBillingGrid.vue';

export default {
	components: {
		BillingGrid
	},
	data() {
		return {
			site_name: this.$route.params.site_name
		};
	},
	mounted() {
		document.title = 'Billing' + ' | URBTEC';
	}
};
</script>

<style></style>
