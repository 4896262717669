import axios from 'axios';

export default class BillingProvider {
	generate(site_name, cycle_id){
		return axios({
			method: 'post',
			url: '/legacy/billing/generate',
			data: {
				site_name: site_name,
				cycle_id: cycle_id,
			}
		});
		
	}

	exportBilling(site_name, cycle_id, utility_type){
		return axios({
			method: 'get',
			url: '/legacy/billing/infoAsCSV',
			params: {
				cycle_id: cycle_id,
				site_name: site_name,
				utility_type: utility_type
			}
		});
	}

	exportBillingAsCSV(site_name, cycle_id, utility_type) {
		return this.exportBilling(site_name, cycle_id, utility_type)
			.then((res) => {
				let filename = res.headers['content-disposition'];
				filename = filename.split('filename=');
				filename = filename[1];
				filename = filename.replace(new RegExp('"', 'g'), '');

				let csv = res.data;
				const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

				if (window.navigator.msSaveOrOpenBlob) {
					navigator.msSaveOrOpenBlob(blob, filename);
				}
				else {
					let link = document.createElement('a');
					link.style.display = 'none';
					document.body.appendChild(link);
					if (link.download !== undefined) {
						link.setAttribute('href', URL.createObjectURL(blob));
						link.setAttribute('download', filename);
						link.click();
					}
					else {
						csv = 'data:text/csv;charset=utf-8,' + csv;
						window.open(encodeURI(csv));
					}
					document.body.removeChild(link);
				}
			});
	}
    
	rollback(site_name){
		var form = {
			site_name: site_name
		};
		return axios({
			method: 'post',
			url: '/legacy/billing/rollback',
			data: form
		});
	}

	electricity_info(site_name){
		var form = {
			site_name: site_name,
			utility_type: 'electricity'
		};
		return axios({
			method: 'get',
			url: '/legacy/billing/info',
			params: form
		});
	}

	cold_water_info(site_name){
		var form = {
			site_name: site_name,
			utility_type: 'cold_water'
		};
		return axios({
			method: 'get',
			url: '/legacy/billing/info',
			params: form
		});
	}
}