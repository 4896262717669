<template>
  <i
    v-if="readingValidity === 'OKAY'"
    class="pi pi-check"
  />
  <i
    v-else-if="readingValidity === 'HIGH'"
    class="pi pi-arrow-up"
  />
  <i
    v-else-if="readingValidity === 'MIDHIGH'"
    class="pi pi-arrow-circle-up"
  />
  <i
    v-else-if="readingValidity === 'LOW'"
    class="pi pi-arrow-down"
  />
  <i
    v-else-if="readingValidity === 'MIDLOW'"
    class="pi pi-arrow-circle-down"
  />
</template>

<script>
export default {
	props: {
		readingValidity: {
			default: null,
			type: String
		}
	}
};
</script>

<style></style>
