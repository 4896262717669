<template>
  <div class="flex justify-center min-h-screen antialiased">  
    <div class="container sm:mt-40 mt-24 my-auto max-w-md p-3 bg-urbcharcoal rounded">
      <div>
        <div class="text-center m-6">
          <h1 class="text-3xl font-semibold text-urbtext">
            Login.
          </h1>
        </div>

        <div class="m-6">
          <form class="mb-4">
            <div class="p-field pt-4">
              <span class="p-float-label">
                <InputText
                  id="email"
                  v-model="form.email"
                  type="text"
                  class="w-full"
                  :class="{
                    'border-red-600': invalidCredentials
                  }"
                  @keyup.enter="onSubmit"
                />
                <label
                  for="email"
                  :class="{
                    'text-red-600': invalidCredentials
                  }"
                >Email</label>
              </span>
            </div>
            <div class="p-field pt-4">
              <span class="p-float-label">
                <Password
                  id="password"
                  v-model="form.password"
                  :inputClass="'w-full ' + (invalidCredentials ? 'border-red-600' : '')"
                  class="w-full"
                  toggleMask
                  :feedback="false"
                  @keyup.enter="onSubmit"
                />
                <label
                  for="password"
                  :class="{
                    'text-red-600': invalidCredentials
                  }"
                >Password</label>
              </span>
            </div>
            <div class="mb-6">
              <Button
                class="w-full px-3 py-4 duration-100 ease-in-out justify-center text-xl"
                :loading="loading"
                label="Login"
                :class="{'shake' : buttonShake}"
                @click="onSubmit"
              />
            </div>
            <div class="mx-3 text-urbtext">
              Forgotten password? 
              <router-link
                to="/password/reset"
                class="text-urborange underline"
              >
                Reset.
              </router-link>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Password from 'primevue/password';

import { mapActions, mapGetters } from 'vuex';

export default {
	components: {
		InputText,
		Button,
		Password
	},
	data() {
		return {
			form: {
				email: null,
				password: null
			},
			invalidCredentials: false,
			loading: false,
			buttonShake: false
		};
	},
	computed: {
		...mapGetters({
			authenticated: 'auth/authenticated'
		})
	},
	methods: {
		...mapActions({
			login: 'auth/login'
		}),
		onSubmit() {
			if (!this.form.email || !this.form.password){
				this.invalidCredentials = true;
				this.shakeTheButton();
				return;
			}
			this.loading = true;
			this.login(this.form)
				.then(() => {
					this.$router.push('/list');
				})
				.catch(res => {
					this.loading = false;
					this.shakeTheButton();
					if (res.response.status === 401) {
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to login!',
							detail: 'Invalid credentials used.'
						});
					} else {
						this.$toast.add({
							severity: 'error',
							summary: 'Failed to login!',
							detail: 'An error has occured'
						});
					}
				});
		},
		shakeTheButton(){
			this.buttonShake = true;
			setTimeout(() => {
				this.buttonShake = false;
			}, 820);
		}
	}
};
</script>

<style></style>
