<template>
  <div class="flex justify-center min-h-screen antialiased">  
    <div class="container sm:mt-40 mt-24 my-auto max-w-md p-3 bg-urbcharcoal rounded">
      <template v-if="!formComplete">
        <Transition name="slide-fade">
          <div>
            <div class="text-center m-6">
              <h1 class="text-3xl font-semibold text-urbtext">
                Forgot your password?
              </h1>
              <p class="text-gray-500">
                Just enter your email address below and we'll send you a link to reset your password!
              </p>
            </div>

            <div class="m-6">
              <form class="mb-4">
                <div class="p-field pt-4">
                  <span class="p-float-label">
                    <InputText
                      id="email"
                      v-model="email"
                      type="text"
                      class="w-full"
                      @keyup.enter="onSubmit"
                    />
                    <label for="email">Email</label>
                  </span>
                </div>
                <div class="mb-6">
                  <Button
                    class="w-full px-3 py-4 duration-100 ease-in-out justify-center text-xl"
                    :loading="loading"
                    label="Send reset link"
                    :class="{'shake' : buttonShake}"
                    @click="onSubmit"
                  />
                </div>
              </form>
            </div>
          </div>
        </Transition>
      </template>
      <template v-else>
        <Transition name="slide-fade">
          <div class="text-center m-6">
            <h1 class="text-3xl font-semibold text-urbtext">
              Forgot your password?
            </h1>
            <p class="text-gray-500">
              An email will be sent to you shortly with instructions to continue.
            </p>
            <i class="pi pi-check-circle text-urborange text-7xl mt-5" />
          </div>
        </Transition>
      </template>
    </div>
  </div>
</template>

<script>
import PasswordService from '../services/PasswordProvider';

export default {
	data(){
		return {
			email: null,
			formComplete: false,
			loading: false,
			buttonShake: false
		};
	},
	methods: {
		onSubmit(){
			if (this.email){
				this.loading = true;
				PasswordService.requestPasswordReset(this.email)
					.then((res) => {
						this.formComplete = true;
						this.loading = false;
					})
					.catch((err) => {
						this.loading = false;
						this.buttonShake = true;
						setTimeout(() => {
							this.buttonShake = false;
						}, 820);
					});
			} else {
				this.buttonShake = true;
				setTimeout(() => {
					this.buttonShake = false;
				}, 820);
			}
		}
	}
};
</script>

<style lang="scss" scoped>
    .field-card {
        margin: 0 30px 0 30px;
        padding-left: 0;
        padding-right: 0; 
    }
    .slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
  opacity: 0;
}
</style>