import axios from 'axios'; 

export default class UserInfoProvider {
	searchUsers(info) {
		return axios({
			method: 'get',
			url: '/admin/user/search',
			params: {
				'info': info
			}
		});
	}

	getDatabases(){
		return axios({
			method: 'get',
			url: '/admin/DB/list'
		});
	}
    
	getUserSites(uid) {
		return axios({
			method: 'get',
			url: '/admin/user/sites',
			params: {
				'uid': uid
			}
		});
	}
    
	grantSiteAccess(uid, site_id) {
		return axios({
			method: 'post',
			url: '/admin/sites/access/single/grant',
			data: {
				'user_id': uid,
				'site_id': site_id,
			}
		});
	}
    
	revokeSiteAccess(uid, site_id) {
		return axios({
			method: 'post',
			url: '/admin/sites/access/single/revoke',
			data: {
				'user_id': uid,
				'site_id': site_id,
			}
		});
	}
    
	grantDBSiteAccess(uid, db_id) {
		return axios({
			method: 'post',
			url: '/admin/sites/access/all/grant',
			data: {
				'user_id': uid,
				'database_id': db_id,
			}
		});
	}
    
	revokeDBSiteAccess(uid, db_id) {
		return axios({
			method: 'post',
			url: '/admin/sites/access/all/revoke',
			data: {
				'user_id': uid,
				'database_id': db_id,
			}
		});
	}

	getAllRoles() {
		return axios({
			method: 'get',
			url: '/admin/user/getroles',
		});
	}
	
	getUserRoles(user_id) {
		return axios({
			method: 'get',
			url: '/admin/user/getuserroles',
			params: {
				user_id: user_id
			}
		});
	}

	toggleUserRole(user_id, role_id) {
		return axios({
			method: 'post',
			url: '/admin/user/togglerole',
			data: {
				'user_id': user_id,
				'role_id': role_id,
			}
		});
	}
} 