<template>
  <DataTable
    ref="datatable"
    v-model:filters="filters"
    :totalRecords="rowCount"
    filterDisplay="row"
    :value="readings"
    :paginator="true"
    :lazy="true"
    class="p-datatable-customers p-datatable-sm"
    :rows="50"
    dataKey="site_name"
    :rowHover="true"
    :loading="loading"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rowsPerPageOptions="[10, 25, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
    responsiveLayout="scroll"
    :globalFilterFields="['unit', 'meter_no', 'reading_date_disp']"
    @filter="onFilter($event)"
    @page="onPage($event)"
    @sort="onSort($event)"
    @keydown.enter.prevent
  >
    <template #header>
      <div class="p-grid">
        <div class="p-col">
          <div class="box">
            <span class="text-3xl"> {{ siteName }} (Viewing) </span>
          </div>
        </div>
        <div class="flex flex-row mt-2">
          <div>
            <Dropdown
              v-model="pagingParams.monthsPrior"
              :options="monthOptions"
              optionLabel="name"
              optionValue="value"
              placeholder="Select a City"
              class=""
              @change="loadPagedData()"
            />
            <Button
              :loading="exportLoading"
              icon="pi pi-external-link"
              label="Export"
              class="mx-2 w-28"
              :disabled="exportDisabled"
              @click="exportCSV()"
            />
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      No readings found.
    </template>
    <template #loading>
      Loading readings for {{ siteName }} data. Please wait.
    </template>
    <Column
      sortable
      style="width:50px"
      filterField="utility_type"
      :showFilterMatchModes="false"
    >
      <template #body="{data}">
        <img
          v-if="data.utility_type === 'electricity'"
          src="@/assets/util-electricity.svg"
          alt="ELECTRICITY"
          style="width:40px"
        >
        <img
          v-else-if="data.utility_type === 'cold_water'"
          src="@/assets/util-cold_water.svg"
          alt="COLD_WATER"
          style="width:40px"
        >
        <img
          v-else-if="data.utility_type === 'water'"
          src="@/assets/util-water.svg"
          alt="WATER"
          style="width:40px"
        >
        <img
          v-else-if="data.utility_type === 'hot_water'"
          src="@/assets/util-hot_water.svg"
          alt="HOT_WATER"
          style="width:40px"
        >
        <img
          v-else
          src="@/assets/util-unsure.svg"
          alt="UNSURE"
          style="width:40px"
        >
      </template>
    </Column>
    <Column
      field="unit_no"
      header="No."
      sortable
      style="width:100px; text-align: center;"
      :showFilterMenu="false"
    >
      <template #body="{data}">
        {{ data.unit_no }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          @keydown.enter="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="unit"
      header="Unit"
      sortable
      :showFilterMenu="false"
    >
      <template #body="{data}">
        {{ data.unit }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by unit"
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="meter_no"
      header="Meter No."
      sortable
      :showFilterMenu="false"
      class="max-w-xs"
    >
      <template #body="{data}">
        {{ data.meter_no }}
        <span
          v-if="data.description != 'Working Meter' && data.description != ''"
          class="text-yellow-600"
        >
          [{{ data.description }}]
        </span>
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by meter no."
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_date_disp"
      header="Reading Date"
      sortable
      class="text-center"
      :showFilterMenu="false"
    >
      <template #body="{data}">
        {{ data.reading_date_disp }}
      </template>
      <template #filter="{filterModel,filterCallback}">
        <InputText
          v-model="filterModel.value"
          v-tooltip.top.focus="'Hit enter key to filter'"
          type="text"
          class="p-column-filter"
          placeholder="Search by date"
          @keydown.enter="filterCallback()"
          @change="filterCallback()"
        />
      </template>
    </Column>
    <Column
      field="reading_new"
      header="New Reading"
      sortable
      style="width:10%"
    >
      <template #body="slotProps">
        <div :class="getStylingClasses(slotProps)">
          {{ slotProps.data.reading_new }}
        </div>
      </template>
    </Column>
    <Column
      field="reading_old"
      header="Old Reading"
      sortable
      style="width:10%"
    >
      <template #body="{data}">
        {{ data.reading_old }}
      </template>
    </Column>
    <Column
      field="consumption"
      header="Consumption"
      sortable
    >
      <template #body="{data}">
        <span class="status-success">
          {{ data.reading_new - data.reading_old }}
        </span>
      </template>
    </Column>
    <Column
      field="file_name"
      header="IMG"
    >
      <template #body="{data}">
        <Button
          :label="pictureButtonLabel(data)"
          icon="pi pi-external-link"
          :disabled="disablePictureButton(data.file_name)"
          @click="togglePicture(data)"
        />
        <Dialog
          v-model:visible="data.pictureDialog"
          :header="data.unit + ' | ' + data.meter_no"
          :maximizable="true"
          position="left"
        >
          <img
            :src="
              baseURL +
                'image/' +
                siteName +
                '/' +
                data.reading_date +
                '/' +
                data.file_name
            "
            :disabled="data.file_name == null"
          >
        </Dialog>
      </template>
    </Column>
  </DataTable>
</template>

<script>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';

import SiteReadingService from '../services/SiteReadings';

import { FilterMatchMode } from 'primevue/api';

export default {
	components: {
		DataTable,
		Column,
		Dialog,
		Dropdown
	},
	props: {
		siteName: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			readings: [],
			loading: true,
			exportLoading: false,
			exportDisabled: true,
			monthOptions: [
				{ name: '1 Months', value: 1 },
				{ name: '2 Months', value: 2 },
				{ name: '3 Months', value: 3 },
				{ name: '4 Months', value: 4 },
				{ name: '5 Months', value: 5 },
				{ name: '6 Months', value: 6 },
				{ name: '1 Year', value: 12 }
			],
			pagingParams: {},
			rowCount: 0,
			filters: {
				meter_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				unit: { value: null, matchMode: FilterMatchMode.CONTAINS },
				unit_no: { value: null, matchMode: FilterMatchMode.CONTAINS },
				reading_date_disp: { value: null, matchMode: FilterMatchMode.CONTAINS },
				utility_type: { value: null, matchMode: FilterMatchMode.IN }
			},
			baseURL: process.env.VUE_APP_API_URL
		};
	},
	originalValue: null,

	created() {
		this.siteReadingService = new SiteReadingService();
	},
	mounted() {
		this.pagingParams = {
			pageIndex: 0,
			pageSize: this.$refs.datatable.rows,
			sortField: null,
			sortOrder: null,
			edit: 0,
			monthsPrior: 3,
			filters: this.filters,
			site_name: this.siteName,
			paging: true
		};

		this.loadPagedData();
	},
	methods: {
		disablePictureButton(file_name) {
			switch (file_name) {
			case 'NOFILE':
			case 'no_image_available.jpg':
				return true;
			default:
				return false;
			}
		},
		pictureButtonLabel(data) {
			return data.pictureDialog == true ? 'Hide' : 'Show';
		},
		togglePicture(data) {
			if (data.pictureDialog) {
				data.pictureDialog = false;
			} else {
				data.pictureDialog = true;
			}
		},
		loadPagedData() {
			this.loading = true;
			this.exportDisabled = true;

			setTimeout(() => {
				this.siteReadingService
					.getViewOnlyReadings(this.pagingParams)
					.then(res => {
						this.readings = res.data.readings;
						this.rowCount = res.data.count;
						this.loading = false;
						if (this.readings.length > 0) {
							this.exportDisabled = false;
						}

						this.readings.forEach(element => {
							element.pictureDialog = false;
						});
					})
					.catch(err => {
						console.error(err.message);
						this.readings = null;
						this.loading = false;
						this.locked = err.response.data.locked;
					});
			}, Math.random() * 1000 + 250);
		},
		onPage(event) {
			this.pagingParams.pageIndex = event.page;
			this.pagingParams.pageSize = event.rows;
			this.loadPagedData();
		},
		onSort(event) {
			this.pagingParams.sortField = event.sortField;
			this.pagingParams.sortOrder = event.sortOrder < 0 ? 'ASC' : 'DESC';
			this.loadPagedData();
		},
		onFilter() {
			this.pagingParams.filters = this.filters;
			this.loadPagedData();
		},
		updateValidity(props) {
			var value1 = props.data['reading_new'];
			var value2 = props.data['reading_old'];
			var estimateval = props.data['est_reading'];
			var avgconsumption = props.data['est_consumption'];

			if (estimateval != '') {
				var currentconsumption = value1 - value2;
				var persentagediff = (currentconsumption / avgconsumption - 1) * 100;

				if (Math.sign(persentagediff) == +1 && persentagediff > 60) {
					props.data['valid'] = 'HIGH';
					return;
				} else if (
					Math.sign(persentagediff) == +1 &&
					persentagediff > 30 &&
					persentagediff <= 60
				) {
					props.data['valid'] = 'MIDHIGH';
					return;
				} else if (persentagediff >= -30 && persentagediff <= 30) {
					props.data['valid'] = 'OKAY';
					return;
				} else if (
					Math.sign(persentagediff) == -1 &&
					persentagediff < -30 &&
					persentagediff >= -60
				) {
					props.data['valid'] = 'MIDLOW';
					return;
				} else if (persentagediff < -60) {
					props.data['valid'] = 'LOW';
					return;
				} else {
					return;
				}
			}
			return;
		},
		getStylingClasses(props) {
			var value1 = props.data['reading_new'];
			var value2 = props.data['reading_old'];
			var estimateval = props.data['est_reading'];
			var avgconsumption = props.data['est_consumption'];

			if (estimateval != '') {
				var currentconsumption = value1 - value2;
				var persentagediff = (currentconsumption / avgconsumption - 1) * 100;

				if (Math.sign(persentagediff) == +1 && persentagediff > 60) {
					props.data['valid'] = 'HIGH';
					return 'text-red-700 font-bold';
				} else if (
					Math.sign(persentagediff) == +1 &&
					persentagediff > 30 &&
					persentagediff <= 60
				) {
					props.data['valid'] = 'MIDHIGH';
					return 'text-yellow-400';
				} else if (persentagediff >= -30 && persentagediff <= 30) {
					props.data['valid'] = 'OKAY';
					return 'text-green-500';
				} else if (
					Math.sign(persentagediff) == -1 &&
					persentagediff < -30 &&
					persentagediff >= -60
				) {
					props.data['valid'] = 'MIDLOW';
					return 'text-yellow-400';
				} else if (persentagediff < -60) {
					props.data['valid'] = 'LOW';
					return 'text-red-700 font-bold';
				} else {
					return '';
				}
			}
			return '';
		},
		exportCSV() {
			this.exportLoading = true;
			this.siteReadingService
				.getViewOnlyReadingsAsCSV(
					{
						sortField: null,
						sortOrder: null,
						edit: 0,
						filters: this.filters,
						site_name: this.siteName,
						monthsPrior: this.pagingParams.monthsPrior
					},
					this.baseURL
				)
				.then(() => {
					this.exportLoading = false;
				});
		}
	}
};
</script>
